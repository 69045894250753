import React, {
  MouseEvent,
  MouseEventHandler,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  useGLTF,
  useAnimations,
  Html,
  ContactShadows,
  Environment,
  Lightformer,
  CameraControls,
} from "@react-three/drei";
import { OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import { DataContainer } from "../CommonStyled";
import ThreeDModelLoader from "../components/ThreeDModelLoader";
import { useApi } from "../hooks/api";
import { Iparameters } from "../store/types";
import { Switch } from "@progress/kendo-react-inputs";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from "@progress/kendo-react-charts";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip, TooltipPosition } from "@progress/kendo-react-tooltip";
import { convertDateToStrWithTime2 } from "../components/CommonFunction";

const DEG45 = Math.PI / 4;
const DEG90 = Math.PI / 2;

const Model: React.FC = () => {
  const orbitControlsRef = useRef<any>(null);
  const [active, setActive] = useState(true);
  const tooltip = React.useRef<Tooltip>(null);

  const onClickController = (e: MouseEvent<HTMLButtonElement>) => {
    const { name } = e.currentTarget;

    if (orbitControlsRef.current) {
      if (name === "reset") {
        orbitControlsRef.current.reset();
      } else if (name === "left") {
        const currentAngle = orbitControlsRef.current.getAzimuthalAngle();
        orbitControlsRef.current.setAzimuthalAngle(currentAngle - DEG45);
      } else if (name === "up") {
        const currentAngle = orbitControlsRef.current.getPolarAngle();
        orbitControlsRef.current.setPolarAngle(currentAngle - DEG90);
      } else if (name === "right") {
        const currentAngle = orbitControlsRef.current.getAzimuthalAngle();
        orbitControlsRef.current.setAzimuthalAngle(currentAngle + DEG45);
      }
    }
  };
  return (
    <>
      {/* 카메라 컨트롤러 */}
      <div
        style={{
          position: "fixed",
          bottom: "50px",
          right: "50px",
          zIndex: 999999,
          backgroundColor: "rgba(255,255,255,0.9)",
          borderRadius: "5px",
          padding: "5px",
        }}
      >
        <Tooltip
          ref={tooltip}
          anchorElement="target"
          position="top"
          openDelay={100}
        >
          {/* Reset */}
          <div
            title="리셋"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
            style={{ display: "inline" }}
          >
            <Button
              themeColor={"primary"}
              fillMode={"flat"}
              icon="arrow-rotate-cw"
              size={"large"}
              onClick={onClickController}
              name="reset"
            ></Button>
          </div>
          {/* 좌측 돌리기 */}
          <div
            title="좌측 돌리기"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
            style={{ display: "inline" }}
          >
            <Button
              themeColor={"primary"}
              fillMode={"flat"}
              icon="chevron-left"
              size={"large"}
              onClick={onClickController}
              name="left"
            ></Button>
          </div>
          <div
            title="위에서 보기"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
            style={{ display: "inline" }}
          >
            {/* 위에서 보기 */}
            <Button
              themeColor={"primary"}
              fillMode={"flat"}
              icon="chevron-up"
              size={"large"}
              onClick={onClickController}
              name="up"
            ></Button>
          </div>
          {/* 우측 돌리기 */}
          <div
            title="우측 돌리기"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
            style={{ display: "inline" }}
          >
            <Button
              themeColor={"primary"}
              fillMode={"flat"}
              icon="chevron-right"
              size={"large"}
              onClick={onClickController}
              name="right"
            ></Button>
          </div>
          {/* 데이터 On/Off */}
          <div
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
            title="데이터 On/Off"
            style={{ display: "inline" }}
          >
            <Switch
              defaultChecked={true}
              onChange={(e: any) => {
                if (!active == false) {
                }
                setActive(!active);
              }}
            />
          </div>
        </Tooltip>
      </div>

      <Canvas
        gl={{ logarithmicDepthBuffer: true, antialias: false }}
        dpr={[1, 1.5]}
        camera={{ position: [5, 5, 15], fov: 50 }}
      >
        <OrbitControls
          ref={orbitControlsRef}
          enableZoom={true}
          minDistance={10} // 카메라 최소 거리
          maxDistance={50} // 카메라 최대 거리
          enableDamping={true}
          dampingFactor={0.5} // 이 값을 조절하여 관성 강도를 변경 (0 ~ 1)
        />

        <Suspense fallback={<ThreeDModelLoader />}>
          <color attach="background" args={["#20202b"]} />

          <FacilityProcess
            position={[0, -1.5, 0]}
            scale={[0.01, 0.01, 0.01]}
            active={active}
          />
          <hemisphereLight intensity={0.5} />
          <ContactShadows
            resolution={1024}
            frames={1}
            position={[0, -1.16, 0]}
            scale={15}
            blur={0.5}
            opacity={1}
            far={20}
          />

          <Environment resolution={512}>
            {/* Ceiling */}
            <Lightformer
              intensity={2}
              rotation-x={Math.PI / 2}
              position={[0, 4, -9]}
              scale={[10, 1, 1]}
            />
            <Lightformer
              intensity={2}
              rotation-x={Math.PI / 2}
              position={[0, 4, -6]}
              scale={[10, 1, 1]}
            />
            <Lightformer
              intensity={2}
              rotation-x={Math.PI / 2}
              position={[0, 4, -3]}
              scale={[10, 1, 1]}
            />
            <Lightformer
              intensity={2}
              rotation-x={Math.PI / 2}
              position={[0, 4, 0]}
              scale={[10, 1, 1]}
            />
            <Lightformer
              intensity={2}
              rotation-x={Math.PI / 2}
              position={[0, 4, 3]}
              scale={[10, 1, 1]}
            />
            <Lightformer
              intensity={2}
              rotation-x={Math.PI / 2}
              position={[0, 4, 6]}
              scale={[10, 1, 1]}
            />
            <Lightformer
              intensity={2}
              rotation-x={Math.PI / 2}
              position={[0, 4, 9]}
              scale={[10, 1, 1]}
            />
            {/* Sides */}
            <Lightformer
              intensity={2}
              rotation-y={Math.PI / 2}
              position={[-50, 2, 0]}
              scale={[100, 2, 1]}
            />
            <Lightformer
              intensity={2}
              rotation-y={-Math.PI / 2}
              position={[50, 2, 0]}
              scale={[100, 2, 1]}
            />
            {/* Key */}
            <Lightformer
              form="ring"
              color="red"
              intensity={10}
              scale={2}
              position={[10, 5, 10]}
              onUpdate={(self) => self.lookAt(0, 0, 0)}
            />
          </Environment>
          <Effects />
        </Suspense>
      </Canvas>
    </>
  );
};

//각 팝업들 position(지우면안됩니다)
const tcpPosition = {
  1: [2, 1.5, 0],
  2: [4.5, 1.5, 2],
  3: [0.8, 1.5, 3],
  4: [-1.2, 1.5, 5],
  5: [-3, 1.5, 3],
  6: [-6.5, 1.5, 2],
  7: [-4.5, 1.5, 0],
};
const tcpDetailPosition = {
  1: [11, 11, 0],
  2: [13.5, 11, 2],
  3: [9.8, 11, 3],
  4: [8.3, 11, 5],
  5: [6, 11, 3],
  6: [3.5, 11, 2],
  7: [4.5, 11, 0],
};
const outputPosition = [-3, 1.5, -6];
const outputPosition2 = [-3, 3, -6];
const visionPosition = [3.5, 2.5, -2.5];
const visionDetailPosition = [6, 10.5, -2.5];
const dryerPosition = [1.5, 2.5, -6];
const InfoDetailPosition = [4.5, 10.5, -6];
const QtyDetailPosition = [-1.5, 8.5, -6];

const FacilityProcess = (props: any) => {
  const { animations, scene, nodes, materials }: any = useGLTF(
    "./facility_process/untitled.gltf"
  );

  // Extract animation actions
  const { ref, actions } = useAnimations(animations);
  const [isVisionDetailShowed, setIsVisionDetailShowed] = useState(false);
  const [isAnimated, setIsAnimated] = useState(true);
  const [detail, setDetail] = useState<any>(null);
  const [isClicking, setIsClicking] = useState<string>("");
  const [isVisionClicking, setIsVisonClicking] = useState<string>("");
  const [isInfoClicking, setIsInfoClicking] = useState<string>("");
  const [isInfoDetailShowed, setIsInfonDetailShowed] = useState(false);
  const [isInfoDetailShowed2, setIsInfonDetailShowed2] = useState(false);
  const [isQtyClicking, setIsQtyClicking] = useState<string>("");
  const [isQtyDetailShowed, setIsQtyDetailShowed] = useState(false);
  const processApi = useApi();
  const [mainDataResult, setMainDataResult] = useState<any>(null);
  const [detailDataResult, setDetailDataResult] = useState<any>(null);
  const [PanelDataResult, setPanelDataResult] = useState<any>(null);

  const fetchMainGrid = async () => {
    let data = {
      isSuccess: true,
      tables: [
        {
          TableName: "Table0",
          ColumnCount: 58,
          RowCount: 1,
          TotalRowCount: null,
          Columns: [
            {
              ColumnName: "Main_Panel_State",
              DataType: "String",
            },
            {
              ColumnName: "SUB1_Panel_State",
              DataType: "String",
            },
            {
              ColumnName: "SUB2_Panel_State",
              DataType: "String",
            },
            {
              ColumnName: "ECS_State",
              DataType: "String",
            },
            {
              ColumnName: "AirBlower_State",
              DataType: "String",
            },
            {
              ColumnName: "Washing_State",
              DataType: "String",
            },
            {
              ColumnName: "RBT1_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC11_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC12_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC13_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC14_State",
              DataType: "String",
            },
            {
              ColumnName: "CV1_State",
              DataType: "String",
            },
            {
              ColumnName: "RBT2_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC21_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC22_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC23_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC24_State",
              DataType: "String",
            },
            {
              ColumnName: "CV2_State",
              DataType: "String",
            },
            {
              ColumnName: "RBT3_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC31_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC32_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC33_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC34_State",
              DataType: "String",
            },
            {
              ColumnName: "CV3_State",
              DataType: "String",
            },
            {
              ColumnName: "RBT4_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC41_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC42_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC43_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC44_State",
              DataType: "String",
            },
            {
              ColumnName: "CV4_State",
              DataType: "String",
            },
            {
              ColumnName: "RBT5_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC51_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC52_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC53_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC54_State",
              DataType: "String",
            },
            {
              ColumnName: "CV5_State",
              DataType: "String",
            },
            {
              ColumnName: "RBT6_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC61_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC62_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC63_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC64_State",
              DataType: "String",
            },
            {
              ColumnName: "CV6_State",
              DataType: "String",
            },
            {
              ColumnName: "RBT7_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC71_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC72_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC73_State",
              DataType: "String",
            },
            {
              ColumnName: "TPC74_State",
              DataType: "String",
            },
            {
              ColumnName: "CV7_State",
              DataType: "String",
            },
            {
              ColumnName: "OP0_투입_State",
              DataType: "String",
            },
            {
              ColumnName: "OP1_공급_State",
              DataType: "String",
            },
            {
              ColumnName: "OP2_CV_A_State",
              DataType: "String",
            },
            {
              ColumnName: "OP3_CV_B_State",
              DataType: "String",
            },
            {
              ColumnName: "OP4_배출_State",
              DataType: "String",
            },
            {
              ColumnName: "OP5_투입검사_State",
              DataType: "String",
            },
            {
              ColumnName: "OP6_배출검사_State",
              DataType: "String",
            },
            {
              ColumnName: "Light_Alarm",
              DataType: "String",
            },
            {
              ColumnName: "Heavy_Alarm",
              DataType: "String",
            },
            {
              ColumnName: "UpdateTime",
              DataType: "String",
            },
          ],
          Rows: [
            {
              Main_Panel_State: "1",
              SUB1_Panel_State: "1",
              SUB2_Panel_State: "1",
              ECS_State: "1",
              AirBlower_State: "1",
              Washing_State: "1",
              RBT1_State: "1",
              TPC11_State: "1",
              TPC12_State: "1",
              TPC13_State: "1",
              TPC14_State: "1",
              CV1_State: "1",
              RBT2_State: "1",
              TPC21_State: "1",
              TPC22_State: "1",
              TPC23_State: "1",
              TPC24_State: "1",
              CV2_State: "1",
              RBT3_State: "1",
              TPC31_State: "1",
              TPC32_State: "1",
              TPC33_State: "1",
              TPC34_State: "1",
              CV3_State: "1",
              RBT4_State: "1",
              TPC41_State: "1",
              TPC42_State: "1",
              TPC43_State: "1",
              TPC44_State: "1",
              CV4_State: "1",
              RBT5_State: "1",
              TPC51_State: "1",
              TPC52_State: "1",
              TPC53_State: "1",
              TPC54_State: "1",
              CV5_State: "1",
              RBT6_State: "1",
              TPC61_State: "1",
              TPC62_State: "1",
              TPC63_State: "1",
              TPC64_State: "1",
              CV6_State: "1",
              RBT7_State: "1",
              TPC71_State: "1",
              TPC72_State: "1",
              TPC73_State: "1",
              TPC74_State: "1",
              CV7_State: "1",
              OP0_투입_State: "1",
              OP1_공급_State: "1",
              OP2_CV_A_State: "1",
              OP3_CV_B_State: "1",
              OP4_배출_State: "1",
              OP5_투입검사_State: "1",
              OP6_배출검사_State: "1",
              Light_Alarm: "11174",
              Heavy_Alarm: "10995",
              UpdateTime: "2023-05-31 10:14:00",
            },
          ],
        },
        {
          TableName: "Table1",
          ColumnCount: 125,
          RowCount: 500,
          TotalRowCount: null,
          Columns: [
            {
              ColumnName: "No",
              DataType: "Int32",
            },
            {
              ColumnName: "INDATE",
              DataType: "String",
            },
            {
              ColumnName: "ID_No",
              DataType: "String",
            },
            {
              ColumnName: "In_InsertTime",
              DataType: "String",
            },
            {
              ColumnName: "In_Result",
              DataType: "String",
            },
            {
              ColumnName: "In_Outer_Width1",
              DataType: "Decimal",
            },
            {
              ColumnName: "In_Outer_Width2",
              DataType: "Decimal",
            },
            {
              ColumnName: "In_Internal_Width",
              DataType: "Decimal",
            },
            {
              ColumnName: "In_Thickness",
              DataType: "Decimal",
            },
            {
              ColumnName: "Tp_No",
              DataType: "Int32",
            },
            {
              ColumnName: "Tp_InsertTime",
              DataType: "String",
            },
            {
              ColumnName: "Tp_Result",
              DataType: "String",
            },
            {
              ColumnName: "Tp_JIG",
              DataType: "Decimal",
            },
            {
              ColumnName: "Tp_JIG_MIN",
              DataType: "Decimal",
            },
            {
              ColumnName: "Tp_JIG_MAX",
              DataType: "Decimal",
            },
            {
              ColumnName: "Tp_WJIG",
              DataType: "Decimal",
            },
            {
              ColumnName: "Tp_WJIG_MIN",
              DataType: "Decimal",
            },
            {
              ColumnName: "Tp_WJIG_MAX",
              DataType: "Decimal",
            },
            {
              ColumnName: "Tp_Vibration",
              DataType: "Int32",
            },
            {
              ColumnName: "Vi_InsertTime",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ReadTime",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Result",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_1X",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_1Y",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_2X",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_2Y",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_3X",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_3Y",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_4X",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_4Y",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_5X",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_5Y",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_6X",
              DataType: "String",
            },
            {
              ColumnName: "Vi_SloatHole_6Y",
              DataType: "String",
            },
            {
              ColumnName: "Vi_CutLen_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_CutLen_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_CutLen_3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_LenQR_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_LenQR_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_LenQR_3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Cut_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Cut_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Cut_3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Len_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Len_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Len_3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_HoleT_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_HoleT_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_HoleT_3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_HoleT_4",
              DataType: "String",
            },
            {
              ColumnName: "Vi_HoleL_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_HoleL_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_HoleL_3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Cut_Outer_Width_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Cut_Outer_Width_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Item_Internal_WidthQR_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Item_Internal_WidthQR_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Item_Outer_WidthQR_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Item_Outer_WidthQR_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Item_Internal_Width_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Item_Internal_Width_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Item_Outer_Width_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Item_Outer_Width_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_42",
              DataType: "String",
            },
            {
              ColumnName: "Vi_43",
              DataType: "String",
            },
            {
              ColumnName: "Vi_44",
              DataType: "String",
            },
            {
              ColumnName: "Vi_45",
              DataType: "String",
            },
            {
              ColumnName: "Vi_46",
              DataType: "String",
            },
            {
              ColumnName: "Vi_47",
              DataType: "String",
            },
            {
              ColumnName: "Vi_48",
              DataType: "String",
            },
            {
              ColumnName: "Vi_49",
              DataType: "String",
            },
            {
              ColumnName: "Vi_50",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Front_Width_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Front_Width_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Front_Width_3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Front_Width_4",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Front_Width_5",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Front_Width_6",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Behind_Width_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Behind_Width_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Behind_Width_3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Behind_Width_4",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Behind_Width_5",
              DataType: "String",
            },
            {
              ColumnName: "Vi_Behind_Width_6",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_4",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_5",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_6",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_7",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_8",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_9",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_10",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_11",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_12",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_13",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_14",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_15",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_16",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_17",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_18",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_19",
              DataType: "String",
            },
            {
              ColumnName: "Vi_ExtraField_20",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RL1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RL2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RL3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RL4",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RL5",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RR1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RR2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RR3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RR4",
              DataType: "String",
            },
            {
              ColumnName: "Vi_RR5",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FL1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FL2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FL3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FL4",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FL5",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FR1",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FR2",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FR3",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FR4",
              DataType: "String",
            },
            {
              ColumnName: "Vi_FR5",
              DataType: "String",
            },
            {
              ColumnName: "Pass_FINYN",
              DataType: "String",
            },
          ],
          Rows: [
            {
              No: 1301,
              INDATE: "20230105",
              ID_No: "21100863A 2B41949",
              In_InsertTime: "2023-01-05 16:04:55",
              In_Result: "OK",
              In_Outer_Width1: 224.151,
              In_Outer_Width2: 224.058,
              In_Internal_Width: 216.295,
              In_Thickness: 4.457,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 17:24:25",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 11,
              Tp_WJIG: 45.6,
              Tp_WJIG_MIN: 39.5,
              Tp_WJIG_MAX: 45.7,
              Tp_Vibration: 880,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 72,
              INDATE: "20230105",
              ID_No: "21100863A 2BH3390",
              In_InsertTime: "2023-01-05 08:10:39",
              In_Result: "OK",
              In_Outer_Width1: 223.936,
              In_Outer_Width2: 223.965,
              In_Internal_Width: 216.299,
              In_Thickness: 4.473,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 08:29:18",
              Tp_Result: "OK",
              Tp_JIG: 15,
              Tp_JIG_MIN: 14,
              Tp_JIG_MAX: 17.2,
              Tp_WJIG: 26.8,
              Tp_WJIG_MIN: 24.5,
              Tp_WJIG_MAX: 28.9,
              Tp_Vibration: 884,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 150,
              INDATE: "20230105",
              ID_No: "21100863A 2BO1075",
              In_InsertTime: "2023-01-05 08:36:45",
              In_Result: "OK",
              In_Outer_Width1: 223.737,
              In_Outer_Width2: 223.804,
              In_Internal_Width: 216.148,
              In_Thickness: 4.426,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:53:52",
              Tp_Result: "OK",
              Tp_JIG: 34.8,
              Tp_JIG_MIN: 33,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 17.6,
              Tp_WJIG_MIN: 13.4,
              Tp_WJIG_MAX: 20,
              Tp_Vibration: 779,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 100,
              INDATE: "20230105",
              ID_No: "21100863A 2BO1330",
              In_InsertTime: "2023-01-05 08:20:36",
              In_Result: "OK",
              In_Outer_Width1: 223.774,
              In_Outer_Width2: 223.851,
              In_Internal_Width: 216.186,
              In_Thickness: 4.386,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 09:10:45",
              Tp_Result: "OK",
              Tp_JIG: 37.2,
              Tp_JIG_MIN: 35.9,
              Tp_JIG_MAX: 39.1,
              Tp_WJIG: 31.9,
              Tp_WJIG_MIN: 22.3,
              Tp_WJIG_MAX: 33.1,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 163,
              INDATE: "20230105",
              ID_No: "21100863A 2BO3799",
              In_InsertTime: "2023-01-05 08:40:13",
              In_Result: "OK",
              In_Outer_Width1: 223.776,
              In_Outer_Width2: 223.922,
              In_Internal_Width: 216.254,
              In_Thickness: 4.453,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 09:31:05",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.7,
              Tp_JIG_MAX: 36.4,
              Tp_WJIG: 32.3,
              Tp_WJIG_MIN: 22,
              Tp_WJIG_MAX: 32.8,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 148,
              INDATE: "20230105",
              ID_No: "21100863A 2BP0041",
              In_InsertTime: "2023-01-05 08:35:44",
              In_Result: "OK",
              In_Outer_Width1: 223.779,
              In_Outer_Width2: 224.068,
              In_Internal_Width: 216.325,
              In_Thickness: 4.417,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 09:00:43",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 33.6,
              Tp_JIG_MAX: 38,
              Tp_WJIG: 27.8,
              Tp_WJIG_MIN: 23.3,
              Tp_WJIG_MAX: 29.3,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 152,
              INDATE: "20230105",
              ID_No: "21100863A 2BP0312",
              In_InsertTime: "2023-01-05 08:37:05",
              In_Result: "OK",
              In_Outer_Width1: 224.113,
              In_Outer_Width2: 224.097,
              In_Internal_Width: 216.415,
              In_Thickness: 4.445,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 09:11:22",
              Tp_Result: "OK",
              Tp_JIG: 30.7,
              Tp_JIG_MIN: 29.3,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 39.9,
              Tp_WJIG_MIN: 30.3,
              Tp_WJIG_MAX: 40.1,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 146,
              INDATE: "20230105",
              ID_No: "21100863A 2BQ0633",
              In_InsertTime: "2023-01-05 08:34:57",
              In_Result: "OK",
              In_Outer_Width1: 224.02,
              In_Outer_Width2: 224.164,
              In_Internal_Width: 216.313,
              In_Thickness: 4.476,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 09:20:59",
              Tp_Result: "OK",
              Tp_JIG: 53.1,
              Tp_JIG_MIN: 51.7,
              Tp_JIG_MAX: 54.1,
              Tp_WJIG: 42.5,
              Tp_WJIG_MIN: 34.8,
              Tp_WJIG_MAX: 44.7,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 165,
              INDATE: "20230105",
              ID_No: "21100863A 2BS2073",
              In_InsertTime: "2023-01-05 08:41:11",
              In_Result: "OK",
              In_Outer_Width1: 224.029,
              In_Outer_Width2: 223.831,
              In_Internal_Width: 216.318,
              In_Thickness: 4.409,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 09:32:06",
              Tp_Result: "OK",
              Tp_JIG: 33.2,
              Tp_JIG_MIN: 31.9,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 23,
              Tp_WJIG_MIN: 16.1,
              Tp_WJIG_MAX: 23.1,
              Tp_Vibration: 883,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 160,
              INDATE: "20230105",
              ID_No: "21100863A 2BT0061",
              In_InsertTime: "2023-01-05 08:38:37",
              In_Result: "OK",
              In_Outer_Width1: 223.968,
              In_Outer_Width2: 224.136,
              In_Internal_Width: 216.197,
              In_Thickness: 4.486,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 09:29:02",
              Tp_Result: "OK",
              Tp_JIG: 25.1,
              Tp_JIG_MIN: 23.6,
              Tp_JIG_MAX: 26.8,
              Tp_WJIG: 43.6,
              Tp_WJIG_MIN: 34.3,
              Tp_WJIG_MAX: 44.1,
              Tp_Vibration: 791,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 167,
              INDATE: "20230105",
              ID_No: "21100863A 2BT1979",
              In_InsertTime: "2023-01-05 08:41:33",
              In_Result: "OK",
              In_Outer_Width1: 223.921,
              In_Outer_Width2: 224.046,
              In_Internal_Width: 216.265,
              In_Thickness: 4.494,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:58:30",
              Tp_Result: "OK",
              Tp_JIG: 22.1,
              Tp_JIG_MIN: 21.2,
              Tp_JIG_MAX: 25.1,
              Tp_WJIG: 35.4,
              Tp_WJIG_MIN: 29.2,
              Tp_WJIG_MAX: 36.4,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 169,
              INDATE: "20230105",
              ID_No: "21100863A 2BT2662",
              In_InsertTime: "2023-01-05 08:42:25",
              In_Result: "OK",
              In_Outer_Width1: 223.787,
              In_Outer_Width2: 223.762,
              In_Internal_Width: 216.253,
              In_Thickness: 4.456,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 09:27:46",
              Tp_Result: "OK",
              Tp_JIG: 28.1,
              Tp_JIG_MIN: 26.5,
              Tp_JIG_MAX: 29.8,
              Tp_WJIG: 32.2,
              Tp_WJIG_MIN: 23.8,
              Tp_WJIG_MAX: 32.4,
              Tp_Vibration: 872,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 166,
              INDATE: "20230105",
              ID_No: "21100863A 2BT2796",
              In_InsertTime: "2023-01-05 08:41:23",
              In_Result: "OK",
              In_Outer_Width1: 223.722,
              In_Outer_Width2: 223.768,
              In_Internal_Width: 216.25,
              In_Thickness: 4.445,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 09:06:24",
              Tp_Result: "OK",
              Tp_JIG: 34.9,
              Tp_JIG_MIN: 33.5,
              Tp_JIG_MAX: 38.1,
              Tp_WJIG: 38.4,
              Tp_WJIG_MIN: 29,
              Tp_WJIG_MAX: 38.4,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 195,
              INDATE: "20230105",
              ID_No: "21100863A 2BT3994",
              In_InsertTime: "2023-01-05 08:49:36",
              In_Result: "OK",
              In_Outer_Width1: 223.822,
              In_Outer_Width2: 224.046,
              In_Internal_Width: 216.251,
              In_Thickness: 4.398,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 09:08:13",
              Tp_Result: "OK",
              Tp_JIG: 15.2,
              Tp_JIG_MIN: 14,
              Tp_JIG_MAX: 17.3,
              Tp_WJIG: 26.6,
              Tp_WJIG_MIN: 25.2,
              Tp_WJIG_MAX: 29.7,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1211,
              INDATE: "20230105",
              ID_No: "21100863A 2BT4572",
              In_InsertTime: "2023-01-05 15:36:36",
              In_Result: "OK",
              In_Outer_Width1: 223.89,
              In_Outer_Width2: 223.979,
              In_Internal_Width: 216.186,
              In_Thickness: 4.461,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 16:22:48",
              Tp_Result: "OK",
              Tp_JIG: 28.6,
              Tp_JIG_MIN: 27.6,
              Tp_JIG_MAX: 30.8,
              Tp_WJIG: 40.3,
              Tp_WJIG_MIN: 32,
              Tp_WJIG_MAX: 40.3,
              Tp_Vibration: 887,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 194,
              INDATE: "20230105",
              ID_No: "21100863A 2BT4912",
              In_InsertTime: "2023-01-05 08:49:27",
              In_Result: "OK",
              In_Outer_Width1: 224.182,
              In_Outer_Width2: 224.078,
              In_Internal_Width: 216.122,
              In_Thickness: 4.43,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 09:27:35",
              Tp_Result: "OK",
              Tp_JIG: 33.1,
              Tp_JIG_MIN: 33.1,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 34.8,
              Tp_WJIG_MIN: 26.7,
              Tp_WJIG_MAX: 36.5,
              Tp_Vibration: 892,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 196,
              INDATE: "20230105",
              ID_No: "21100863A 2BT5003",
              In_InsertTime: "2023-01-05 08:49:57",
              In_Result: "OK",
              In_Outer_Width1: 223.734,
              In_Outer_Width2: 223.949,
              In_Internal_Width: 216.205,
              In_Thickness: 4.413,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 09:35:24",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.5,
              Tp_WJIG: 39.9,
              Tp_WJIG_MIN: 34.9,
              Tp_WJIG_MAX: 40.8,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 197,
              INDATE: "20230105",
              ID_No: "21100863A 2BU0201",
              In_InsertTime: "2023-01-05 08:50:14",
              In_Result: "OK",
              In_Outer_Width1: 223.723,
              In_Outer_Width2: 223.777,
              In_Internal_Width: 216.143,
              In_Thickness: 4.453,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 09:40:23",
              Tp_Result: "OK",
              Tp_JIG: 25.1,
              Tp_JIG_MIN: 23.5,
              Tp_JIG_MAX: 26.5,
              Tp_WJIG: 37.5,
              Tp_WJIG_MIN: 29.1,
              Tp_WJIG_MAX: 37.6,
              Tp_Vibration: 851,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 168,
              INDATE: "20230105",
              ID_No: "21100863A 2BU1284",
              In_InsertTime: "2023-01-05 08:42:15",
              In_Result: "OK",
              In_Outer_Width1: 223.971,
              In_Outer_Width2: 223.808,
              In_Internal_Width: 216.309,
              In_Thickness: 4.488,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:59:32",
              Tp_Result: "OK",
              Tp_JIG: 34.8,
              Tp_JIG_MIN: 32.9,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 23.2,
              Tp_WJIG_MIN: 19.5,
              Tp_WJIG_MAX: 25,
              Tp_Vibration: 779,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 193,
              INDATE: "20230105",
              ID_No: "21100863A 2BU1483",
              In_InsertTime: "2023-01-05 08:49:19",
              In_Result: "OK",
              In_Outer_Width1: 223.872,
              In_Outer_Width2: 223.903,
              In_Internal_Width: 216.314,
              In_Thickness: 4.461,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 09:15:43",
              Tp_Result: "OK",
              Tp_JIG: 12.3,
              Tp_JIG_MIN: 11.4,
              Tp_JIG_MAX: 13.9,
              Tp_WJIG: 25.3,
              Tp_WJIG_MIN: 16.9,
              Tp_WJIG_MAX: 25.5,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 192,
              INDATE: "20230105",
              ID_No: "21100863A 2BU1492",
              In_InsertTime: "2023-01-05 08:49:11",
              In_Result: "OK",
              In_Outer_Width1: 223.883,
              In_Outer_Width2: 223.995,
              In_Internal_Width: 216.315,
              In_Thickness: 4.461,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 09:07:16",
              Tp_Result: "OK",
              Tp_JIG: 35.6,
              Tp_JIG_MIN: 34.1,
              Tp_JIG_MAX: 37.7,
              Tp_WJIG: 27.4,
              Tp_WJIG_MIN: 23.4,
              Tp_WJIG_MAX: 28.6,
              Tp_Vibration: 781,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 170,
              INDATE: "20230105",
              ID_No: "21100863A 2BU2559",
              In_InsertTime: "2023-01-05 08:42:33",
              In_Result: "OK",
              In_Outer_Width1: 223.983,
              In_Outer_Width2: 223.821,
              In_Internal_Width: 216.361,
              In_Thickness: 4.413,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 09:17:08",
              Tp_Result: "OK",
              Tp_JIG: 29.9,
              Tp_JIG_MIN: 29.5,
              Tp_JIG_MAX: 34.9,
              Tp_WJIG: 33.1,
              Tp_WJIG_MIN: 24,
              Tp_WJIG_MAX: 33.3,
              Tp_Vibration: 889,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 189,
              INDATE: "20230105",
              ID_No: "21100863A 2BU3040",
              In_InsertTime: "2023-01-05 08:48:20",
              In_Result: "OK",
              In_Outer_Width1: 224.238,
              In_Outer_Width2: 223.8,
              In_Internal_Width: 216.283,
              In_Thickness: 4.43,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 09:39:02",
              Tp_Result: "OK",
              Tp_JIG: 37.1,
              Tp_JIG_MIN: 36,
              Tp_JIG_MAX: 39.2,
              Tp_WJIG: 49.7,
              Tp_WJIG_MIN: 41.3,
              Tp_WJIG_MAX: 50.1,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1157,
              INDATE: "20230105",
              ID_No: "21100863A 2BU4438",
              In_InsertTime: "2023-01-05 15:19:44",
              In_Result: "OK",
              In_Outer_Width1: 223.908,
              In_Outer_Width2: 223.944,
              In_Internal_Width: 216.233,
              In_Thickness: 4.456,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 16:05:57",
              Tp_Result: "OK",
              Tp_JIG: 28.8,
              Tp_JIG_MIN: 27.4,
              Tp_JIG_MAX: 30.9,
              Tp_WJIG: 38.5,
              Tp_WJIG_MIN: 33.4,
              Tp_WJIG_MAX: 40.3,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1148,
              INDATE: "20230105",
              ID_No: "21100863A 2BU4801",
              In_InsertTime: "2023-01-05 15:17:36",
              In_Result: "OK",
              In_Outer_Width1: 223.792,
              In_Outer_Width2: 223.792,
              In_Internal_Width: 216.223,
              In_Thickness: 4.445,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 15:43:11",
              Tp_Result: "OK",
              Tp_JIG: 27.7,
              Tp_JIG_MIN: 26.4,
              Tp_JIG_MAX: 30.6,
              Tp_WJIG: 24.7,
              Tp_WJIG_MIN: 18.7,
              Tp_WJIG_MAX: 24.9,
              Tp_Vibration: 904,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1089,
              INDATE: "20230105",
              ID_No: "21100863A 2BU4834",
              In_InsertTime: "2023-01-05 15:00:00",
              In_Result: "OK",
              In_Outer_Width1: 224.107,
              In_Outer_Width2: 224,
              In_Internal_Width: 216.26,
              In_Thickness: 4.454,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 15:44:34",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 10.4,
              Tp_WJIG: 41.9,
              Tp_WJIG_MIN: 38.2,
              Tp_WJIG_MAX: 43.2,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1106,
              INDATE: "20230105",
              ID_No: "21100863A 2C10619",
              In_InsertTime: "2023-01-05 15:05:10",
              In_Result: "OK",
              In_Outer_Width1: 223.956,
              In_Outer_Width2: 223.828,
              In_Internal_Width: 216.192,
              In_Thickness: 4.5,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 15:39:45",
              Tp_Result: "OK",
              Tp_JIG: 30.6,
              Tp_JIG_MIN: 30.1,
              Tp_JIG_MAX: 35.2,
              Tp_WJIG: 34.1,
              Tp_WJIG_MIN: 27.8,
              Tp_WJIG_MAX: 35.3,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 88,
              INDATE: "20230105",
              ID_No: "21100863A 2C20030",
              In_InsertTime: "2023-01-05 08:17:44",
              In_Result: "OK",
              In_Outer_Width1: 224.045,
              In_Outer_Width2: 223.781,
              In_Internal_Width: 216.236,
              In_Thickness: 4.495,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 08:34:51",
              Tp_Result: "OK",
              Tp_JIG: 15,
              Tp_JIG_MIN: 13.4,
              Tp_JIG_MAX: 17.5,
              Tp_WJIG: 45.5,
              Tp_WJIG_MIN: 39.8,
              Tp_WJIG_MAX: 46.2,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 81,
              INDATE: "20230105",
              ID_No: "21100863A 2C20522",
              In_InsertTime: "2023-01-05 08:14:20",
              In_Result: "OK",
              In_Outer_Width1: 223.874,
              In_Outer_Width2: 223.854,
              In_Internal_Width: 216.19,
              In_Thickness: 4.458,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 08:40:34",
              Tp_Result: "OK",
              Tp_JIG: 31.3,
              Tp_JIG_MIN: 29.9,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 36.4,
              Tp_WJIG_MIN: 29.6,
              Tp_WJIG_MAX: 38,
              Tp_Vibration: 858,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 92,
              INDATE: "20230105",
              ID_No: "21100863A 2C31073",
              In_InsertTime: "2023-01-05 08:18:37",
              In_Result: "OK",
              In_Outer_Width1: 224.076,
              In_Outer_Width2: 223.972,
              In_Internal_Width: 216.25,
              In_Thickness: 4.507,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 09:04:00",
              Tp_Result: "OK",
              Tp_JIG: 53.2,
              Tp_JIG_MIN: 51.8,
              Tp_JIG_MAX: 54.3,
              Tp_WJIG: 58.3,
              Tp_WJIG_MIN: 49.1,
              Tp_WJIG_MAX: 60,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1156,
              INDATE: "20230105",
              ID_No: "21100863A 2C32255",
              In_InsertTime: "2023-01-05 15:19:32",
              In_Result: "OK",
              In_Outer_Width1: 224.083,
              In_Outer_Width2: 223.965,
              In_Internal_Width: 216.223,
              In_Thickness: 4.506,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 16:12:13",
              Tp_Result: "OK",
              Tp_JIG: 25.4,
              Tp_JIG_MIN: 23.6,
              Tp_JIG_MAX: 27,
              Tp_WJIG: 47.7,
              Tp_WJIG_MIN: 37.9,
              Tp_WJIG_MAX: 48.6,
              Tp_Vibration: 901,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 91,
              INDATE: "20230105",
              ID_No: "21100863A 2C32814",
              In_InsertTime: "2023-01-05 08:18:24",
              In_Result: "OK",
              In_Outer_Width1: 223.649,
              In_Outer_Width2: 223.694,
              In_Internal_Width: 216.226,
              In_Thickness: 4.444,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 09:08:30",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 36.8,
              Tp_WJIG_MIN: 29.7,
              Tp_WJIG_MAX: 39.3,
              Tp_Vibration: 888,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 77,
              INDATE: "20230105",
              ID_No: "21100863A 2C33574",
              In_InsertTime: "2023-01-05 08:13:23",
              In_Result: "OK",
              In_Outer_Width1: 224.189,
              In_Outer_Width2: 223.999,
              In_Internal_Width: 216.354,
              In_Thickness: 4.427,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:30:33",
              Tp_Result: "OK",
              Tp_JIG: 21.4,
              Tp_JIG_MIN: 19.9,
              Tp_JIG_MAX: 23.9,
              Tp_WJIG: 34.4,
              Tp_WJIG_MIN: 29.3,
              Tp_WJIG_MAX: 36.7,
              Tp_Vibration: 901,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 93,
              INDATE: "20230105",
              ID_No: "21100863A 2C34815",
              In_InsertTime: "2023-01-05 08:18:45",
              In_Result: "OK",
              In_Outer_Width1: 224.046,
              In_Outer_Width2: 223.846,
              In_Internal_Width: 216.284,
              In_Thickness: 4.465,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 09:09:30",
              Tp_Result: "OK",
              Tp_JIG: 33.2,
              Tp_JIG_MIN: 31,
              Tp_JIG_MAX: 33.8,
              Tp_WJIG: 43,
              Tp_WJIG_MIN: 37.5,
              Tp_WJIG_MAX: 44.2,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 96,
              INDATE: "20230105",
              ID_No: "21100863A 2C50452",
              In_InsertTime: "2023-01-05 08:19:36",
              In_Result: "OK",
              In_Outer_Width1: 223.943,
              In_Outer_Width2: 223.956,
              In_Internal_Width: 216.315,
              In_Thickness: 4.436,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 08:54:03",
              Tp_Result: "OK",
              Tp_JIG: 30.8,
              Tp_JIG_MIN: 30.4,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 37.3,
              Tp_WJIG_MIN: 30,
              Tp_WJIG_MAX: 38.3,
              Tp_Vibration: 879,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 74,
              INDATE: "20230105",
              ID_No: "21100863A 2C50655",
              In_InsertTime: "2023-01-05 08:12:40",
              In_Result: "OK",
              In_Outer_Width1: 224.133,
              In_Outer_Width2: 223.889,
              In_Internal_Width: 216.291,
              In_Thickness: 4.48,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 08:58:20",
              Tp_Result: "OK",
              Tp_JIG: 53.3,
              Tp_JIG_MIN: 51.8,
              Tp_JIG_MAX: 54.2,
              Tp_WJIG: 47.4,
              Tp_WJIG_MIN: 38.5,
              Tp_WJIG_MAX: 49.2,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 75,
              INDATE: "20230105",
              ID_No: "21100863A 2C51890",
              In_InsertTime: "2023-01-05 08:12:56",
              In_Result: "OK",
              In_Outer_Width1: 223.825,
              In_Outer_Width2: 223.782,
              In_Internal_Width: 216.236,
              In_Thickness: 4.448,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 09:03:51",
              Tp_Result: "OK",
              Tp_JIG: 33.4,
              Tp_JIG_MIN: 31.7,
              Tp_JIG_MAX: 33.9,
              Tp_WJIG: 29.3,
              Tp_WJIG_MIN: 24,
              Tp_WJIG_MAX: 30.2,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 94,
              INDATE: "20230105",
              ID_No: "21100863A 2C51911",
              In_InsertTime: "2023-01-05 08:18:56",
              In_Result: "OK",
              In_Outer_Width1: 223.804,
              In_Outer_Width2: 223.799,
              In_Internal_Width: 216.241,
              In_Thickness: 4.433,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 08:43:39",
              Tp_Result: "OK",
              Tp_JIG: 34.8,
              Tp_JIG_MIN: 33,
              Tp_JIG_MAX: 37.6,
              Tp_WJIG: 42,
              Tp_WJIG_MIN: 36.4,
              Tp_WJIG_MAX: 43.7,
              Tp_Vibration: 792,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 95,
              INDATE: "20230105",
              ID_No: "21100863A 2C52074",
              In_InsertTime: "2023-01-05 08:19:10",
              In_Result: "OK",
              In_Outer_Width1: 223.767,
              In_Outer_Width2: 223.987,
              In_Internal_Width: 216.273,
              In_Thickness: 4.408,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:36:08",
              Tp_Result: "OK",
              Tp_JIG: 21.7,
              Tp_JIG_MIN: 20.1,
              Tp_JIG_MAX: 24.1,
              Tp_WJIG: 34,
              Tp_WJIG_MIN: 29.8,
              Tp_WJIG_MAX: 36,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 90,
              INDATE: "20230105",
              ID_No: "21100863A 2C61669",
              In_InsertTime: "2023-01-05 08:18:10",
              In_Result: "OK",
              In_Outer_Width1: 223.95,
              In_Outer_Width2: 223.964,
              In_Internal_Width: 216.291,
              In_Thickness: 4.437,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 08:42:44",
              Tp_Result: "OK",
              Tp_JIG: 27.2,
              Tp_JIG_MIN: 25.4,
              Tp_JIG_MAX: 29.8,
              Tp_WJIG: 32.1,
              Tp_WJIG_MIN: 25.7,
              Tp_WJIG_MAX: 32.1,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 98,
              INDATE: "20230105",
              ID_No: "21100863A 2C63592",
              In_InsertTime: "2023-01-05 08:19:58",
              In_Result: "OK",
              In_Outer_Width1: 224.047,
              In_Outer_Width2: 224.019,
              In_Internal_Width: 216.269,
              In_Thickness: 4.424,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:37:05",
              Tp_Result: "OK",
              Tp_JIG: 34.8,
              Tp_JIG_MIN: 32.7,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 33.5,
              Tp_WJIG_MIN: 28.9,
              Tp_WJIG_MAX: 35,
              Tp_Vibration: 776,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 97,
              INDATE: "20230105",
              ID_No: "21100863A 2C64156",
              In_InsertTime: "2023-01-05 08:19:44",
              In_Result: "OK",
              In_Outer_Width1: 223.878,
              In_Outer_Width2: 223.991,
              In_Internal_Width: 216.252,
              In_Thickness: 4.445,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 08:46:15",
              Tp_Result: "OK",
              Tp_JIG: 32.7,
              Tp_JIG_MIN: 31.2,
              Tp_JIG_MAX: 35.8,
              Tp_WJIG: 40.5,
              Tp_WJIG_MIN: 32.3,
              Tp_WJIG_MAX: 40.7,
              Tp_Vibration: 874,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 99,
              INDATE: "20230105",
              ID_No: "21100863A 2C71292",
              In_InsertTime: "2023-01-05 08:20:09",
              In_Result: "OK",
              In_Outer_Width1: 223.916,
              In_Outer_Width2: 224.102,
              In_Internal_Width: 216.221,
              In_Thickness: 4.491,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 09:05:15",
              Tp_Result: "OK",
              Tp_JIG: 28,
              Tp_JIG_MIN: 26.3,
              Tp_JIG_MAX: 30,
              Tp_WJIG: 42,
              Tp_WJIG_MIN: 34.4,
              Tp_WJIG_MAX: 42.4,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 57,
              INDATE: "20230105",
              ID_No: "21100863A 2C71992",
              In_InsertTime: "2023-01-05 08:07:17",
              In_Result: "OK",
              In_Outer_Width1: 223.852,
              In_Outer_Width2: 223.849,
              In_Internal_Width: 216.191,
              In_Thickness: 4.41,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 08:58:13",
              Tp_Result: "OK",
              Tp_JIG: 33.4,
              Tp_JIG_MIN: 31.5,
              Tp_JIG_MAX: 33.7,
              Tp_WJIG: 40,
              Tp_WJIG_MIN: 33.4,
              Tp_WJIG_MAX: 40.7,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 60,
              INDATE: "20230105",
              ID_No: "21100863A 2CF0188",
              In_InsertTime: "2023-01-05 08:08:02",
              In_Result: "OK",
              In_Outer_Width1: 223.973,
              In_Outer_Width2: 223.915,
              In_Internal_Width: 216.22,
              In_Thickness: 4.466,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 08:53:49",
              Tp_Result: "OK",
              Tp_JIG: 28,
              Tp_JIG_MIN: 26.5,
              Tp_JIG_MAX: 30,
              Tp_WJIG: 36.6,
              Tp_WJIG_MIN: 31.5,
              Tp_WJIG_MAX: 36.6,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 70,
              INDATE: "20230105",
              ID_No: "21100863A 2CF0284",
              In_InsertTime: "2023-01-05 08:10:20",
              In_Result: "OK",
              In_Outer_Width1: 223.831,
              In_Outer_Width2: 223.803,
              In_Internal_Width: 216.206,
              In_Thickness: 4.46,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 08:56:23",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.5,
              Tp_WJIG: 41.7,
              Tp_WJIG_MIN: 39.1,
              Tp_WJIG_MAX: 44.1,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 73,
              INDATE: "20230105",
              ID_No: "21100863A 2CF0364",
              In_InsertTime: "2023-01-05 08:12:25",
              In_Result: "OK",
              In_Outer_Width1: 224.042,
              In_Outer_Width2: 223.704,
              In_Internal_Width: 216.209,
              In_Thickness: 4.467,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 08:36:56",
              Tp_Result: "OK",
              Tp_JIG: 27.3,
              Tp_JIG_MIN: 25.9,
              Tp_JIG_MAX: 29.8,
              Tp_WJIG: 28.9,
              Tp_WJIG_MIN: 23.3,
              Tp_WJIG_MAX: 28.9,
              Tp_Vibration: 792,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1208,
              INDATE: "20230105",
              ID_No: "21100863A 2CF1901",
              In_InsertTime: "2023-01-05 15:35:46",
              In_Result: "OK",
              In_Outer_Width1: 224.179,
              In_Outer_Width2: 224.238,
              In_Internal_Width: 216.197,
              In_Thickness: 4.444,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 16:02:59",
              Tp_Result: "OK",
              Tp_JIG: 35.8,
              Tp_JIG_MIN: 34.6,
              Tp_JIG_MAX: 38.5,
              Tp_WJIG: 51.5,
              Tp_WJIG_MIN: 44.2,
              Tp_WJIG_MAX: 51.6,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 71,
              INDATE: "20230105",
              ID_No: "21100863A 2CF4379",
              In_InsertTime: "2023-01-05 08:10:29",
              In_Result: "OK",
              In_Outer_Width1: 223.772,
              In_Outer_Width2: 223.974,
              In_Internal_Width: 216.173,
              In_Thickness: 4.496,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 09:02:51",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 34,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 42.5,
              Tp_WJIG_MIN: 32,
              Tp_WJIG_MAX: 42.8,
              Tp_Vibration: 882,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 64,
              INDATE: "20230105",
              ID_No: "21100863A 2CJ3552",
              In_InsertTime: "2023-01-05 08:08:56",
              In_Result: "OK",
              In_Outer_Width1: 223.841,
              In_Outer_Width2: 224.019,
              In_Internal_Width: 216.217,
              In_Thickness: 4.421,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 08:59:25",
              Tp_Result: "OK",
              Tp_JIG: 37.5,
              Tp_JIG_MIN: 36,
              Tp_JIG_MAX: 39.3,
              Tp_WJIG: 37.6,
              Tp_WJIG_MIN: 30.7,
              Tp_WJIG_MAX: 40.1,
              Tp_Vibration: 802,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 61,
              INDATE: "20230105",
              ID_No: "21100863A 2CK0558",
              In_InsertTime: "2023-01-05 08:08:21",
              In_Result: "OK",
              In_Outer_Width1: 223.848,
              In_Outer_Width2: 223.895,
              In_Internal_Width: 216.124,
              In_Thickness: 4.476,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 08:42:33",
              Tp_Result: "OK",
              Tp_JIG: 30.6,
              Tp_JIG_MIN: 30.4,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 30.6,
              Tp_WJIG_MIN: 24.4,
              Tp_WJIG_MAX: 31.7,
              Tp_Vibration: 883,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 62,
              INDATE: "20230105",
              ID_No: "21100863A 2CK2543",
              In_InsertTime: "2023-01-05 08:08:32",
              In_Result: "OK",
              In_Outer_Width1: 223.869,
              In_Outer_Width2: 223.978,
              In_Internal_Width: 216.259,
              In_Thickness: 4.446,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:25:54",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.2,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 19.8,
              Tp_WJIG_MIN: 16,
              Tp_WJIG_MAX: 20.7,
              Tp_Vibration: 781,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 63,
              INDATE: "20230105",
              ID_No: "21100863A 2CK3710",
              In_InsertTime: "2023-01-05 08:08:47",
              In_Result: "OK",
              In_Outer_Width1: 224.194,
              In_Outer_Width2: 223.978,
              In_Internal_Width: 216.318,
              In_Thickness: 4.413,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 08:34:54",
              Tp_Result: "OK",
              Tp_JIG: 32.9,
              Tp_JIG_MIN: 31.6,
              Tp_JIG_MAX: 35.8,
              Tp_WJIG: 31.7,
              Tp_WJIG_MIN: 26.2,
              Tp_WJIG_MAX: 31.8,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 69,
              INDATE: "20230105",
              ID_No: "21100863A 2CL0926",
              In_InsertTime: "2023-01-05 08:09:53",
              In_Result: "OK",
              In_Outer_Width1: 224.082,
              In_Outer_Width2: 223.904,
              In_Internal_Width: 216.302,
              In_Thickness: 4.384,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 09:00:38",
              Tp_Result: "OK",
              Tp_JIG: 25.2,
              Tp_JIG_MIN: 23.6,
              Tp_JIG_MAX: 26.9,
              Tp_WJIG: 37.4,
              Tp_WJIG_MIN: 33.4,
              Tp_WJIG_MAX: 39.5,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 232,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0348",
              In_InsertTime: "2023-01-05 09:01:19",
              In_Result: "OK",
              In_Outer_Width1: 224.16,
              In_Outer_Width2: 223.991,
              In_Internal_Width: 216.233,
              In_Thickness: 4.459,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 09:46:34",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.2,
              Tp_WJIG: 32,
              Tp_WJIG_MIN: 28.8,
              Tp_WJIG_MAX: 34.1,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 251,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0372",
              In_InsertTime: "2023-01-05 09:07:18",
              In_Result: "OK",
              In_Outer_Width1: 224.026,
              In_Outer_Width2: 224.035,
              In_Internal_Width: 216.231,
              In_Thickness: 4.472,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 10:09:52",
              Tp_Result: "OK",
              Tp_JIG: 33.1,
              Tp_JIG_MIN: 31.6,
              Tp_JIG_MAX: 33.7,
              Tp_WJIG: 35.2,
              Tp_WJIG_MIN: 27.3,
              Tp_WJIG_MAX: 35.4,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 260,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0397",
              In_InsertTime: "2023-01-05 09:10:48",
              In_Result: "OK",
              In_Outer_Width1: 224.102,
              In_Outer_Width2: 223.912,
              In_Internal_Width: 216.286,
              In_Thickness: 4.466,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 10:12:57",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 38.8,
              Tp_WJIG_MIN: 27.3,
              Tp_WJIG_MAX: 39,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 271,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0404",
              In_InsertTime: "2023-01-05 09:13:35",
              In_Result: "OK",
              In_Outer_Width1: 223.962,
              In_Outer_Width2: 224.01,
              In_Internal_Width: 216.212,
              In_Thickness: 4.484,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 10:16:31",
              Tp_Result: "OK",
              Tp_JIG: 37.1,
              Tp_JIG_MIN: 35.7,
              Tp_JIG_MAX: 38.9,
              Tp_WJIG: 42.4,
              Tp_WJIG_MIN: 34.4,
              Tp_WJIG_MAX: 43,
              Tp_Vibration: 852,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 261,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0407",
              In_InsertTime: "2023-01-05 09:11:00",
              In_Result: "OK",
              In_Outer_Width1: 224.048,
              In_Outer_Width2: 224.217,
              In_Internal_Width: 216.241,
              In_Thickness: 4.479,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 09:38:38",
              Tp_Result: "OK",
              Tp_JIG: 12.4,
              Tp_JIG_MIN: 11.4,
              Tp_JIG_MAX: 14,
              Tp_WJIG: 31.6,
              Tp_WJIG_MIN: 22.9,
              Tp_WJIG_MAX: 32.7,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 249,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0418",
              In_InsertTime: "2023-01-05 09:06:50",
              In_Result: "OK",
              In_Outer_Width1: 224.139,
              In_Outer_Width2: 224.079,
              In_Internal_Width: 216.319,
              In_Thickness: 4.488,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 09:44:53",
              Tp_Result: "OK",
              Tp_JIG: 32.8,
              Tp_JIG_MIN: 32.7,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 43.7,
              Tp_WJIG_MIN: 33.2,
              Tp_WJIG_MAX: 43.8,
              Tp_Vibration: 901,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 264,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0436",
              In_InsertTime: "2023-01-05 09:11:43",
              In_Result: "OK",
              In_Outer_Width1: 223.765,
              In_Outer_Width2: 223.809,
              In_Internal_Width: 216.167,
              In_Thickness: 4.464,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 09:45:58",
              Tp_Result: "OK",
              Tp_JIG: 33.3,
              Tp_JIG_MIN: 30.6,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 32.9,
              Tp_WJIG_MIN: 26,
              Tp_WJIG_MAX: 34,
              Tp_Vibration: 902,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 263,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0458",
              In_InsertTime: "2023-01-05 09:11:35",
              In_Result: "OK",
              In_Outer_Width1: 223.89,
              In_Outer_Width2: 223.851,
              In_Internal_Width: 216.265,
              In_Thickness: 4.478,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 10:09:58",
              Tp_Result: "OK",
              Tp_JIG: 53.3,
              Tp_JIG_MIN: 52.2,
              Tp_JIG_MAX: 54.1,
              Tp_WJIG: 48.4,
              Tp_WJIG_MIN: 38.2,
              Tp_WJIG_MAX: 50.4,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 231,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0623",
              In_InsertTime: "2023-01-05 09:01:04",
              In_Result: "OK",
              In_Outer_Width1: 224.142,
              In_Outer_Width2: 224.018,
              In_Internal_Width: 216.167,
              In_Thickness: 4.444,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 09:39:07",
              Tp_Result: "OK",
              Tp_JIG: 33.4,
              Tp_JIG_MIN: 33.3,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 31.4,
              Tp_WJIG_MIN: 24.5,
              Tp_WJIG_MAX: 33.9,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 259,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0674",
              In_InsertTime: "2023-01-05 09:10:38",
              In_Result: "OK",
              In_Outer_Width1: 224.099,
              In_Outer_Width2: 224.14,
              In_Internal_Width: 216.193,
              In_Thickness: 4.436,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 10:07:21",
              Tp_Result: "OK",
              Tp_JIG: 9.5,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 11.1,
              Tp_WJIG: 45.9,
              Tp_WJIG_MIN: 41,
              Tp_WJIG_MAX: 47.2,
              Tp_Vibration: 883,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 289,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0693",
              In_InsertTime: "2023-01-05 09:19:09",
              In_Result: "OK",
              In_Outer_Width1: 223.886,
              In_Outer_Width2: 223.88,
              In_Internal_Width: 216.201,
              In_Thickness: 4.479,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 09:46:18",
              Tp_Result: "OK",
              Tp_JIG: 26.6,
              Tp_JIG_MIN: 24.7,
              Tp_JIG_MAX: 29.6,
              Tp_WJIG: 43.7,
              Tp_WJIG_MIN: 36,
              Tp_WJIG_MAX: 43.7,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 303,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0716",
              In_InsertTime: "2023-01-05 09:23:58",
              In_Result: "OK",
              In_Outer_Width1: 224.01,
              In_Outer_Width2: 223.821,
              In_Internal_Width: 216.139,
              In_Thickness: 4.455,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 10:10:05",
              Tp_Result: "OK",
              Tp_JIG: 32.4,
              Tp_JIG_MIN: 32.4,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 40.6,
              Tp_WJIG_MIN: 32.6,
              Tp_WJIG_MAX: 43.4,
              Tp_Vibration: 902,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 305,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0736",
              In_InsertTime: "2023-01-05 09:24:21",
              In_Result: "OK",
              In_Outer_Width1: 224.101,
              In_Outer_Width2: 223.741,
              In_Internal_Width: 216.165,
              In_Thickness: 4.462,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 10:23:13",
              Tp_Result: "OK",
              Tp_JIG: 32,
              Tp_JIG_MIN: 31,
              Tp_JIG_MAX: 33.6,
              Tp_WJIG: 30.1,
              Tp_WJIG_MIN: 25.6,
              Tp_WJIG_MAX: 30.5,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 308,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0744",
              In_InsertTime: "2023-01-05 09:25:12",
              In_Result: "OK",
              In_Outer_Width1: 223.755,
              In_Outer_Width2: 223.826,
              In_Internal_Width: 216.14,
              In_Thickness: 4.452,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 10:05:16",
              Tp_Result: "OK",
              Tp_JIG: 32.1,
              Tp_JIG_MIN: 30.8,
              Tp_JIG_MAX: 34.4,
              Tp_WJIG: 43.2,
              Tp_WJIG_MIN: 37.3,
              Tp_WJIG_MAX: 45.3,
              Tp_Vibration: 904,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 307,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0747",
              In_InsertTime: "2023-01-05 09:24:41",
              In_Result: "OK",
              In_Outer_Width1: 224.087,
              In_Outer_Width2: 223.851,
              In_Internal_Width: 216.24,
              In_Thickness: 4.455,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 10:26:37",
              Tp_Result: "OK",
              Tp_JIG: 32.9,
              Tp_JIG_MIN: 30.7,
              Tp_JIG_MAX: 33.5,
              Tp_WJIG: 53.2,
              Tp_WJIG_MIN: 44.9,
              Tp_WJIG_MAX: 53.3,
              Tp_Vibration: 877,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 281,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0767",
              In_InsertTime: "2023-01-05 09:17:17",
              In_Result: "OK",
              In_Outer_Width1: 223.817,
              In_Outer_Width2: 223.979,
              In_Internal_Width: 216.137,
              In_Thickness: 4.461,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 09:44:14",
              Tp_Result: "OK",
              Tp_JIG: 12.5,
              Tp_JIG_MIN: 11.3,
              Tp_JIG_MAX: 13.9,
              Tp_WJIG: 25.4,
              Tp_WJIG_MIN: 18.9,
              Tp_WJIG_MAX: 26.6,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 219,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0769",
              In_InsertTime: "2023-01-05 08:57:52",
              In_Result: "OK",
              In_Outer_Width1: 223.925,
              In_Outer_Width2: 223.865,
              In_Internal_Width: 216.153,
              In_Thickness: 4.444,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 09:49:00",
              Tp_Result: "OK",
              Tp_JIG: 33.4,
              Tp_JIG_MIN: 31.3,
              Tp_JIG_MAX: 33.7,
              Tp_WJIG: 33.7,
              Tp_WJIG_MIN: 27.4,
              Tp_WJIG_MAX: 34.6,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 253,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0770",
              In_InsertTime: "2023-01-05 09:08:01",
              In_Result: "OK",
              In_Outer_Width1: 224.013,
              In_Outer_Width2: 224.039,
              In_Internal_Width: 216.092,
              In_Thickness: 4.475,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 10:10:52",
              Tp_Result: "OK",
              Tp_JIG: 37.5,
              Tp_JIG_MIN: 36.2,
              Tp_JIG_MAX: 39.1,
              Tp_WJIG: 44,
              Tp_WJIG_MIN: 37.9,
              Tp_WJIG_MAX: 45.9,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 297,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0794",
              In_InsertTime: "2023-01-05 09:22:12",
              In_Result: "OK",
              In_Outer_Width1: 224.024,
              In_Outer_Width2: 223.91,
              In_Internal_Width: 216.145,
              In_Thickness: 4.471,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 09:40:36",
              Tp_Result: "OK",
              Tp_JIG: 34.3,
              Tp_JIG_MIN: 33.3,
              Tp_JIG_MAX: 36.8,
              Tp_WJIG: 32.4,
              Tp_WJIG_MIN: 28.8,
              Tp_WJIG_MAX: 34.2,
              Tp_Vibration: 781,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 284,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0811",
              In_InsertTime: "2023-01-05 09:17:47",
              In_Result: "OK",
              In_Outer_Width1: 223.831,
              In_Outer_Width2: 224.045,
              In_Internal_Width: 216.148,
              In_Thickness: 4.469,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 09:45:21",
              Tp_Result: "OK",
              Tp_JIG: 32.3,
              Tp_JIG_MIN: 31.4,
              Tp_JIG_MAX: 34.9,
              Tp_WJIG: 35.5,
              Tp_WJIG_MIN: 31.5,
              Tp_WJIG_MAX: 37.5,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 279,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0852",
              In_InsertTime: "2023-01-05 09:16:34",
              In_Result: "OK",
              In_Outer_Width1: 224.188,
              In_Outer_Width2: 223.907,
              In_Internal_Width: 216.288,
              In_Thickness: 4.44,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 10:15:35",
              Tp_Result: "OK",
              Tp_JIG: 52.6,
              Tp_JIG_MIN: 51.2,
              Tp_JIG_MAX: 53.9,
              Tp_WJIG: 62.7,
              Tp_WJIG_MIN: 54,
              Tp_WJIG_MAX: 64.9,
              Tp_Vibration: 888,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 220,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0855",
              In_InsertTime: "2023-01-05 08:58:10",
              In_Result: "OK",
              In_Outer_Width1: 224.156,
              In_Outer_Width2: 224.112,
              In_Internal_Width: 216.119,
              In_Thickness: 4.455,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 09:23:28",
              Tp_Result: "OK",
              Tp_JIG: 35.6,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 38.3,
              Tp_WJIG: 41.7,
              Tp_WJIG_MIN: 35.7,
              Tp_WJIG_MAX: 43.4,
              Tp_Vibration: 793,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 262,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0856",
              In_InsertTime: "2023-01-05 09:11:24",
              In_Result: "OK",
              In_Outer_Width1: 223.831,
              In_Outer_Width2: 223.78,
              In_Internal_Width: 216.18,
              In_Thickness: 4.434,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 09:29:29",
              Tp_Result: "OK",
              Tp_JIG: 35.6,
              Tp_JIG_MIN: 34.2,
              Tp_JIG_MAX: 37.4,
              Tp_WJIG: 30.4,
              Tp_WJIG_MIN: 27.4,
              Tp_WJIG_MAX: 32.9,
              Tp_Vibration: 772,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 282,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0862",
              In_InsertTime: "2023-01-05 09:17:29",
              In_Result: "OK",
              In_Outer_Width1: 224.13,
              In_Outer_Width2: 224.051,
              In_Internal_Width: 216.212,
              In_Thickness: 4.424,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 09:51:32",
              Tp_Result: "OK",
              Tp_JIG: 33.3,
              Tp_JIG_MIN: 29.1,
              Tp_JIG_MAX: 34.8,
              Tp_WJIG: 34.3,
              Tp_WJIG_MIN: 27.7,
              Tp_WJIG_MAX: 35.1,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 268,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0868",
              In_InsertTime: "2023-01-05 09:12:46",
              In_Result: "OK",
              In_Outer_Width1: 224.126,
              In_Outer_Width2: 224.028,
              In_Internal_Width: 216.179,
              In_Thickness: 4.475,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 10:11:01",
              Tp_Result: "OK",
              Tp_JIG: 28.1,
              Tp_JIG_MIN: 27,
              Tp_JIG_MAX: 29.9,
              Tp_WJIG: 40.2,
              Tp_WJIG_MIN: 34.5,
              Tp_WJIG_MAX: 42.5,
              Tp_Vibration: 889,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 254,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0873",
              In_InsertTime: "2023-01-05 09:08:27",
              In_Result: "OK",
              In_Outer_Width1: 223.942,
              In_Outer_Width2: 223.731,
              In_Internal_Width: 216.191,
              In_Thickness: 4.43,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 09:35:08",
              Tp_Result: "OK",
              Tp_JIG: 27.8,
              Tp_JIG_MIN: 26,
              Tp_JIG_MAX: 30.3,
              Tp_WJIG: 42.6,
              Tp_WJIG_MIN: 35.5,
              Tp_WJIG_MAX: 42.7,
              Tp_Vibration: 791,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 270,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0877",
              In_InsertTime: "2023-01-05 09:13:10",
              In_Result: "OK",
              In_Outer_Width1: 223.856,
              In_Outer_Width2: 224.012,
              In_Internal_Width: 216.167,
              In_Thickness: 4.43,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 10:12:07",
              Tp_Result: "OK",
              Tp_JIG: 31.2,
              Tp_JIG_MIN: 30.2,
              Tp_JIG_MAX: 33,
              Tp_WJIG: 43.6,
              Tp_WJIG_MIN: 38.4,
              Tp_WJIG_MAX: 45.3,
              Tp_Vibration: 891,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 301,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0879",
              In_InsertTime: "2023-01-05 09:23:29",
              In_Result: "OK",
              In_Outer_Width1: 224.147,
              In_Outer_Width2: 223.964,
              In_Internal_Width: 216.199,
              In_Thickness: 4.426,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 10:22:14",
              Tp_Result: "OK",
              Tp_JIG: 28.1,
              Tp_JIG_MIN: 26.5,
              Tp_JIG_MAX: 29.8,
              Tp_WJIG: 42.4,
              Tp_WJIG_MIN: 36.7,
              Tp_WJIG_MAX: 45.3,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 223,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0883",
              In_InsertTime: "2023-01-05 08:59:24",
              In_Result: "OK",
              In_Outer_Width1: 224.024,
              In_Outer_Width2: 224.03,
              In_Internal_Width: 216.189,
              In_Thickness: 4.468,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 09:44:38",
              Tp_Result: "OK",
              Tp_JIG: 28.2,
              Tp_JIG_MIN: 26.5,
              Tp_JIG_MAX: 29.9,
              Tp_WJIG: 37.5,
              Tp_WJIG_MIN: 31.9,
              Tp_WJIG_MAX: 40,
              Tp_Vibration: 887,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 222,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0884",
              In_InsertTime: "2023-01-05 08:59:15",
              In_Result: "OK",
              In_Outer_Width1: 224.085,
              In_Outer_Width2: 223.903,
              In_Internal_Width: 216.156,
              In_Thickness: 4.466,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 09:16:13",
              Tp_Result: "OK",
              Tp_JIG: 34.6,
              Tp_JIG_MIN: 32.8,
              Tp_JIG_MAX: 34.8,
              Tp_WJIG: 25.4,
              Tp_WJIG_MIN: 21.6,
              Tp_WJIG_MAX: 26.6,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 139,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0891",
              In_InsertTime: "2023-01-05 08:32:15",
              In_Result: "OK",
              In_Outer_Width1: 223.843,
              In_Outer_Width2: 224.009,
              In_Internal_Width: 216.151,
              In_Thickness: 4.449,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 08:58:40",
              Tp_Result: "OK",
              Tp_JIG: 12.4,
              Tp_JIG_MIN: 10.9,
              Tp_JIG_MAX: 13.8,
              Tp_WJIG: 57.7,
              Tp_WJIG_MIN: 48.5,
              Tp_WJIG_MAX: 59.6,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 269,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0893",
              In_InsertTime: "2023-01-05 09:13:01",
              In_Result: "OK",
              In_Outer_Width1: 224.074,
              In_Outer_Width2: 224.014,
              In_Internal_Width: 216.133,
              In_Thickness: 4.453,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 10:15:28",
              Tp_Result: "OK",
              Tp_JIG: 33.2,
              Tp_JIG_MIN: 31.3,
              Tp_JIG_MAX: 33.6,
              Tp_WJIG: 36.1,
              Tp_WJIG_MIN: 30.6,
              Tp_WJIG_MAX: 37.6,
              Tp_Vibration: 876,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 258,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0896",
              In_InsertTime: "2023-01-05 09:10:26",
              In_Result: "OK",
              In_Outer_Width1: 224.036,
              In_Outer_Width2: 223.875,
              In_Internal_Width: 216.172,
              In_Thickness: 4.441,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 09:27:24",
              Tp_Result: "OK",
              Tp_JIG: 34.3,
              Tp_JIG_MIN: 32.5,
              Tp_JIG_MAX: 34.7,
              Tp_WJIG: 46.9,
              Tp_WJIG_MIN: 41.7,
              Tp_WJIG_MAX: 48.1,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 217,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0897",
              In_InsertTime: "2023-01-05 08:56:54",
              In_Result: "OK",
              In_Outer_Width1: 224.106,
              In_Outer_Width2: 223.958,
              In_Internal_Width: 216.144,
              In_Thickness: 4.454,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 09:48:02",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 35.9,
              Tp_WJIG_MIN: 29.5,
              Tp_WJIG_MAX: 37.9,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 298,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0914",
              In_InsertTime: "2023-01-05 09:22:34",
              In_Result: "OK",
              In_Outer_Width1: 223.916,
              In_Outer_Width2: 224.023,
              In_Internal_Width: 216.158,
              In_Thickness: 4.455,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 10:21:11",
              Tp_Result: "OK",
              Tp_JIG: 52.2,
              Tp_JIG_MIN: 51,
              Tp_JIG_MAX: 53.8,
              Tp_WJIG: 47,
              Tp_WJIG_MIN: 39.4,
              Tp_WJIG_MAX: 49.5,
              Tp_Vibration: 891,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 214,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0916",
              In_InsertTime: "2023-01-05 08:55:37",
              In_Result: "OK",
              In_Outer_Width1: 224.159,
              In_Outer_Width2: 224.028,
              In_Internal_Width: 216.146,
              In_Thickness: 4.444,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 09:40:59",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.6,
              Tp_WJIG: 35.8,
              Tp_WJIG_MIN: 33.6,
              Tp_WJIG_MAX: 37.8,
              Tp_Vibration: 792,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 296,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0930",
              In_InsertTime: "2023-01-05 09:21:59",
              In_Result: "OK",
              In_Outer_Width1: 224.089,
              In_Outer_Width2: 224.164,
              In_Internal_Width: 216.194,
              In_Thickness: 4.451,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 09:47:25",
              Tp_Result: "OK",
              Tp_JIG: 35.6,
              Tp_JIG_MIN: 34.2,
              Tp_JIG_MAX: 38.3,
              Tp_WJIG: 36.1,
              Tp_WJIG_MIN: 32.4,
              Tp_WJIG_MAX: 38.7,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 171,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0943",
              In_InsertTime: "2023-01-05 08:42:42",
              In_Result: "OK",
              In_Outer_Width1: 224.215,
              In_Outer_Width2: 224.178,
              In_Internal_Width: 216.25,
              In_Thickness: 4.452,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 09:09:00",
              Tp_Result: "OK",
              Tp_JIG: 32.8,
              Tp_JIG_MIN: 31.6,
              Tp_JIG_MAX: 35.6,
              Tp_WJIG: 37.6,
              Tp_WJIG_MIN: 32.7,
              Tp_WJIG_MAX: 39.4,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 295,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0945",
              In_InsertTime: "2023-01-05 09:21:50",
              In_Result: "OK",
              In_Outer_Width1: 223.776,
              In_Outer_Width2: 223.997,
              In_Internal_Width: 216.118,
              In_Thickness: 4.439,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 10:24:18",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 42.3,
              Tp_WJIG_MIN: 34.8,
              Tp_WJIG_MAX: 45.1,
              Tp_Vibration: 858,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 207,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0953",
              In_InsertTime: "2023-01-05 08:54:05",
              In_Result: "OK",
              In_Outer_Width1: 223.799,
              In_Outer_Width2: 223.845,
              In_Internal_Width: 216.177,
              In_Thickness: 4.44,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 09:20:22",
              Tp_Result: "OK",
              Tp_JIG: 32.9,
              Tp_JIG_MIN: 31.8,
              Tp_JIG_MAX: 35.3,
              Tp_WJIG: 39.5,
              Tp_WJIG_MIN: 36,
              Tp_WJIG_MAX: 42.1,
              Tp_Vibration: 894,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 300,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0955",
              In_InsertTime: "2023-01-05 09:23:14",
              In_Result: "OK",
              In_Outer_Width1: 223.951,
              In_Outer_Width2: 224.011,
              In_Internal_Width: 216.228,
              In_Thickness: 4.421,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 09:49:53",
              Tp_Result: "OK",
              Tp_JIG: 12.7,
              Tp_JIG_MIN: 11.1,
              Tp_JIG_MAX: 13.8,
              Tp_WJIG: 52.7,
              Tp_WJIG_MIN: 44.6,
              Tp_WJIG_MAX: 55,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 294,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0966",
              In_InsertTime: "2023-01-05 09:21:37",
              In_Result: "OK",
              In_Outer_Width1: 223.998,
              In_Outer_Width2: 223.984,
              In_Internal_Width: 216.15,
              In_Thickness: 4.439,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 09:38:35",
              Tp_Result: "OK",
              Tp_JIG: 34.6,
              Tp_JIG_MIN: 32.8,
              Tp_JIG_MAX: 34.9,
              Tp_WJIG: 20.8,
              Tp_WJIG_MIN: 16.8,
              Tp_WJIG_MAX: 21.8,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 256,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0967",
              In_InsertTime: "2023-01-05 09:09:29",
              In_Result: "OK",
              In_Outer_Width1: 224.058,
              In_Outer_Width2: 224.023,
              In_Internal_Width: 216.147,
              In_Thickness: 4.428,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 09:36:03",
              Tp_Result: "OK",
              Tp_JIG: 37,
              Tp_JIG_MIN: 35.3,
              Tp_JIG_MAX: 39.4,
              Tp_WJIG: 30.9,
              Tp_WJIG_MIN: 24.1,
              Tp_WJIG_MAX: 31.6,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 208,
              INDATE: "20230105",
              ID_No: "21100863A 2CM0971",
              In_InsertTime: "2023-01-05 08:54:14",
              In_Result: "OK",
              In_Outer_Width1: 224.3,
              In_Outer_Width2: 223.864,
              In_Internal_Width: 216.272,
              In_Thickness: 4.46,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 09:12:49",
              Tp_Result: "OK",
              Tp_JIG: 35.6,
              Tp_JIG_MIN: 34.2,
              Tp_JIG_MAX: 37.5,
              Tp_WJIG: 31.2,
              Tp_WJIG_MIN: 28.6,
              Tp_WJIG_MAX: 33.3,
              Tp_Vibration: 777,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 144,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1012",
              In_InsertTime: "2023-01-05 08:33:22",
              In_Result: "OK",
              In_Outer_Width1: 224.126,
              In_Outer_Width2: 223.987,
              In_Internal_Width: 216.166,
              In_Thickness: 4.485,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 08:59:41",
              Tp_Result: "OK",
              Tp_JIG: 26.3,
              Tp_JIG_MIN: 24.7,
              Tp_JIG_MAX: 29.4,
              Tp_WJIG: 30.6,
              Tp_WJIG_MIN: 25.7,
              Tp_WJIG_MAX: 31.5,
              Tp_Vibration: 792,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 252,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1024",
              In_InsertTime: "2023-01-05 09:07:30",
              In_Result: "OK",
              In_Outer_Width1: 224.046,
              In_Outer_Width2: 224.06,
              In_Internal_Width: 216.123,
              In_Thickness: 4.468,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 10:06:27",
              Tp_Result: "OK",
              Tp_JIG: 31.5,
              Tp_JIG_MIN: 30,
              Tp_JIG_MAX: 33,
              Tp_WJIG: 41.2,
              Tp_WJIG_MIN: 35.1,
              Tp_WJIG_MAX: 42.9,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 287,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1028",
              In_InsertTime: "2023-01-05 09:18:43",
              In_Result: "OK",
              In_Outer_Width1: 224.082,
              In_Outer_Width2: 223.959,
              In_Internal_Width: 216.184,
              In_Thickness: 4.476,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 10:17:34",
              Tp_Result: "OK",
              Tp_JIG: 31.5,
              Tp_JIG_MIN: 30.1,
              Tp_JIG_MAX: 33.4,
              Tp_WJIG: 42.6,
              Tp_WJIG_MIN: 37.2,
              Tp_WJIG_MAX: 44.7,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 255,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1047",
              In_InsertTime: "2023-01-05 09:08:59",
              In_Result: "OK",
              In_Outer_Width1: 223.791,
              In_Outer_Width2: 223.865,
              In_Internal_Width: 216.138,
              In_Thickness: 4.474,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 10:11:57",
              Tp_Result: "OK",
              Tp_JIG: 25,
              Tp_JIG_MIN: 23.5,
              Tp_JIG_MAX: 26.4,
              Tp_WJIG: 37.3,
              Tp_WJIG_MIN: 31.4,
              Tp_WJIG_MAX: 38.8,
              Tp_Vibration: 871,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 209,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1051",
              In_InsertTime: "2023-01-05 08:54:36",
              In_Result: "OK",
              In_Outer_Width1: 224.066,
              In_Outer_Width2: 223.808,
              In_Internal_Width: 216.172,
              In_Thickness: 4.456,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 09:40:00",
              Tp_Result: "OK",
              Tp_JIG: 31.6,
              Tp_JIG_MIN: 30.6,
              Tp_JIG_MAX: 33.7,
              Tp_WJIG: 40.6,
              Tp_WJIG_MIN: 36.6,
              Tp_WJIG_MAX: 43.4,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 286,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1056",
              In_InsertTime: "2023-01-05 09:18:35",
              In_Result: "OK",
              In_Outer_Width1: 223.812,
              In_Outer_Width2: 223.953,
              In_Internal_Width: 216.141,
              In_Thickness: 4.466,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 10:16:41",
              Tp_Result: "OK",
              Tp_JIG: 27.8,
              Tp_JIG_MIN: 26.6,
              Tp_JIG_MAX: 29.8,
              Tp_WJIG: 38.6,
              Tp_WJIG_MIN: 33.9,
              Tp_WJIG_MAX: 41.9,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 257,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1065",
              In_InsertTime: "2023-01-05 09:09:46",
              In_Result: "OK",
              In_Outer_Width1: 223.814,
              In_Outer_Width2: 223.993,
              In_Internal_Width: 216.23,
              In_Thickness: 4.451,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 09:26:30",
              Tp_Result: "OK",
              Tp_JIG: 21,
              Tp_JIG_MIN: 19.6,
              Tp_JIG_MAX: 23.6,
              Tp_WJIG: 46.7,
              Tp_WJIG_MIN: 41.3,
              Tp_WJIG_MAX: 48.4,
              Tp_Vibration: 891,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 290,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1067",
              In_InsertTime: "2023-01-05 09:19:35",
              In_Result: "OK",
              In_Outer_Width1: 223.981,
              In_Outer_Width2: 223.984,
              In_Internal_Width: 216.193,
              In_Thickness: 4.462,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 10:22:11",
              Tp_Result: "OK",
              Tp_JIG: 37.1,
              Tp_JIG_MIN: 35.8,
              Tp_JIG_MAX: 39.1,
              Tp_WJIG: 44.9,
              Tp_WJIG_MIN: 38.4,
              Tp_WJIG_MAX: 47,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 230,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1069",
              In_InsertTime: "2023-01-05 09:00:43",
              In_Result: "OK",
              In_Outer_Width1: 224.252,
              In_Outer_Width2: 223.897,
              In_Internal_Width: 216.264,
              In_Thickness: 4.465,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 09:27:04",
              Tp_Result: "OK",
              Tp_JIG: 12.2,
              Tp_JIG_MIN: 10.5,
              Tp_JIG_MAX: 13.9,
              Tp_WJIG: 52.6,
              Tp_WJIG_MIN: 45.1,
              Tp_WJIG_MAX: 55,
              Tp_Vibration: 875,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 288,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1076",
              In_InsertTime: "2023-01-05 09:18:52",
              In_Result: "OK",
              In_Outer_Width1: 223.961,
              In_Outer_Width2: 223.912,
              In_Internal_Width: 216.153,
              In_Thickness: 4.465,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 10:04:25",
              Tp_Result: "OK",
              Tp_JIG: 32.4,
              Tp_JIG_MIN: 31.9,
              Tp_JIG_MAX: 35.8,
              Tp_WJIG: 40.3,
              Tp_WJIG_MIN: 32.1,
              Tp_WJIG_MAX: 43.1,
              Tp_Vibration: 900,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 280,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1082",
              In_InsertTime: "2023-01-05 09:16:59",
              In_Result: "OK",
              In_Outer_Width1: 223.93,
              In_Outer_Width2: 223.875,
              In_Internal_Width: 216.159,
              In_Thickness: 4.46,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 09:35:05",
              Tp_Result: "OK",
              Tp_JIG: 30.7,
              Tp_JIG_MIN: 29.7,
              Tp_JIG_MAX: 36.4,
              Tp_WJIG: 30.7,
              Tp_WJIG_MIN: 28,
              Tp_WJIG_MAX: 33,
              Tp_Vibration: 776,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 248,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1083",
              In_InsertTime: "2023-01-05 09:06:29",
              In_Result: "OK",
              In_Outer_Width1: 223.803,
              In_Outer_Width2: 223.764,
              In_Internal_Width: 216.15,
              In_Thickness: 4.449,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 09:24:53",
              Tp_Result: "OK",
              Tp_JIG: 14.9,
              Tp_JIG_MIN: 13.4,
              Tp_JIG_MAX: 16.7,
              Tp_WJIG: 33.5,
              Tp_WJIG_MIN: 30.5,
              Tp_WJIG_MAX: 34.5,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 285,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1086",
              In_InsertTime: "2023-01-05 09:18:23",
              In_Result: "OK",
              In_Outer_Width1: 223.889,
              In_Outer_Width2: 224.033,
              In_Internal_Width: 216.216,
              In_Thickness: 4.45,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 10:21:05",
              Tp_Result: "OK",
              Tp_JIG: 33,
              Tp_JIG_MIN: 30.8,
              Tp_JIG_MAX: 33.5,
              Tp_WJIG: 56.4,
              Tp_WJIG_MIN: 48.6,
              Tp_WJIG_MAX: 56.9,
              Tp_Vibration: 876,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 250,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1088",
              In_InsertTime: "2023-01-05 09:07:00",
              In_Result: "OK",
              In_Outer_Width1: 223.922,
              In_Outer_Width2: 224.073,
              In_Internal_Width: 216.217,
              In_Thickness: 4.438,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 10:05:19",
              Tp_Result: "OK",
              Tp_JIG: 29.4,
              Tp_JIG_MIN: 27.9,
              Tp_JIG_MAX: 30.8,
              Tp_WJIG: 49.7,
              Tp_WJIG_MIN: 38.5,
              Tp_WJIG_MAX: 49.7,
              Tp_Vibration: 882,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 275,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1236",
              In_InsertTime: "2023-01-05 09:15:28",
              In_Result: "OK",
              In_Outer_Width1: 224.153,
              In_Outer_Width2: 223.853,
              In_Internal_Width: 216.14,
              In_Thickness: 4.446,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 09:32:03",
              Tp_Result: "OK",
              Tp_JIG: 21.9,
              Tp_JIG_MIN: 20.6,
              Tp_JIG_MAX: 24.1,
              Tp_WJIG: 33.2,
              Tp_WJIG_MIN: 30.4,
              Tp_WJIG_MAX: 36.2,
              Tp_Vibration: 895,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 276,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1241",
              In_InsertTime: "2023-01-05 09:16:02",
              In_Result: "OK",
              In_Outer_Width1: 223.801,
              In_Outer_Width2: 223.828,
              In_Internal_Width: 216.106,
              In_Thickness: 4.458,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 09:33:00",
              Tp_Result: "OK",
              Tp_JIG: 34.5,
              Tp_JIG_MIN: 32.7,
              Tp_JIG_MAX: 34.9,
              Tp_WJIG: 24.8,
              Tp_WJIG_MIN: 22.5,
              Tp_WJIG_MAX: 27.9,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 278,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1247",
              In_InsertTime: "2023-01-05 09:16:21",
              In_Result: "OK",
              In_Outer_Width1: 223.788,
              In_Outer_Width2: 223.755,
              In_Internal_Width: 216.099,
              In_Thickness: 4.459,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 10:18:38",
              Tp_Result: "OK",
              Tp_JIG: 35.3,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 41.5,
              Tp_WJIG_MIN: 34.2,
              Tp_WJIG_MAX: 44.6,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 309,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1251",
              In_InsertTime: "2023-01-05 09:25:39",
              In_Result: "OK",
              In_Outer_Width1: 223.847,
              In_Outer_Width2: 223.829,
              In_Internal_Width: 216.156,
              In_Thickness: 4.448,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 09:43:14",
              Tp_Result: "OK",
              Tp_JIG: 21.5,
              Tp_JIG_MIN: 20.3,
              Tp_JIG_MAX: 24,
              Tp_WJIG: 35.8,
              Tp_WJIG_MIN: 31.4,
              Tp_WJIG_MAX: 38.1,
              Tp_Vibration: 887,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 293,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1253",
              In_InsertTime: "2023-01-05 09:21:09",
              In_Result: "OK",
              In_Outer_Width1: 223.847,
              In_Outer_Width2: 223.853,
              In_Internal_Width: 216.09,
              In_Thickness: 4.452,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 10:23:17",
              Tp_Result: "OK",
              Tp_JIG: 24.9,
              Tp_JIG_MIN: 23.4,
              Tp_JIG_MAX: 26.4,
              Tp_WJIG: 30.5,
              Tp_WJIG_MIN: 23.5,
              Tp_WJIG_MAX: 31.5,
              Tp_Vibration: 850,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 311,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1255",
              In_InsertTime: "2023-01-05 09:27:39",
              In_Result: "OK",
              In_Outer_Width1: 224.032,
              In_Outer_Width2: 223.885,
              In_Internal_Width: 216.137,
              In_Thickness: 4.447,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 10:15:50",
              Tp_Result: "OK",
              Tp_JIG: 32.7,
              Tp_JIG_MIN: 32.6,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 26.2,
              Tp_WJIG_MIN: 19.6,
              Tp_WJIG_MAX: 28.1,
              Tp_Vibration: 900,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 224,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1264",
              In_InsertTime: "2023-01-05 08:59:31",
              In_Result: "OK",
              In_Outer_Width1: 224.269,
              In_Outer_Width2: 223.767,
              In_Internal_Width: 216.185,
              In_Thickness: 4.456,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 09:34:26",
              Tp_Result: "OK",
              Tp_JIG: 31.2,
              Tp_JIG_MIN: 28.8,
              Tp_JIG_MAX: 34.8,
              Tp_WJIG: 27.2,
              Tp_WJIG_MIN: 21.2,
              Tp_WJIG_MAX: 28.2,
              Tp_Vibration: 902,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 226,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1269",
              In_InsertTime: "2023-01-05 08:59:58",
              In_Result: "OK",
              In_Outer_Width1: 223.774,
              In_Outer_Width2: 223.915,
              In_Internal_Width: 216.099,
              In_Thickness: 4.454,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 09:18:22",
              Tp_Result: "OK",
              Tp_JIG: 35.6,
              Tp_JIG_MIN: 34.2,
              Tp_JIG_MAX: 37.3,
              Tp_WJIG: 22,
              Tp_WJIG_MIN: 19.7,
              Tp_WJIG_MAX: 23.9,
              Tp_Vibration: 789,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 277,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1271",
              In_InsertTime: "2023-01-05 09:16:12",
              In_Result: "OK",
              In_Outer_Width1: 224.006,
              In_Outer_Width2: 223.985,
              In_Internal_Width: 216.128,
              In_Thickness: 4.448,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 09:41:44",
              Tp_Result: "OK",
              Tp_JIG: 35.4,
              Tp_JIG_MIN: 33.7,
              Tp_JIG_MAX: 38.1,
              Tp_WJIG: 37.5,
              Tp_WJIG_MIN: 30.2,
              Tp_WJIG_MAX: 39.4,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 302,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1274",
              In_InsertTime: "2023-01-05 09:23:38",
              In_Result: "OK",
              In_Outer_Width1: 223.956,
              In_Outer_Width2: 223.852,
              In_Internal_Width: 216.123,
              In_Thickness: 4.444,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 09:41:33",
              Tp_Result: "OK",
              Tp_JIG: 15.2,
              Tp_JIG_MIN: 13.6,
              Tp_JIG_MAX: 17,
              Tp_WJIG: 33.4,
              Tp_WJIG_MIN: 30.7,
              Tp_WJIG_MAX: 35.5,
              Tp_Vibration: 789,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 299,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1281",
              In_InsertTime: "2023-01-05 09:23:04",
              In_Result: "OK",
              In_Outer_Width1: 223.801,
              In_Outer_Width2: 223.875,
              In_Internal_Width: 216.1,
              In_Thickness: 4.459,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 10:05:24",
              Tp_Result: "OK",
              Tp_JIG: 35.8,
              Tp_JIG_MIN: 30.8,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 30,
              Tp_WJIG_MIN: 24.3,
              Tp_WJIG_MAX: 30.6,
              Tp_Vibration: 896,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 291,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1292",
              In_InsertTime: "2023-01-05 09:20:06",
              In_Result: "OK",
              In_Outer_Width1: 223.902,
              In_Outer_Width2: 223.787,
              In_Internal_Width: 216.111,
              In_Thickness: 4.444,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 09:37:38",
              Tp_Result: "OK",
              Tp_JIG: 21.5,
              Tp_JIG_MIN: 20.3,
              Tp_JIG_MAX: 23.8,
              Tp_WJIG: 38,
              Tp_WJIG_MIN: 34,
              Tp_WJIG_MAX: 40.5,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 292,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1309",
              In_InsertTime: "2023-01-05 09:20:47",
              In_Result: "OK",
              In_Outer_Width1: 223.809,
              In_Outer_Width2: 224.125,
              In_Internal_Width: 216.127,
              In_Thickness: 4.458,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 10:18:33",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.2,
              Tp_WJIG: 29.6,
              Tp_WJIG_MIN: 27.1,
              Tp_WJIG_MAX: 31.8,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 283,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1310",
              In_InsertTime: "2023-01-05 09:17:37",
              In_Result: "OK",
              In_Outer_Width1: 223.922,
              In_Outer_Width2: 223.912,
              In_Internal_Width: 216.108,
              In_Thickness: 4.453,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 09:36:00",
              Tp_Result: "OK",
              Tp_JIG: 15.2,
              Tp_JIG_MIN: 14,
              Tp_JIG_MAX: 17,
              Tp_WJIG: 28.3,
              Tp_WJIG_MIN: 26.1,
              Tp_WJIG_MAX: 30.5,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 304,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1319",
              In_InsertTime: "2023-01-05 09:24:09",
              In_Result: "OK",
              In_Outer_Width1: 224.111,
              In_Outer_Width2: 223.876,
              In_Internal_Width: 216.17,
              In_Thickness: 4.451,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 10:04:19",
              Tp_Result: "OK",
              Tp_JIG: 12.5,
              Tp_JIG_MIN: 10.9,
              Tp_JIG_MAX: 13.7,
              Tp_WJIG: 32.6,
              Tp_WJIG_MIN: 26.5,
              Tp_WJIG_MAX: 34.7,
              Tp_Vibration: 878,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 216,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1349",
              In_InsertTime: "2023-01-05 08:56:06",
              In_Result: "OK",
              In_Outer_Width1: 224.146,
              In_Outer_Width2: 223.845,
              In_Internal_Width: 216.191,
              In_Thickness: 4.451,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 09:22:26",
              Tp_Result: "OK",
              Tp_JIG: 26.8,
              Tp_JIG_MIN: 25,
              Tp_JIG_MAX: 29.8,
              Tp_WJIG: 27.9,
              Tp_WJIG_MIN: 23.8,
              Tp_WJIG_MAX: 29.3,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 229,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1353",
              In_InsertTime: "2023-01-05 09:00:33",
              In_Result: "OK",
              In_Outer_Width1: 224.094,
              In_Outer_Width2: 223.924,
              In_Internal_Width: 216.191,
              In_Thickness: 4.45,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 09:19:20",
              Tp_Result: "OK",
              Tp_JIG: 14.9,
              Tp_JIG_MIN: 13.7,
              Tp_JIG_MAX: 16.8,
              Tp_WJIG: 28,
              Tp_WJIG_MIN: 25.9,
              Tp_WJIG_MAX: 30.4,
              Tp_Vibration: 892,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 221,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1356",
              In_InsertTime: "2023-01-05 08:58:24",
              In_Result: "OK",
              In_Outer_Width1: 223.982,
              In_Outer_Width2: 224.18,
              In_Internal_Width: 216.14,
              In_Thickness: 4.447,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 09:15:16",
              Tp_Result: "OK",
              Tp_JIG: 21.8,
              Tp_JIG_MIN: 20.8,
              Tp_JIG_MAX: 24.1,
              Tp_WJIG: 22.9,
              Tp_WJIG_MIN: 19.9,
              Tp_WJIG_MAX: 24.5,
              Tp_Vibration: 876,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 310,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1357",
              In_InsertTime: "2023-01-05 09:27:28",
              In_Result: "OK",
              In_Outer_Width1: 224.046,
              In_Outer_Width2: 223.891,
              In_Internal_Width: 216.145,
              In_Thickness: 4.451,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 10:24:12",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9,
              Tp_WJIG: 32.4,
              Tp_WJIG_MIN: 30.5,
              Tp_WJIG_MAX: 35.3,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 225,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1368",
              In_InsertTime: "2023-01-05 08:59:40",
              In_Result: "OK",
              In_Outer_Width1: 223.935,
              In_Outer_Width2: 224.008,
              In_Internal_Width: 216.126,
              In_Thickness: 4.454,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 09:27:32",
              Tp_Result: "OK",
              Tp_JIG: 32.7,
              Tp_JIG_MIN: 31.5,
              Tp_JIG_MAX: 34.4,
              Tp_WJIG: 31.6,
              Tp_WJIG_MIN: 27.5,
              Tp_WJIG_MAX: 33.3,
              Tp_Vibration: 888,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 218,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1369",
              In_InsertTime: "2023-01-05 08:57:17",
              In_Result: "OK",
              In_Outer_Width1: 224.01,
              In_Outer_Width2: 224.207,
              In_Internal_Width: 216.152,
              In_Thickness: 4.453,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 09:43:37",
              Tp_Result: "OK",
              Tp_JIG: 53.1,
              Tp_JIG_MIN: 51.9,
              Tp_JIG_MAX: 54,
              Tp_WJIG: 48.1,
              Tp_WJIG_MIN: 40.2,
              Tp_WJIG_MAX: 50.9,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 306,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1372",
              In_InsertTime: "2023-01-05 09:24:29",
              In_Result: "OK",
              In_Outer_Width1: 223.945,
              In_Outer_Width2: 223.994,
              In_Internal_Width: 216.214,
              In_Thickness: 4.47,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 10:11:10",
              Tp_Result: "OK",
              Tp_JIG: 33.6,
              Tp_JIG_MIN: 29.9,
              Tp_JIG_MAX: 34.4,
              Tp_WJIG: 46.1,
              Tp_WJIG_MIN: 38.5,
              Tp_WJIG_MAX: 47.1,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 228,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1373",
              In_InsertTime: "2023-01-05 09:00:25",
              In_Result: "OK",
              In_Outer_Width1: 223.837,
              In_Outer_Width2: 223.971,
              In_Internal_Width: 216.118,
              In_Thickness: 4.447,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 09:50:22",
              Tp_Result: "OK",
              Tp_JIG: 37.1,
              Tp_JIG_MIN: 35.7,
              Tp_JIG_MAX: 39.1,
              Tp_WJIG: 43.4,
              Tp_WJIG_MIN: 37.5,
              Tp_WJIG_MAX: 45.7,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 273,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1374",
              In_InsertTime: "2023-01-05 09:14:32",
              In_Result: "OK",
              In_Outer_Width1: 223.943,
              In_Outer_Width2: 223.912,
              In_Internal_Width: 216.15,
              In_Thickness: 4.463,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 10:17:37",
              Tp_Result: "OK",
              Tp_JIG: 24.9,
              Tp_JIG_MIN: 23.2,
              Tp_JIG_MAX: 26.3,
              Tp_WJIG: 40.4,
              Tp_WJIG_MIN: 33.5,
              Tp_WJIG_MAX: 42.4,
              Tp_Vibration: 858,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 274,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1375",
              In_InsertTime: "2023-01-05 09:15:08",
              In_Result: "OK",
              In_Outer_Width1: 223.929,
              In_Outer_Width2: 223.994,
              In_Internal_Width: 216.149,
              In_Thickness: 4.461,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 10:12:54",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.3,
              Tp_WJIG: 27.1,
              Tp_WJIG_MIN: 24.7,
              Tp_WJIG_MAX: 28.8,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 227,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1381",
              In_InsertTime: "2023-01-05 09:00:12",
              In_Result: "OK",
              In_Outer_Width1: 223.909,
              In_Outer_Width2: 223.915,
              In_Internal_Width: 216.135,
              In_Thickness: 4.468,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 09:45:39",
              Tp_Result: "OK",
              Tp_JIG: 31.5,
              Tp_JIG_MIN: 30.1,
              Tp_JIG_MAX: 33.6,
              Tp_WJIG: 55.6,
              Tp_WJIG_MIN: 48.9,
              Tp_WJIG_MAX: 57,
              Tp_Vibration: 872,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 210,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1382",
              In_InsertTime: "2023-01-05 08:55:21",
              In_Result: "OK",
              In_Outer_Width1: 223.896,
              In_Outer_Width2: 223.953,
              In_Internal_Width: 216.116,
              In_Thickness: 4.464,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 09:44:41",
              Tp_Result: "OK",
              Tp_JIG: 37.5,
              Tp_JIG_MIN: 36.2,
              Tp_JIG_MAX: 39.2,
              Tp_WJIG: 38.8,
              Tp_WJIG_MIN: 31.8,
              Tp_WJIG_MAX: 39.9,
              Tp_Vibration: 899,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 211,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1384",
              In_InsertTime: "2023-01-05 08:55:24",
              In_Result: "OK",
              In_Outer_Width1: 223.833,
              In_Outer_Width2: 223.886,
              In_Internal_Width: 216.108,
              In_Thickness: 4.46,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 09:13:46",
              Tp_Result: "OK",
              Tp_JIG: 15.1,
              Tp_JIG_MIN: 13.8,
              Tp_JIG_MAX: 16.9,
              Tp_WJIG: 30.8,
              Tp_WJIG_MIN: 26.6,
              Tp_WJIG_MAX: 30.9,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 212,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1391",
              In_InsertTime: "2023-01-05 08:55:27",
              In_Result: "OK",
              In_Outer_Width1: 223.782,
              In_Outer_Width2: 223.872,
              In_Internal_Width: 216.111,
              In_Thickness: 4.457,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 09:21:24",
              Tp_Result: "OK",
              Tp_JIG: 12.2,
              Tp_JIG_MIN: 11,
              Tp_JIG_MAX: 13.9,
              Tp_WJIG: 33.3,
              Tp_WJIG_MIN: 29,
              Tp_WJIG_MAX: 37,
              Tp_Vibration: 878,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 107,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1410",
              In_InsertTime: "2023-01-05 08:22:01",
              In_Result: "OK",
              In_Outer_Width1: 223.863,
              In_Outer_Width2: 223.948,
              In_Internal_Width: 216.165,
              In_Thickness: 4.445,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 09:07:32",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.6,
              Tp_WJIG: 55.8,
              Tp_WJIG_MIN: 52.3,
              Tp_WJIG_MAX: 57.5,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 101,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1414",
              In_InsertTime: "2023-01-05 08:20:44",
              In_Result: "OK",
              In_Outer_Width1: 223.89,
              In_Outer_Width2: 223.939,
              In_Internal_Width: 216.174,
              In_Thickness: 4.447,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 09:06:02",
              Tp_Result: "OK",
              Tp_JIG: 31.8,
              Tp_JIG_MIN: 30.2,
              Tp_JIG_MAX: 33.8,
              Tp_WJIG: 49.6,
              Tp_WJIG_MIN: 44.4,
              Tp_WJIG_MAX: 52.2,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 41,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1421",
              In_InsertTime: "2023-01-05 08:02:10",
              In_Result: "OK",
              In_Outer_Width1: 223.817,
              In_Outer_Width2: 223.893,
              In_Internal_Width: 216.183,
              In_Thickness: 4.444,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:19:22",
              Tp_Result: "OK",
              Tp_JIG: 21.3,
              Tp_JIG_MIN: 20,
              Tp_JIG_MAX: 23.8,
              Tp_WJIG: 51.3,
              Tp_WJIG_MIN: 45.7,
              Tp_WJIG_MAX: 53.1,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 42,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1483",
              In_InsertTime: "2023-01-05 08:02:22",
              In_Result: "OK",
              In_Outer_Width1: 223.994,
              In_Outer_Width2: 223.971,
              In_Internal_Width: 216.148,
              In_Thickness: 4.449,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 08:29:11",
              Tp_Result: "OK",
              Tp_JIG: 32.6,
              Tp_JIG_MIN: 31.2,
              Tp_JIG_MAX: 35.3,
              Tp_WJIG: 40,
              Tp_WJIG_MIN: 34.6,
              Tp_WJIG_MAX: 41.8,
              Tp_Vibration: 899,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 182,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1486",
              In_InsertTime: "2023-01-05 08:46:04",
              In_Result: "OK",
              In_Outer_Width1: 223.994,
              In_Outer_Width2: 224.02,
              In_Internal_Width: 216.167,
              In_Thickness: 4.451,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 09:32:18",
              Tp_Result: "OK",
              Tp_JIG: 53,
              Tp_JIG_MIN: 51.6,
              Tp_JIG_MAX: 54.2,
              Tp_WJIG: 74.1,
              Tp_WJIG_MIN: 63.6,
              Tp_WJIG_MAX: 75.8,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 55,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1491",
              In_InsertTime: "2023-01-05 08:06:14",
              In_Result: "OK",
              In_Outer_Width1: 223.956,
              In_Outer_Width2: 224.099,
              In_Internal_Width: 216.114,
              In_Thickness: 4.451,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 08:23:44",
              Tp_Result: "OK",
              Tp_JIG: 15,
              Tp_JIG_MIN: 13.7,
              Tp_JIG_MAX: 17.3,
              Tp_WJIG: 38.8,
              Tp_WJIG_MIN: 35.3,
              Tp_WJIG_MAX: 40.3,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 54,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1492",
              In_InsertTime: "2023-01-05 08:05:00",
              In_Result: "OK",
              In_Outer_Width1: 223.816,
              In_Outer_Width2: 224.002,
              In_Internal_Width: 216.099,
              In_Thickness: 4.446,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 08:31:15",
              Tp_Result: "OK",
              Tp_JIG: 26.8,
              Tp_JIG_MIN: 25.3,
              Tp_JIG_MAX: 29.6,
              Tp_WJIG: 31.1,
              Tp_WJIG_MIN: 26.8,
              Tp_WJIG_MAX: 32.5,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 50,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1495",
              In_InsertTime: "2023-01-05 08:03:56",
              In_Result: "OK",
              In_Outer_Width1: 223.906,
              In_Outer_Width2: 223.922,
              In_Internal_Width: 216.126,
              In_Thickness: 4.457,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 08:30:13",
              Tp_Result: "OK",
              Tp_JIG: 12.5,
              Tp_JIG_MIN: 11.2,
              Tp_JIG_MAX: 14,
              Tp_WJIG: 48.1,
              Tp_WJIG_MIN: 38.9,
              Tp_WJIG_MAX: 50,
              Tp_Vibration: 899,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 37,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1501",
              In_InsertTime: "2023-01-05 08:01:19",
              In_Result: "OK",
              In_Outer_Width1: 223.792,
              In_Outer_Width2: 223.843,
              In_Internal_Width: 216.127,
              In_Thickness: 4.458,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 08:18:11",
              Tp_Result: "OK",
              Tp_JIG: 15.6,
              Tp_JIG_MIN: 14,
              Tp_JIG_MAX: 18,
              Tp_WJIG: 50.7,
              Tp_WJIG_MIN: 47,
              Tp_WJIG_MAX: 52.6,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 49,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1502",
              In_InsertTime: "2023-01-05 08:03:46",
              In_Result: "OK",
              In_Outer_Width1: 223.881,
              In_Outer_Width2: 223.863,
              In_Internal_Width: 216.155,
              In_Thickness: 4.444,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 08:22:47",
              Tp_Result: "OK",
              Tp_JIG: 34.8,
              Tp_JIG_MIN: 33.5,
              Tp_JIG_MAX: 37.2,
              Tp_WJIG: 40.4,
              Tp_WJIG_MIN: 36.4,
              Tp_WJIG_MAX: 42.6,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 79,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1506",
              In_InsertTime: "2023-01-05 08:13:57",
              In_Result: "OK",
              In_Outer_Width1: 223.957,
              In_Outer_Width2: 223.931,
              In_Internal_Width: 216.104,
              In_Thickness: 4.445,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 08:48:19",
              Tp_Result: "OK",
              Tp_JIG: 33.6,
              Tp_JIG_MIN: 27,
              Tp_JIG_MAX: 35.2,
              Tp_WJIG: 36.7,
              Tp_WJIG_MIN: 30.3,
              Tp_WJIG_MAX: 38.7,
              Tp_Vibration: 899,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 44,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1510",
              In_InsertTime: "2023-01-05 08:02:43",
              In_Result: "OK",
              In_Outer_Width1: 223.823,
              In_Outer_Width2: 223.904,
              In_Internal_Width: 216.136,
              In_Thickness: 4.448,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:20:19",
              Tp_Result: "OK",
              Tp_JIG: 34.7,
              Tp_JIG_MIN: 32.7,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 39.9,
              Tp_WJIG_MIN: 36.5,
              Tp_WJIG_MAX: 42.4,
              Tp_Vibration: 782,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 80,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1512",
              In_InsertTime: "2023-01-05 08:14:06",
              In_Result: "OK",
              In_Outer_Width1: 223.758,
              In_Outer_Width2: 223.79,
              In_Internal_Width: 216.129,
              In_Thickness: 4.444,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:31:29",
              Tp_Result: "OK",
              Tp_JIG: 34.7,
              Tp_JIG_MIN: 32.7,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 33,
              Tp_WJIG_MIN: 29.8,
              Tp_WJIG_MAX: 35.6,
              Tp_Vibration: 788,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 76,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1513",
              In_InsertTime: "2023-01-05 08:13:08",
              In_Result: "OK",
              In_Outer_Width1: 223.693,
              In_Outer_Width2: 223.829,
              In_Internal_Width: 216.091,
              In_Thickness: 4.443,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 08:37:58",
              Tp_Result: "OK",
              Tp_JIG: 36,
              Tp_JIG_MIN: 34.2,
              Tp_JIG_MAX: 38.5,
              Tp_WJIG: 30.7,
              Tp_WJIG_MIN: 26.5,
              Tp_WJIG_MAX: 33,
              Tp_Vibration: 794,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 43,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1517",
              In_InsertTime: "2023-01-05 08:02:35",
              In_Result: "OK",
              In_Outer_Width1: 223.778,
              In_Outer_Width2: 223.897,
              In_Internal_Width: 216.145,
              In_Thickness: 4.45,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 08:52:33",
              Tp_Result: "OK",
              Tp_JIG: 33.2,
              Tp_JIG_MIN: 30.9,
              Tp_JIG_MAX: 33.8,
              Tp_WJIG: 48.4,
              Tp_WJIG_MIN: 42.2,
              Tp_WJIG_MAX: 50,
              Tp_Vibration: 889,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 68,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1519",
              In_InsertTime: "2023-01-05 08:09:40",
              In_Result: "OK",
              In_Outer_Width1: 223.849,
              In_Outer_Width2: 223.84,
              In_Internal_Width: 216.17,
              In_Thickness: 4.445,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 08:55:21",
              Tp_Result: "OK",
              Tp_JIG: 31.6,
              Tp_JIG_MIN: 29.9,
              Tp_JIG_MAX: 33.9,
              Tp_WJIG: 56.4,
              Tp_WJIG_MIN: 50.9,
              Tp_WJIG_MAX: 58.8,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 87,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1527",
              In_InsertTime: "2023-01-05 08:17:34",
              In_Result: "OK",
              In_Outer_Width1: 223.764,
              In_Outer_Width2: 223.821,
              In_Internal_Width: 216.158,
              In_Thickness: 4.441,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 09:06:18",
              Tp_Result: "OK",
              Tp_JIG: 24.9,
              Tp_JIG_MIN: 23.1,
              Tp_JIG_MAX: 26.6,
              Tp_WJIG: 64.5,
              Tp_WJIG_MIN: 55,
              Tp_WJIG_MAX: 65.6,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 86,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1533",
              In_InsertTime: "2023-01-05 08:17:26",
              In_Result: "OK",
              In_Outer_Width1: 223.784,
              In_Outer_Width2: 223.896,
              In_Internal_Width: 216.186,
              In_Thickness: 4.44,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 08:41:36",
              Tp_Result: "OK",
              Tp_JIG: 12.6,
              Tp_JIG_MIN: 10.8,
              Tp_JIG_MAX: 14,
              Tp_WJIG: 53.5,
              Tp_WJIG_MIN: 45.1,
              Tp_WJIG_MAX: 55.2,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 89,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1534",
              In_InsertTime: "2023-01-05 08:17:52",
              In_Result: "OK",
              In_Outer_Width1: 223.87,
              In_Outer_Width2: 223.929,
              In_Internal_Width: 216.156,
              In_Thickness: 4.44,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 09:01:57",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.7,
              Tp_WJIG: 50.4,
              Tp_WJIG_MIN: 47.5,
              Tp_WJIG_MAX: 52.4,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 78,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1561",
              In_InsertTime: "2023-01-05 08:13:43",
              In_Result: "OK",
              In_Outer_Width1: 223.89,
              In_Outer_Width2: 223.993,
              In_Internal_Width: 216.178,
              In_Thickness: 4.452,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 08:59:29",
              Tp_Result: "OK",
              Tp_JIG: 27.6,
              Tp_JIG_MIN: 26.2,
              Tp_JIG_MAX: 29.6,
              Tp_WJIG: 57,
              Tp_WJIG_MIN: 49.8,
              Tp_WJIG_MAX: 58.7,
              Tp_Vibration: 797,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 114,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1562",
              In_InsertTime: "2023-01-05 08:25:24",
              In_Result: "OK",
              In_Outer_Width1: 223.978,
              In_Outer_Width2: 224.025,
              In_Internal_Width: 216.152,
              In_Thickness: 4.452,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:41:43",
              Tp_Result: "OK",
              Tp_JIG: 21.3,
              Tp_JIG_MIN: 20.3,
              Tp_JIG_MAX: 24,
              Tp_WJIG: 36.6,
              Tp_WJIG_MIN: 32.4,
              Tp_WJIG_MAX: 38.5,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 110,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1569",
              In_InsertTime: "2023-01-05 08:23:48",
              In_Result: "OK",
              In_Outer_Width1: 223.869,
              In_Outer_Width2: 223.888,
              In_Internal_Width: 216.177,
              In_Thickness: 4.458,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 09:09:46",
              Tp_Result: "OK",
              Tp_JIG: 53.3,
              Tp_JIG_MIN: 52,
              Tp_JIG_MAX: 54.3,
              Tp_WJIG: 58.2,
              Tp_WJIG_MIN: 50,
              Tp_WJIG_MAX: 60.6,
              Tp_Vibration: 871,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 66,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1572",
              In_InsertTime: "2023-01-05 08:09:18",
              In_Result: "OK",
              In_Outer_Width1: 223.857,
              In_Outer_Width2: 223.791,
              In_Internal_Width: 216.169,
              In_Thickness: 4.46,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 08:47:11",
              Tp_Result: "OK",
              Tp_JIG: 32.5,
              Tp_JIG_MIN: 32.3,
              Tp_JIG_MAX: 36.2,
              Tp_WJIG: 54.6,
              Tp_WJIG_MIN: 45.3,
              Tp_WJIG_MAX: 56.4,
              Tp_Vibration: 895,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 85,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1574",
              In_InsertTime: "2023-01-05 08:15:09",
              In_Result: "OK",
              In_Outer_Width1: 223.882,
              In_Outer_Width2: 223.88,
              In_Internal_Width: 216.182,
              In_Thickness: 4.46,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 08:33:54",
              Tp_Result: "OK",
              Tp_JIG: 35.3,
              Tp_JIG_MIN: 33.7,
              Tp_JIG_MAX: 37.5,
              Tp_WJIG: 39,
              Tp_WJIG_MIN: 35.2,
              Tp_WJIG_MAX: 41.2,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 83,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1576",
              In_InsertTime: "2023-01-05 08:14:49",
              In_Result: "OK",
              In_Outer_Width1: 224.051,
              In_Outer_Width2: 223.76,
              In_Internal_Width: 216.167,
              In_Thickness: 4.456,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 09:00:23",
              Tp_Result: "OK",
              Tp_JIG: 31.9,
              Tp_JIG_MIN: 30.6,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 40.6,
              Tp_WJIG_MIN: 36.5,
              Tp_WJIG_MAX: 42.9,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 67,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1584",
              In_InsertTime: "2023-01-05 08:09:28",
              In_Result: "OK",
              In_Outer_Width1: 223.772,
              In_Outer_Width2: 223.978,
              In_Internal_Width: 216.093,
              In_Thickness: 4.466,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 08:28:20",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 37.4,
              Tp_WJIG: 32.1,
              Tp_WJIG_MIN: 28.3,
              Tp_WJIG_MAX: 33.9,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 108,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1585",
              In_InsertTime: "2023-01-05 08:22:09",
              In_Result: "OK",
              In_Outer_Width1: 223.87,
              In_Outer_Width2: 223.996,
              In_Internal_Width: 216.165,
              In_Thickness: 4.471,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 09:14:08",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 33.4,
              Tp_JIG_MAX: 36.4,
              Tp_WJIG: 57.2,
              Tp_WJIG_MIN: 46.1,
              Tp_WJIG_MAX: 58.3,
              Tp_Vibration: 877,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 65,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1588",
              In_InsertTime: "2023-01-05 08:09:08",
              In_Result: "OK",
              In_Outer_Width1: 223.958,
              In_Outer_Width2: 223.831,
              In_Internal_Width: 216.178,
              In_Thickness: 4.477,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 08:35:55",
              Tp_Result: "OK",
              Tp_JIG: 12.5,
              Tp_JIG_MIN: 10.9,
              Tp_JIG_MAX: 14,
              Tp_WJIG: 53.5,
              Tp_WJIG_MIN: 42.5,
              Tp_WJIG_MAX: 54.7,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 103,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1589",
              In_InsertTime: "2023-01-05 08:21:06",
              In_Result: "OK",
              In_Outer_Width1: 223.756,
              In_Outer_Width2: 223.8,
              In_Internal_Width: 216.091,
              In_Thickness: 4.466,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 08:39:28",
              Tp_Result: "OK",
              Tp_JIG: 35.4,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 37.5,
              Tp_WJIG: 24.7,
              Tp_WJIG_MIN: 21.5,
              Tp_WJIG_MAX: 25.5,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 105,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1591",
              In_InsertTime: "2023-01-05 08:21:45",
              In_Result: "OK",
              In_Outer_Width1: 223.782,
              In_Outer_Width2: 223.829,
              In_Internal_Width: 216.087,
              In_Thickness: 4.467,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 08:40:25",
              Tp_Result: "OK",
              Tp_JIG: 15.3,
              Tp_JIG_MIN: 13.7,
              Tp_JIG_MAX: 17.3,
              Tp_WJIG: 32,
              Tp_WJIG_MIN: 29.3,
              Tp_WJIG_MAX: 33.7,
              Tp_Vibration: 881,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 117,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1592",
              In_InsertTime: "2023-01-05 08:28:11",
              In_Result: "OK",
              In_Outer_Width1: 223.854,
              In_Outer_Width2: 223.841,
              In_Internal_Width: 216.101,
              In_Thickness: 4.468,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:42:41",
              Tp_Result: "OK",
              Tp_JIG: 34.7,
              Tp_JIG_MIN: 32.8,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 27.3,
              Tp_WJIG_MIN: 24.2,
              Tp_WJIG_MAX: 28.9,
              Tp_Vibration: 853,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 109,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1593",
              In_InsertTime: "2023-01-05 08:23:32",
              In_Result: "OK",
              In_Outer_Width1: 223.942,
              In_Outer_Width2: 223.824,
              In_Internal_Width: 216.188,
              In_Thickness: 4.461,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 08:48:22",
              Tp_Result: "OK",
              Tp_JIG: 26.7,
              Tp_JIG_MIN: 25.3,
              Tp_JIG_MAX: 29.6,
              Tp_WJIG: 41.9,
              Tp_WJIG_MIN: 35.3,
              Tp_WJIG_MAX: 42,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 84,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1595",
              In_InsertTime: "2023-01-05 08:14:59",
              In_Result: "OK",
              In_Outer_Width1: 223.867,
              In_Outer_Width2: 223.851,
              In_Internal_Width: 216.159,
              In_Thickness: 4.456,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 08:52:57",
              Tp_Result: "OK",
              Tp_JIG: 32.4,
              Tp_JIG_MIN: 32.4,
              Tp_JIG_MAX: 36,
              Tp_WJIG: 52.9,
              Tp_WJIG_MIN: 44.2,
              Tp_WJIG_MAX: 55.1,
              Tp_Vibration: 896,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 112,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1596",
              In_InsertTime: "2023-01-05 08:24:30",
              In_Result: "OK",
              In_Outer_Width1: 223.693,
              In_Outer_Width2: 223.778,
              In_Internal_Width: 216.114,
              In_Thickness: 4.446,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 08:49:21",
              Tp_Result: "OK",
              Tp_JIG: 35.4,
              Tp_JIG_MIN: 33.4,
              Tp_JIG_MAX: 38.2,
              Tp_WJIG: 51.6,
              Tp_WJIG_MIN: 46.4,
              Tp_WJIG_MAX: 54.2,
              Tp_Vibration: 793,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 184,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1602",
              In_InsertTime: "2023-01-05 08:47:03",
              In_Result: "OK",
              In_Outer_Width1: 223.873,
              In_Outer_Width2: 223.894,
              In_Internal_Width: 216.209,
              In_Thickness: 4.473,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 09:12:05",
              Tp_Result: "OK",
              Tp_JIG: 35.5,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 38.3,
              Tp_WJIG: 45,
              Tp_WJIG_MIN: 40,
              Tp_WJIG_MAX: 47.2,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 82,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1603",
              In_InsertTime: "2023-01-05 08:14:32",
              In_Result: "OK",
              In_Outer_Width1: 223.92,
              In_Outer_Width2: 223.878,
              In_Internal_Width: 216.184,
              In_Thickness: 4.465,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 09:05:11",
              Tp_Result: "OK",
              Tp_JIG: 37.3,
              Tp_JIG_MIN: 36,
              Tp_JIG_MAX: 38.9,
              Tp_WJIG: 48.7,
              Tp_WJIG_MIN: 41,
              Tp_WJIG_MAX: 50.1,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 119,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1607",
              In_InsertTime: "2023-01-05 08:28:30",
              In_Result: "OK",
              In_Outer_Width1: 223.8,
              In_Outer_Width2: 223.812,
              In_Internal_Width: 216.14,
              In_Thickness: 4.428,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 09:16:24",
              Tp_Result: "OK",
              Tp_JIG: 37.3,
              Tp_JIG_MIN: 36.2,
              Tp_JIG_MAX: 39.3,
              Tp_WJIG: 43,
              Tp_WJIG_MIN: 37.2,
              Tp_WJIG_MAX: 45.5,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 125,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1611",
              In_InsertTime: "2023-01-05 08:29:21",
              In_Result: "OK",
              In_Outer_Width1: 223.806,
              In_Outer_Width2: 223.868,
              In_Internal_Width: 216.184,
              In_Thickness: 4.431,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 08:45:59",
              Tp_Result: "OK",
              Tp_JIG: 15.2,
              Tp_JIG_MIN: 13.5,
              Tp_JIG_MAX: 17.5,
              Tp_WJIG: 44.5,
              Tp_WJIG_MIN: 41.8,
              Tp_WJIG_MAX: 46.9,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 188,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1616",
              In_InsertTime: "2023-01-05 08:48:11",
              In_Result: "OK",
              In_Outer_Width1: 223.892,
              In_Outer_Width2: 223.892,
              In_Internal_Width: 216.18,
              In_Thickness: 4.454,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 09:22:54",
              Tp_Result: "OK",
              Tp_JIG: 33.4,
              Tp_JIG_MIN: 31.2,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 51.4,
              Tp_WJIG_MIN: 44.7,
              Tp_WJIG_MAX: 53,
              Tp_Vibration: 901,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 181,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1618",
              In_InsertTime: "2023-01-05 08:45:48",
              In_Result: "OK",
              In_Outer_Width1: 223.99,
              In_Outer_Width2: 224.185,
              In_Internal_Width: 216.188,
              In_Thickness: 4.411,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 09:36:44",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.5,
              Tp_JIG_MAX: 36.3,
              Tp_WJIG: 38.7,
              Tp_WJIG_MIN: 29.6,
              Tp_WJIG_MAX: 39.1,
              Tp_Vibration: 880,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 176,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1622",
              In_InsertTime: "2023-01-05 08:43:56",
              In_Result: "OK",
              In_Outer_Width1: 223.857,
              In_Outer_Width2: 223.791,
              In_Internal_Width: 216.176,
              In_Thickness: 4.455,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 09:10:02",
              Tp_Result: "OK",
              Tp_JIG: 12.5,
              Tp_JIG_MIN: 10.8,
              Tp_JIG_MAX: 14,
              Tp_WJIG: 38.4,
              Tp_WJIG_MIN: 33,
              Tp_WJIG_MAX: 41.1,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 186,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1629",
              In_InsertTime: "2023-01-05 08:47:53",
              In_Result: "OK",
              In_Outer_Width1: 223.899,
              In_Outer_Width2: 223.864,
              In_Internal_Width: 216.261,
              In_Thickness: 4.437,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 09:05:05",
              Tp_Result: "OK",
              Tp_JIG: 34.6,
              Tp_JIG_MIN: 32.6,
              Tp_JIG_MAX: 34.9,
              Tp_WJIG: 33.5,
              Tp_WJIG_MIN: 30.7,
              Tp_WJIG_MAX: 35.6,
              Tp_Vibration: 852,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 177,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1631",
              In_InsertTime: "2023-01-05 08:44:06",
              In_Result: "OK",
              In_Outer_Width1: 223.817,
              In_Outer_Width2: 223.833,
              In_Internal_Width: 216.155,
              In_Thickness: 4.447,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 09:02:39",
              Tp_Result: "OK",
              Tp_JIG: 14.9,
              Tp_JIG_MIN: 13.7,
              Tp_JIG_MAX: 17.1,
              Tp_WJIG: 37.1,
              Tp_WJIG_MIN: 35.1,
              Tp_WJIG_MAX: 40.2,
              Tp_Vibration: 897,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 126,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1632",
              In_InsertTime: "2023-01-05 08:29:30",
              In_Result: "OK",
              In_Outer_Width1: 223.673,
              In_Outer_Width2: 223.669,
              In_Internal_Width: 216.117,
              In_Thickness: 4.448,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 08:54:00",
              Tp_Result: "OK",
              Tp_JIG: 26.6,
              Tp_JIG_MIN: 24.9,
              Tp_JIG_MAX: 29.3,
              Tp_WJIG: 40,
              Tp_WJIG_MIN: 34.3,
              Tp_WJIG_MAX: 40.5,
              Tp_Vibration: 796,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 191,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1633",
              In_InsertTime: "2023-01-05 08:49:01",
              In_Result: "OK",
              In_Outer_Width1: 223.773,
              In_Outer_Width2: 223.735,
              In_Internal_Width: 216.087,
              In_Thickness: 4.45,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 09:34:20",
              Tp_Result: "OK",
              Tp_JIG: 32.1,
              Tp_JIG_MIN: 31.1,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 33.5,
              Tp_WJIG_MIN: 30.2,
              Tp_WJIG_MAX: 35.9,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 118,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1634",
              In_InsertTime: "2023-01-05 08:28:22",
              In_Result: "OK",
              In_Outer_Width1: 223.775,
              In_Outer_Width2: 223.75,
              In_Internal_Width: 216.115,
              In_Thickness: 4.448,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 09:11:42",
              Tp_Result: "OK",
              Tp_JIG: 31.8,
              Tp_JIG_MIN: 30.5,
              Tp_JIG_MAX: 33.7,
              Tp_WJIG: 36.8,
              Tp_WJIG_MIN: 33,
              Tp_WJIG_MAX: 39.5,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 120,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1636",
              In_InsertTime: "2023-01-05 08:28:38",
              In_Result: "OK",
              In_Outer_Width1: 223.891,
              In_Outer_Width2: 223.877,
              In_Internal_Width: 216.203,
              In_Thickness: 4.45,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 08:45:01",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 33.4,
              Tp_JIG_MAX: 37.3,
              Tp_WJIG: 37.3,
              Tp_WJIG_MIN: 33.8,
              Tp_WJIG_MAX: 39.5,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 175,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1639",
              In_InsertTime: "2023-01-05 08:43:48",
              In_Result: "OK",
              In_Outer_Width1: 224.114,
              In_Outer_Width2: 223.874,
              In_Internal_Width: 216.187,
              In_Thickness: 4.457,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 09:21:52",
              Tp_Result: "OK",
              Tp_JIG: 33.3,
              Tp_JIG_MIN: 33.2,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 51.9,
              Tp_WJIG_MIN: 41.7,
              Tp_WJIG_MAX: 53.3,
              Tp_Vibration: 871,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 39,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1707",
              In_InsertTime: "2023-01-05 08:01:38",
              In_Result: "OK",
              In_Outer_Width1: 223.822,
              In_Outer_Width2: 223.827,
              In_Internal_Width: 216.124,
              In_Thickness: 4.465,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 08:36:43",
              Tp_Result: "OK",
              Tp_JIG: 33.8,
              Tp_JIG_MIN: 30.4,
              Tp_JIG_MAX: 35.4,
              Tp_WJIG: 30.9,
              Tp_WJIG_MIN: 25,
              Tp_WJIG_MAX: 32.8,
              Tp_Vibration: 899,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 40,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1710",
              In_InsertTime: "2023-01-05 08:01:50",
              In_Result: "OK",
              In_Outer_Width1: 223.787,
              In_Outer_Width2: 223.9,
              In_Internal_Width: 216.146,
              In_Thickness: 4.465,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 08:47:01",
              Tp_Result: "OK",
              Tp_JIG: 53.7,
              Tp_JIG_MIN: 52,
              Tp_JIG_MAX: 54.5,
              Tp_WJIG: 64.1,
              Tp_WJIG_MIN: 55.1,
              Tp_WJIG_MAX: 66.5,
              Tp_Vibration: 887,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 38,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1711",
              In_InsertTime: "2023-01-05 08:01:30",
              In_Result: "OK",
              In_Outer_Width1: 223.944,
              In_Outer_Width2: 223.921,
              In_Internal_Width: 216.119,
              In_Thickness: 4.448,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 08:26:35",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.2,
              Tp_JIG_MAX: 38,
              Tp_WJIG: 55.2,
              Tp_WJIG_MIN: 48.3,
              Tp_WJIG_MAX: 56.6,
              Tp_Vibration: 788,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 106,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1723",
              In_InsertTime: "2023-01-05 08:21:53",
              In_Result: "OK",
              In_Outer_Width1: 223.81,
              In_Outer_Width2: 223.854,
              In_Internal_Width: 216.116,
              In_Thickness: 4.452,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 08:47:24",
              Tp_Result: "OK",
              Tp_JIG: 12.3,
              Tp_JIG_MIN: 11.2,
              Tp_JIG_MAX: 14.1,
              Tp_WJIG: 34.8,
              Tp_WJIG_MIN: 28.1,
              Tp_WJIG_MAX: 36.9,
              Tp_Vibration: 875,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 128,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1726",
              In_InsertTime: "2023-01-05 08:29:47",
              In_Result: "OK",
              In_Outer_Width1: 223.842,
              In_Outer_Width2: 223.848,
              In_Internal_Width: 216.144,
              In_Thickness: 4.458,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 09:15:20",
              Tp_Result: "OK",
              Tp_JIG: 53.2,
              Tp_JIG_MIN: 51.6,
              Tp_JIG_MAX: 54.2,
              Tp_WJIG: 59.5,
              Tp_WJIG_MIN: 51.3,
              Tp_WJIG_MAX: 62.1,
              Tp_Vibration: 902,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 215,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1727",
              In_InsertTime: "2023-01-05 08:55:56",
              In_Result: "OK",
              In_Outer_Width1: 223.873,
              In_Outer_Width2: 223.93,
              In_Internal_Width: 216.186,
              In_Thickness: 4.453,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 09:46:04",
              Tp_Result: "OK",
              Tp_JIG: 24.9,
              Tp_JIG_MIN: 22.9,
              Tp_JIG_MAX: 26.6,
              Tp_WJIG: 58.5,
              Tp_WJIG_MIN: 48.4,
              Tp_WJIG_MAX: 59.2,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 272,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1734",
              In_InsertTime: "2023-01-05 09:14:03",
              In_Result: "OK",
              In_Outer_Width1: 224.209,
              In_Outer_Width2: 223.786,
              In_Internal_Width: 216.178,
              In_Thickness: 4.449,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 09:40:42",
              Tp_Result: "OK",
              Tp_JIG: 26.8,
              Tp_JIG_MIN: 25.1,
              Tp_JIG_MAX: 29.6,
              Tp_WJIG: 29.8,
              Tp_WJIG_MIN: 26.4,
              Tp_WJIG_MAX: 31.8,
              Tp_Vibration: 790,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 213,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1736",
              In_InsertTime: "2023-01-05 08:55:30",
              In_Result: "OK",
              In_Outer_Width1: 223.985,
              In_Outer_Width2: 224.01,
              In_Internal_Width: 216.182,
              In_Thickness: 4.481,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 09:33:23",
              Tp_Result: "OK",
              Tp_JIG: 33,
              Tp_JIG_MIN: 33,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 35,
              Tp_WJIG_MIN: 27.6,
              Tp_WJIG_MAX: 37.8,
              Tp_Vibration: 906,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 179,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1759",
              In_InsertTime: "2023-01-05 08:44:34",
              In_Result: "OK",
              In_Outer_Width1: 223.795,
              In_Outer_Width2: 223.818,
              In_Internal_Width: 216.123,
              In_Thickness: 4.451,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 09:34:42",
              Tp_Result: "OK",
              Tp_JIG: 25.3,
              Tp_JIG_MIN: 23.7,
              Tp_JIG_MAX: 26.5,
              Tp_WJIG: 35.1,
              Tp_WJIG_MIN: 29.9,
              Tp_WJIG_MAX: 37,
              Tp_Vibration: 796,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 113,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1812",
              In_InsertTime: "2023-01-05 08:24:47",
              In_Result: "OK",
              In_Outer_Width1: 223.896,
              In_Outer_Width2: 223.926,
              In_Internal_Width: 216.244,
              In_Thickness: 4.431,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 09:10:48",
              Tp_Result: "OK",
              Tp_JIG: 28.3,
              Tp_JIG_MIN: 26.9,
              Tp_JIG_MAX: 30.1,
              Tp_WJIG: 41.8,
              Tp_WJIG_MIN: 34.8,
              Tp_WJIG_MAX: 43.6,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 187,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1817",
              In_InsertTime: "2023-01-05 08:48:03",
              In_Result: "OK",
              In_Outer_Width1: 223.881,
              In_Outer_Width2: 223.942,
              In_Internal_Width: 216.271,
              In_Thickness: 4.466,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 09:33:32",
              Tp_Result: "OK",
              Tp_JIG: 28.1,
              Tp_JIG_MIN: 26.4,
              Tp_JIG_MAX: 29.9,
              Tp_WJIG: 48.5,
              Tp_WJIG_MIN: 40.7,
              Tp_WJIG_MAX: 49.8,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 123,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1818",
              In_InsertTime: "2023-01-05 08:29:06",
              In_Result: "OK",
              In_Outer_Width1: 223.934,
              In_Outer_Width2: 224.093,
              In_Internal_Width: 216.287,
              In_Thickness: 4.472,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 09:17:40",
              Tp_Result: "OK",
              Tp_JIG: 25,
              Tp_JIG_MIN: 23.4,
              Tp_JIG_MAX: 26.6,
              Tp_WJIG: 45.5,
              Tp_WJIG_MIN: 36.1,
              Tp_WJIG_MAX: 45.5,
              Tp_Vibration: 798,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 180,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1819",
              In_InsertTime: "2023-01-05 08:44:43",
              In_Result: "OK",
              In_Outer_Width1: 223.996,
              In_Outer_Width2: 224.116,
              In_Internal_Width: 216.296,
              In_Thickness: 4.459,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 09:11:03",
              Tp_Result: "OK",
              Tp_JIG: 26.4,
              Tp_JIG_MIN: 24.7,
              Tp_JIG_MAX: 29.5,
              Tp_WJIG: 45.9,
              Tp_WJIG_MIN: 36.7,
              Tp_WJIG_MAX: 45.9,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 206,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1821",
              In_InsertTime: "2023-01-05 08:53:53",
              In_Result: "OK",
              In_Outer_Width1: 223.965,
              In_Outer_Width2: 224.098,
              In_Internal_Width: 216.287,
              In_Thickness: 4.419,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 09:28:40",
              Tp_Result: "OK",
              Tp_JIG: 33.6,
              Tp_JIG_MIN: 30.8,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 51.6,
              Tp_WJIG_MIN: 44,
              Tp_WJIG_MAX: 52.6,
              Tp_Vibration: 854,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 122,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1824",
              In_InsertTime: "2023-01-05 08:28:57",
              In_Result: "OK",
              In_Outer_Width1: 223.987,
              In_Outer_Width2: 224.181,
              In_Internal_Width: 216.189,
              In_Thickness: 4.545,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 09:04:30",
              Tp_Result: "OK",
              Tp_JIG: 33,
              Tp_JIG_MIN: 33,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 33.6,
              Tp_WJIG_MIN: 26.2,
              Tp_WJIG_MAX: 35.7,
              Tp_Vibration: 897,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 190,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1827",
              In_InsertTime: "2023-01-05 08:48:28",
              In_Result: "OK",
              In_Outer_Width1: 224.126,
              In_Outer_Width2: 223.996,
              In_Internal_Width: 216.294,
              In_Thickness: 4.451,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 09:14:41",
              Tp_Result: "OK",
              Tp_JIG: 32.7,
              Tp_JIG_MIN: 31.4,
              Tp_JIG_MAX: 35.4,
              Tp_WJIG: 34.7,
              Tp_WJIG_MIN: 27.9,
              Tp_WJIG_MAX: 35.3,
              Tp_Vibration: 892,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 173,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1829",
              In_InsertTime: "2023-01-05 08:43:26",
              In_Result: "OK",
              In_Outer_Width1: 223.913,
              In_Outer_Width2: 223.906,
              In_Internal_Width: 216.278,
              In_Thickness: 4.456,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 09:28:43",
              Tp_Result: "OK",
              Tp_JIG: 31.6,
              Tp_JIG_MIN: 30.4,
              Tp_JIG_MAX: 33.7,
              Tp_WJIG: 50.4,
              Tp_WJIG_MIN: 41.7,
              Tp_WJIG_MAX: 50.8,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 172,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1830",
              In_InsertTime: "2023-01-05 08:42:53",
              In_Result: "OK",
              In_Outer_Width1: 223.86,
              In_Outer_Width2: 223.82,
              In_Internal_Width: 216.233,
              In_Thickness: 4.447,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 09:33:29",
              Tp_Result: "OK",
              Tp_JIG: 37.7,
              Tp_JIG_MIN: 36.4,
              Tp_JIG_MAX: 39.5,
              Tp_WJIG: 34.5,
              Tp_WJIG_MIN: 27.4,
              Tp_WJIG_MAX: 35.4,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 178,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1835",
              In_InsertTime: "2023-01-05 08:44:22",
              In_Result: "OK",
              In_Outer_Width1: 224.011,
              In_Outer_Width2: 224.21,
              In_Internal_Width: 216.301,
              In_Thickness: 4.441,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 09:29:50",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 10.1,
              Tp_WJIG: 55.3,
              Tp_WJIG_MIN: 50.4,
              Tp_WJIG_MAX: 56.2,
              Tp_Vibration: 803,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 121,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1836",
              In_InsertTime: "2023-01-05 08:28:48",
              In_Result: "OK",
              In_Outer_Width1: 223.916,
              In_Outer_Width2: 224.11,
              In_Internal_Width: 216.174,
              In_Thickness: 4.516,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 08:53:01",
              Tp_Result: "OK",
              Tp_JIG: 12.5,
              Tp_JIG_MIN: 11,
              Tp_JIG_MAX: 14,
              Tp_WJIG: 30.2,
              Tp_WJIG_MIN: 23.6,
              Tp_WJIG_MAX: 32.2,
              Tp_Vibration: 878,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 185,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1840",
              In_InsertTime: "2023-01-05 08:47:13",
              In_Result: "OK",
              In_Outer_Width1: 224.014,
              In_Outer_Width2: 224.085,
              In_Internal_Width: 216.223,
              In_Thickness: 4.535,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 09:04:05",
              Tp_Result: "OK",
              Tp_JIG: 21.4,
              Tp_JIG_MIN: 19.9,
              Tp_JIG_MAX: 23.8,
              Tp_WJIG: 36.7,
              Tp_WJIG_MIN: 31.5,
              Tp_WJIG_MAX: 38.5,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 127,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1844",
              In_InsertTime: "2023-01-05 08:29:39",
              In_Result: "OK",
              In_Outer_Width1: 223.777,
              In_Outer_Width2: 223.854,
              In_Internal_Width: 216.217,
              In_Thickness: 4.457,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 09:19:47",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 44.7,
              Tp_WJIG_MIN: 36.3,
              Tp_WJIG_MAX: 47.1,
              Tp_Vibration: 881,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 124,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1845",
              In_InsertTime: "2023-01-05 08:29:13",
              In_Result: "OK",
              In_Outer_Width1: 223.89,
              In_Outer_Width2: 224.006,
              In_Internal_Width: 216.179,
              In_Thickness: 4.525,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 09:13:07",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 10,
              Tp_WJIG: 45.1,
              Tp_WJIG_MIN: 40.4,
              Tp_WJIG_MAX: 46.4,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 174,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1846",
              In_InsertTime: "2023-01-05 08:43:38",
              In_Result: "OK",
              In_Outer_Width1: 223.925,
              In_Outer_Width2: 224.038,
              In_Internal_Width: 216.26,
              In_Thickness: 4.458,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 09:01:42",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 33.6,
              Tp_JIG_MAX: 37.4,
              Tp_WJIG: 42.9,
              Tp_WJIG_MIN: 37.5,
              Tp_WJIG_MAX: 44.3,
              Tp_Vibration: 787,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 48,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1850",
              In_InsertTime: "2023-01-05 08:03:37",
              In_Result: "OK",
              In_Outer_Width1: 223.854,
              In_Outer_Width2: 224.018,
              In_Internal_Width: 216.223,
              In_Thickness: 4.446,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 08:53:46",
              Tp_Result: "OK",
              Tp_JIG: 37.4,
              Tp_JIG_MIN: 36.3,
              Tp_JIG_MAX: 39.5,
              Tp_WJIG: 46.6,
              Tp_WJIG_MIN: 36.1,
              Tp_WJIG_MAX: 46.7,
              Tp_Vibration: 852,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 183,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1853",
              In_InsertTime: "2023-01-05 08:46:44",
              In_Result: "OK",
              In_Outer_Width1: 224.172,
              In_Outer_Width2: 223.935,
              In_Internal_Width: 216.252,
              In_Thickness: 4.514,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 09:37:45",
              Tp_Result: "OK",
              Tp_JIG: 33,
              Tp_JIG_MIN: 31,
              Tp_JIG_MAX: 33.5,
              Tp_WJIG: 51.6,
              Tp_WJIG_MIN: 43,
              Tp_WJIG_MAX: 51.6,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 56,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1854",
              In_InsertTime: "2023-01-05 08:07:04",
              In_Result: "OK",
              In_Outer_Width1: 223.917,
              In_Outer_Width2: 223.96,
              In_Internal_Width: 216.191,
              In_Thickness: 4.513,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 08:52:41",
              Tp_Result: "OK",
              Tp_JIG: 53.6,
              Tp_JIG_MIN: 52,
              Tp_JIG_MAX: 54.3,
              Tp_WJIG: 43,
              Tp_WJIG_MIN: 34.6,
              Tp_WJIG_MAX: 44.9,
              Tp_Vibration: 882,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 46,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1855",
              In_InsertTime: "2023-01-05 08:03:16",
              In_Result: "OK",
              In_Outer_Width1: 224.089,
              In_Outer_Width2: 224.062,
              In_Internal_Width: 216.28,
              In_Thickness: 4.442,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 08:48:09",
              Tp_Result: "OK",
              Tp_JIG: 27.9,
              Tp_JIG_MIN: 26.3,
              Tp_JIG_MAX: 29.9,
              Tp_WJIG: 46.6,
              Tp_WJIG_MIN: 38.7,
              Tp_WJIG_MAX: 47.6,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 45,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1857",
              In_InsertTime: "2023-01-05 08:02:54",
              In_Result: "OK",
              In_Outer_Width1: 223.909,
              In_Outer_Width2: 223.981,
              In_Internal_Width: 216.198,
              In_Thickness: 4.514,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 08:41:25",
              Tp_Result: "OK",
              Tp_JIG: 32.3,
              Tp_JIG_MIN: 32.2,
              Tp_JIG_MAX: 35.9,
              Tp_WJIG: 35.3,
              Tp_WJIG_MIN: 27,
              Tp_WJIG_MAX: 37.6,
              Tp_Vibration: 892,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 47,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1863",
              In_InsertTime: "2023-01-05 08:03:27",
              In_Result: "OK",
              In_Outer_Width1: 223.837,
              In_Outer_Width2: 224,
              In_Internal_Width: 216.248,
              In_Thickness: 4.506,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 08:49:03",
              Tp_Result: "OK",
              Tp_JIG: 31.6,
              Tp_JIG_MIN: 30.6,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 51.9,
              Tp_WJIG_MIN: 44.8,
              Tp_WJIG_MAX: 53.4,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 52,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1866",
              In_InsertTime: "2023-01-05 08:04:38",
              In_Result: "OK",
              In_Outer_Width1: 223.957,
              In_Outer_Width2: 223.943,
              In_Internal_Width: 216.222,
              In_Thickness: 4.523,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 08:50:48",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.9,
              Tp_WJIG: 47.9,
              Tp_WJIG_MIN: 44.8,
              Tp_WJIG_MAX: 49.8,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 53,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1867",
              In_InsertTime: "2023-01-05 08:04:47",
              In_Result: "OK",
              In_Outer_Width1: 223.892,
              In_Outer_Width2: 223.972,
              In_Internal_Width: 216.166,
              In_Thickness: 4.523,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 08:57:12",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.4,
              Tp_JIG_MAX: 36.4,
              Tp_WJIG: 42.2,
              Tp_WJIG_MIN: 33.8,
              Tp_WJIG_MAX: 44.5,
              Tp_Vibration: 884,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 51,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1877",
              In_InsertTime: "2023-01-05 08:04:18",
              In_Result: "OK",
              In_Outer_Width1: 223.867,
              In_Outer_Width2: 223.901,
              In_Internal_Width: 216.21,
              In_Thickness: 4.497,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 08:55:24",
              Tp_Result: "OK",
              Tp_JIG: 25.3,
              Tp_JIG_MIN: 23.2,
              Tp_JIG_MAX: 26.9,
              Tp_WJIG: 57.3,
              Tp_WJIG_MIN: 47.7,
              Tp_WJIG_MAX: 58.2,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 116,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1942",
              In_InsertTime: "2023-01-05 08:28:04",
              In_Result: "OK",
              In_Outer_Width1: 223.966,
              In_Outer_Width2: 223.893,
              In_Internal_Width: 216.152,
              In_Thickness: 4.465,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 08:51:56",
              Tp_Result: "OK",
              Tp_JIG: 32.6,
              Tp_JIG_MIN: 31.5,
              Tp_JIG_MAX: 35.3,
              Tp_WJIG: 34.6,
              Tp_WJIG_MIN: 29.4,
              Tp_WJIG_MAX: 35.4,
              Tp_Vibration: 902,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 111,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1946",
              In_InsertTime: "2023-01-05 08:24:14",
              In_Result: "OK",
              In_Outer_Width1: 223.806,
              In_Outer_Width2: 224.038,
              In_Internal_Width: 216.18,
              In_Thickness: 4.47,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 09:15:09",
              Tp_Result: "OK",
              Tp_JIG: 33.1,
              Tp_JIG_MIN: 31.3,
              Tp_JIG_MAX: 33.7,
              Tp_WJIG: 51,
              Tp_WJIG_MIN: 45.1,
              Tp_WJIG_MAX: 52.3,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 104,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1957",
              In_InsertTime: "2023-01-05 08:21:36",
              In_Result: "OK",
              In_Outer_Width1: 223.928,
              In_Outer_Width2: 223.939,
              In_Internal_Width: 216.105,
              In_Thickness: 4.479,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 09:11:59",
              Tp_Result: "OK",
              Tp_JIG: 25,
              Tp_JIG_MIN: 23.2,
              Tp_JIG_MAX: 26.8,
              Tp_WJIG: 46.2,
              Tp_WJIG_MIN: 39.6,
              Tp_WJIG_MAX: 47.6,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 102,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1962",
              In_InsertTime: "2023-01-05 08:20:52",
              In_Result: "OK",
              In_Outer_Width1: 223.908,
              In_Outer_Width2: 224.097,
              In_Internal_Width: 216.143,
              In_Thickness: 4.476,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 08:58:44",
              Tp_Result: "OK",
              Tp_JIG: 32.6,
              Tp_JIG_MIN: 32.6,
              Tp_JIG_MAX: 36.2,
              Tp_WJIG: 54.3,
              Tp_WJIG_MIN: 45.1,
              Tp_WJIG_MAX: 55.9,
              Tp_Vibration: 891,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 115,
              INDATE: "20230105",
              ID_No: "21100863A 2CM1970",
              In_InsertTime: "2023-01-05 08:27:55",
              In_Result: "OK",
              In_Outer_Width1: 223.734,
              In_Outer_Width2: 224.003,
              In_Internal_Width: 216.052,
              In_Thickness: 4.465,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 08:59:49",
              Tp_Result: "OK",
              Tp_JIG: 33.6,
              Tp_JIG_MIN: 29.6,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 39.3,
              Tp_WJIG_MIN: 33.5,
              Tp_WJIG_MAX: 41.3,
              Tp_Vibration: 899,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 3,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0034",
              In_InsertTime: "2023-01-05 07:52:15",
              In_Result: "OK",
              In_Outer_Width1: 223.756,
              In_Outer_Width2: 223.792,
              In_Internal_Width: 216.155,
              In_Thickness: 4.482,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 08:25:09",
              Tp_Result: "OK",
              Tp_JIG: 33.8,
              Tp_JIG_MIN: 30.4,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 36.1,
              Tp_WJIG_MIN: 27.7,
              Tp_WJIG_MAX: 36.6,
              Tp_Vibration: 893,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 6,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0036",
              In_InsertTime: "2023-01-05 07:52:42",
              In_Result: "OK",
              In_Outer_Width1: 223.884,
              In_Outer_Width2: 223.817,
              In_Internal_Width: 216.12,
              In_Thickness: 4.474,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 08:17:48",
              Tp_Result: "OK",
              Tp_JIG: 32.7,
              Tp_JIG_MIN: 31.4,
              Tp_JIG_MAX: 35.4,
              Tp_WJIG: 40,
              Tp_WJIG_MIN: 31,
              Tp_WJIG_MAX: 40.1,
              Tp_Vibration: 888,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 12,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0038",
              In_InsertTime: "2023-01-05 07:53:34",
              In_Result: "OK",
              In_Outer_Width1: 224.132,
              In_Outer_Width2: 223.793,
              In_Internal_Width: 216.253,
              In_Thickness: 4.51,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 08:42:26",
              Tp_Result: "OK",
              Tp_JIG: 37.2,
              Tp_JIG_MIN: 35.9,
              Tp_JIG_MAX: 39,
              Tp_WJIG: 50.8,
              Tp_WJIG_MIN: 40.1,
              Tp_WJIG_MAX: 50.9,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 5,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0040",
              In_InsertTime: "2023-01-05 07:52:32",
              In_Result: "OK",
              In_Outer_Width1: 224,
              In_Outer_Width2: 223.938,
              In_Internal_Width: 216.163,
              In_Thickness: 4.505,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:08:11",
              Tp_Result: "OK",
              Tp_JIG: 21.7,
              Tp_JIG_MIN: 20.4,
              Tp_JIG_MAX: 23.9,
              Tp_WJIG: 30.5,
              Tp_WJIG_MIN: 24.6,
              Tp_WJIG_MAX: 31.7,
              Tp_Vibration: 776,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 21,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0042",
              In_InsertTime: "2023-01-05 07:56:07",
              In_Result: "OK",
              In_Outer_Width1: 223.763,
              In_Outer_Width2: 223.796,
              In_Internal_Width: 216.137,
              In_Thickness: 4.492,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 08:30:55",
              Tp_Result: "OK",
              Tp_JIG: 33.6,
              Tp_JIG_MIN: 31,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 40.2,
              Tp_WJIG_MIN: 30.9,
              Tp_WJIG_MAX: 40.5,
              Tp_Vibration: 893,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 30,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0045",
              In_InsertTime: "2023-01-05 07:57:56",
              In_Result: "OK",
              In_Outer_Width1: 223.817,
              In_Outer_Width2: 223.864,
              In_Internal_Width: 216.146,
              In_Thickness: 4.486,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 08:48:06",
              Tp_Result: "OK",
              Tp_JIG: 37.3,
              Tp_JIG_MIN: 35.6,
              Tp_JIG_MAX: 39.3,
              Tp_WJIG: 53.7,
              Tp_WJIG_MIN: 42.9,
              Tp_WJIG_MAX: 53.8,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 20,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0046",
              In_InsertTime: "2023-01-05 07:55:57",
              In_Result: "OK",
              In_Outer_Width1: 224.258,
              In_Outer_Width2: 223.949,
              In_Internal_Width: 216.319,
              In_Thickness: 4.46,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 08:20:54",
              Tp_Result: "OK",
              Tp_JIG: 34.8,
              Tp_JIG_MIN: 33.5,
              Tp_JIG_MAX: 37.7,
              Tp_WJIG: 50.6,
              Tp_WJIG_MIN: 42.5,
              Tp_WJIG_MAX: 51.7,
              Tp_Vibration: 791,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 59,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0049",
              In_InsertTime: "2023-01-05 08:07:49",
              In_Result: "OK",
              In_Outer_Width1: 223.893,
              In_Outer_Width2: 223.878,
              In_Internal_Width: 216.255,
              In_Thickness: 4.451,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:24:57",
              Tp_Result: "OK",
              Tp_JIG: 21.3,
              Tp_JIG_MIN: 19.9,
              Tp_JIG_MAX: 23.8,
              Tp_WJIG: 43.1,
              Tp_WJIG_MIN: 38,
              Tp_WJIG_MAX: 45.1,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 243,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0052",
              In_InsertTime: "2023-01-05 09:05:17",
              In_Result: "OK",
              In_Outer_Width1: 223.887,
              In_Outer_Width2: 224.061,
              In_Internal_Width: 216.247,
              In_Thickness: 4.446,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 09:23:56",
              Tp_Result: "OK",
              Tp_JIG: 35.3,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 37.3,
              Tp_WJIG: 37,
              Tp_WJIG_MIN: 32.4,
              Tp_WJIG_MAX: 38,
              Tp_Vibration: 783,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 58,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0053",
              In_InsertTime: "2023-01-05 08:07:29",
              In_Result: "OK",
              In_Outer_Width1: 223.993,
              In_Outer_Width2: 223.929,
              In_Internal_Width: 216.28,
              In_Thickness: 4.443,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 08:32:17",
              Tp_Result: "OK",
              Tp_JIG: 35.4,
              Tp_JIG_MIN: 33.5,
              Tp_JIG_MAX: 38.4,
              Tp_WJIG: 56.4,
              Tp_WJIG_MIN: 49.1,
              Tp_WJIG_MAX: 57.8,
              Tp_Vibration: 787,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 25,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0055",
              In_InsertTime: "2023-01-05 07:56:53",
              In_Result: "OK",
              In_Outer_Width1: 223.892,
              In_Outer_Width2: 223.86,
              In_Internal_Width: 216.223,
              In_Thickness: 4.452,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 08:46:54",
              Tp_Result: "OK",
              Tp_JIG: 33.3,
              Tp_JIG_MIN: 31.3,
              Tp_JIG_MAX: 33.9,
              Tp_WJIG: 41.4,
              Tp_WJIG_MIN: 34.1,
              Tp_WJIG_MAX: 42.7,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 24,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0057",
              In_InsertTime: "2023-01-05 07:56:42",
              In_Result: "OK",
              In_Outer_Width1: 223.739,
              In_Outer_Width2: 223.771,
              In_Internal_Width: 216.175,
              In_Thickness: 4.449,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 08:23:30",
              Tp_Result: "OK",
              Tp_JIG: 32.5,
              Tp_JIG_MIN: 31,
              Tp_JIG_MAX: 35.3,
              Tp_WJIG: 46.5,
              Tp_WJIG_MIN: 39.6,
              Tp_WJIG_MAX: 48.1,
              Tp_Vibration: 902,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 29,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0058",
              In_InsertTime: "2023-01-05 07:57:46",
              In_Result: "OK",
              In_Outer_Width1: 223.795,
              In_Outer_Width2: 223.83,
              In_Internal_Width: 216.188,
              In_Thickness: 4.445,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 08:24:32",
              Tp_Result: "OK",
              Tp_JIG: 12.4,
              Tp_JIG_MIN: 10.4,
              Tp_JIG_MAX: 13.9,
              Tp_WJIG: 43.9,
              Tp_WJIG_MIN: 34.7,
              Tp_WJIG_MAX: 45.8,
              Tp_Vibration: 901,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 8,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0059",
              In_InsertTime: "2023-01-05 07:53:02",
              In_Result: "OK",
              In_Outer_Width1: 223.841,
              In_Outer_Width2: 223.898,
              In_Internal_Width: 216.184,
              In_Thickness: 4.441,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 08:29:50",
              Tp_Result: "OK",
              Tp_JIG: 32,
              Tp_JIG_MIN: 31.5,
              Tp_JIG_MAX: 35.6,
              Tp_WJIG: 51.2,
              Tp_WJIG_MIN: 39.6,
              Tp_WJIG_MAX: 51.9,
              Tp_Vibration: 899,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0061",
              In_InsertTime: "2023-01-05 07:51:59",
              In_Result: "OK",
              In_Outer_Width1: 223.837,
              In_Outer_Width2: 223.739,
              In_Internal_Width: 216.254,
              In_Thickness: 4.434,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 08:14:13",
              Tp_Result: "OK",
              Tp_JIG: 26.6,
              Tp_JIG_MIN: 25.3,
              Tp_JIG_MAX: 29.2,
              Tp_WJIG: 43.7,
              Tp_WJIG_MIN: 35.8,
              Tp_WJIG_MAX: 43.7,
              Tp_Vibration: 797,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 26,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0063",
              In_InsertTime: "2023-01-05 07:57:01",
              In_Result: "OK",
              In_Outer_Width1: 224.194,
              In_Outer_Width2: 223.944,
              In_Internal_Width: 216.282,
              In_Thickness: 4.474,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:14:43",
              Tp_Result: "OK",
              Tp_JIG: 34.7,
              Tp_JIG_MIN: 32.7,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 42.5,
              Tp_WJIG_MIN: 37.3,
              Tp_WJIG_MAX: 44,
              Tp_Vibration: 789,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 36,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0067",
              In_InsertTime: "2023-01-05 07:59:18",
              In_Result: "OK",
              In_Outer_Width1: 224.015,
              In_Outer_Width2: 223.892,
              In_Internal_Width: 216.231,
              In_Thickness: 4.476,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 08:25:33",
              Tp_Result: "OK",
              Tp_JIG: 27,
              Tp_JIG_MIN: 25.7,
              Tp_JIG_MAX: 29.7,
              Tp_WJIG: 33.7,
              Tp_WJIG_MIN: 27.4,
              Tp_WJIG_MAX: 34.1,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 10,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0068",
              In_InsertTime: "2023-01-05 07:53:18",
              In_Result: "OK",
              In_Outer_Width1: 223.963,
              In_Outer_Width2: 224.064,
              In_Internal_Width: 216.216,
              In_Thickness: 4.477,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:09:07",
              Tp_Result: "OK",
              Tp_JIG: 34.6,
              Tp_JIG_MIN: 32.6,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 45.4,
              Tp_WJIG_MIN: 40.1,
              Tp_WJIG_MAX: 46.9,
              Tp_Vibration: 784,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 204,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0069",
              In_InsertTime: "2023-01-05 08:53:32",
              In_Result: "OK",
              In_Outer_Width1: 223.902,
              In_Outer_Width2: 223.894,
              In_Internal_Width: 216.256,
              In_Thickness: 4.477,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 09:10:38",
              Tp_Result: "OK",
              Tp_JIG: 34.4,
              Tp_JIG_MIN: 32.2,
              Tp_JIG_MAX: 34.8,
              Tp_WJIG: 44.4,
              Tp_WJIG_MIN: 38.5,
              Tp_WJIG_MAX: 45.4,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 15,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0070",
              In_InsertTime: "2023-01-05 07:53:58",
              In_Result: "OK",
              In_Outer_Width1: 223.905,
              In_Outer_Width2: 223.877,
              In_Internal_Width: 216.277,
              In_Thickness: 4.48,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 08:11:40",
              Tp_Result: "OK",
              Tp_JIG: 34.7,
              Tp_JIG_MIN: 33.1,
              Tp_JIG_MAX: 37,
              Tp_WJIG: 45.5,
              Tp_WJIG_MIN: 39.8,
              Tp_WJIG_MAX: 47,
              Tp_Vibration: 883,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 239,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0071",
              In_InsertTime: "2023-01-05 09:04:05",
              In_Result: "OK",
              In_Outer_Width1: 223.951,
              In_Outer_Width2: 223.919,
              In_Internal_Width: 216.28,
              In_Thickness: 4.483,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 09:20:52",
              Tp_Result: "OK",
              Tp_JIG: 20.8,
              Tp_JIG_MIN: 19.3,
              Tp_JIG_MAX: 23.5,
              Tp_WJIG: 46.1,
              Tp_WJIG_MIN: 40,
              Tp_WJIG_MAX: 47.3,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 404,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0072",
              In_InsertTime: "2023-01-05 10:07:14",
              In_Result: "OK",
              In_Outer_Width1: 223.861,
              In_Outer_Width2: 223.814,
              In_Internal_Width: 216.217,
              In_Thickness: 4.474,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 10:26:34",
              Tp_Result: "OK",
              Tp_JIG: 21,
              Tp_JIG_MIN: 19.6,
              Tp_JIG_MAX: 23.6,
              Tp_WJIG: 49.7,
              Tp_WJIG_MIN: 43.9,
              Tp_WJIG_MAX: 51,
              Tp_Vibration: 881,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 157,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0073",
              In_InsertTime: "2023-01-05 08:38:00",
              In_Result: "OK",
              In_Outer_Width1: 224.098,
              In_Outer_Width2: 223.934,
              In_Internal_Width: 216.305,
              In_Thickness: 4.481,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 09:16:03",
              Tp_Result: "OK",
              Tp_JIG: 32.7,
              Tp_JIG_MIN: 32.7,
              Tp_JIG_MAX: 36.2,
              Tp_WJIG: 52.8,
              Tp_WJIG_MIN: 42.7,
              Tp_WJIG_MAX: 54.1,
              Tp_Vibration: 894,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 27,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0080",
              In_InsertTime: "2023-01-05 07:57:12",
              In_Result: "OK",
              In_Outer_Width1: 223.944,
              In_Outer_Width2: 223.853,
              In_Internal_Width: 216.175,
              In_Thickness: 4.472,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 08:42:30",
              Tp_Result: "OK",
              Tp_JIG: 27.5,
              Tp_JIG_MIN: 26,
              Tp_JIG_MAX: 29.6,
              Tp_WJIG: 59.2,
              Tp_WJIG_MIN: 51.3,
              Tp_WJIG_MAX: 60.7,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 32,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0084",
              In_InsertTime: "2023-01-05 07:58:17",
              In_Result: "OK",
              In_Outer_Width1: 223.945,
              In_Outer_Width2: 223.925,
              In_Internal_Width: 216.203,
              In_Thickness: 4.468,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 08:43:24",
              Tp_Result: "OK",
              Tp_JIG: 32,
              Tp_JIG_MIN: 30.5,
              Tp_JIG_MAX: 34.2,
              Tp_WJIG: 42.9,
              Tp_WJIG_MIN: 36.2,
              Tp_WJIG_MAX: 44.9,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 23,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0085",
              In_InsertTime: "2023-01-05 07:56:24",
              In_Result: "OK",
              In_Outer_Width1: 223.957,
              In_Outer_Width2: 223.741,
              In_Internal_Width: 216.191,
              In_Thickness: 4.463,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:13:46",
              Tp_Result: "OK",
              Tp_JIG: 21.3,
              Tp_JIG_MIN: 20,
              Tp_JIG_MAX: 23.6,
              Tp_WJIG: 46.5,
              Tp_WJIG_MIN: 40.9,
              Tp_WJIG_MAX: 48.4,
              Tp_Vibration: 852,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 4,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0107",
              In_InsertTime: "2023-01-05 07:52:23",
              In_Result: "OK",
              In_Outer_Width1: 223.818,
              In_Outer_Width2: 223.98,
              In_Internal_Width: 216.165,
              In_Thickness: 4.464,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 08:35:46",
              Tp_Result: "OK",
              Tp_JIG: 53.8,
              Tp_JIG_MIN: 52.1,
              Tp_JIG_MAX: 54.4,
              Tp_WJIG: 51.8,
              Tp_WJIG_MIN: 43.4,
              Tp_WJIG_MAX: 55,
              Tp_Vibration: 884,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 18,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0108",
              In_InsertTime: "2023-01-05 07:54:23",
              In_Result: "OK",
              In_Outer_Width1: 224.068,
              In_Outer_Width2: 223.847,
              In_Internal_Width: 216.278,
              In_Thickness: 4.464,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 08:19:53",
              Tp_Result: "OK",
              Tp_JIG: 26.7,
              Tp_JIG_MIN: 25.2,
              Tp_JIG_MAX: 29.2,
              Tp_WJIG: 36.9,
              Tp_WJIG_MIN: 29.9,
              Tp_WJIG_MAX: 37.3,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 11,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0117",
              In_InsertTime: "2023-01-05 07:53:26",
              In_Result: "OK",
              In_Outer_Width1: 223.879,
              In_Outer_Width2: 223.962,
              In_Internal_Width: 216.177,
              In_Thickness: 4.462,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 08:18:51",
              Tp_Result: "OK",
              Tp_JIG: 12.5,
              Tp_JIG_MIN: 11.3,
              Tp_JIG_MAX: 14,
              Tp_WJIG: 40,
              Tp_WJIG_MIN: 30.6,
              Tp_WJIG_MAX: 42.1,
              Tp_Vibration: 887,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 19,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0120",
              In_InsertTime: "2023-01-05 07:55:44",
              In_Result: "OK",
              In_Outer_Width1: 223.838,
              In_Outer_Width2: 223.922,
              In_Internal_Width: 216.183,
              In_Thickness: 4.454,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 08:12:37",
              Tp_Result: "OK",
              Tp_JIG: 15.2,
              Tp_JIG_MIN: 13.7,
              Tp_JIG_MAX: 17.3,
              Tp_WJIG: 34.6,
              Tp_WJIG_MIN: 31.7,
              Tp_WJIG_MAX: 37.8,
              Tp_Vibration: 883,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 28,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0122",
              In_InsertTime: "2023-01-05 07:57:28",
              In_Result: "OK",
              In_Outer_Width1: 223.841,
              In_Outer_Width2: 223.924,
              In_Internal_Width: 216.196,
              In_Thickness: 4.466,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 08:35:39",
              Tp_Result: "OK",
              Tp_JIG: 32.3,
              Tp_JIG_MIN: 31.8,
              Tp_JIG_MAX: 35.8,
              Tp_WJIG: 46.1,
              Tp_WJIG_MIN: 36.4,
              Tp_WJIG_MAX: 48.5,
              Tp_Vibration: 887,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 17,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0125",
              In_InsertTime: "2023-01-05 07:54:14",
              In_Result: "OK",
              In_Outer_Width1: 223.86,
              In_Outer_Width2: 224.023,
              In_Internal_Width: 216.193,
              In_Thickness: 4.455,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 08:45:54",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 33.7,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 48.9,
              Tp_WJIG_MIN: 38.4,
              Tp_WJIG_MAX: 51.1,
              Tp_Vibration: 893,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 14,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0130",
              In_InsertTime: "2023-01-05 07:53:51",
              In_Result: "OK",
              In_Outer_Width1: 223.799,
              In_Outer_Width2: 223.78,
              In_Internal_Width: 216.216,
              In_Thickness: 4.461,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 08:37:44",
              Tp_Result: "OK",
              Tp_JIG: 31.9,
              Tp_JIG_MIN: 30.5,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 34.6,
              Tp_WJIG_MIN: 30.2,
              Tp_WJIG_MAX: 37.6,
              Tp_Vibration: 800,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 35,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0131",
              In_InsertTime: "2023-01-05 07:59:07",
              In_Result: "OK",
              In_Outer_Width1: 223.763,
              In_Outer_Width2: 223.823,
              In_Internal_Width: 216.202,
              In_Thickness: 4.466,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 08:51:36",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 33.5,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 48.7,
              Tp_WJIG_MIN: 38.4,
              Tp_WJIG_MAX: 50.8,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 9,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0133",
              In_InsertTime: "2023-01-05 07:53:10",
              In_Result: "OK",
              In_Outer_Width1: 224.283,
              In_Outer_Width2: 223.896,
              In_Internal_Width: 216.318,
              In_Thickness: 4.505,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 08:36:52",
              Tp_Result: "OK",
              Tp_JIG: 27.7,
              Tp_JIG_MIN: 25.9,
              Tp_JIG_MAX: 29.7,
              Tp_WJIG: 44.2,
              Tp_WJIG_MIN: 35.4,
              Tp_WJIG_MAX: 45.6,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 13,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0134",
              In_InsertTime: "2023-01-05 07:53:42",
              In_Result: "OK",
              In_Outer_Width1: 224.186,
              In_Outer_Width2: 224.007,
              In_Internal_Width: 216.336,
              In_Thickness: 4.495,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 08:43:35",
              Tp_Result: "OK",
              Tp_JIG: 25,
              Tp_JIG_MIN: 23.2,
              Tp_JIG_MAX: 26.8,
              Tp_WJIG: 45.9,
              Tp_WJIG_MIN: 35,
              Tp_WJIG_MAX: 45.9,
              Tp_Vibration: 850,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 7,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0136",
              In_InsertTime: "2023-01-05 07:52:52",
              In_Result: "OK",
              In_Outer_Width1: 223.861,
              In_Outer_Width2: 223.867,
              In_Internal_Width: 216.262,
              In_Thickness: 4.498,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 08:41:15",
              Tp_Result: "OK",
              Tp_JIG: 33.2,
              Tp_JIG_MIN: 31.2,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 43.3,
              Tp_WJIG_MIN: 32.6,
              Tp_WJIG_MAX: 43.3,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 16,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0138",
              In_InsertTime: "2023-01-05 07:54:07",
              In_Result: "OK",
              In_Outer_Width1: 223.837,
              In_Outer_Width2: 223.974,
              In_Internal_Width: 216.296,
              In_Thickness: 4.493,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 08:39:38",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.5,
              Tp_WJIG: 33.2,
              Tp_WJIG_MIN: 30.5,
              Tp_WJIG_MAX: 36.2,
              Tp_Vibration: 883,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 22,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0139",
              In_InsertTime: "2023-01-05 07:56:15",
              In_Result: "OK",
              In_Outer_Width1: 223.923,
              In_Outer_Width2: 223.9,
              In_Internal_Width: 216.309,
              In_Thickness: 4.488,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 08:41:28",
              Tp_Result: "OK",
              Tp_JIG: 53.5,
              Tp_JIG_MIN: 52,
              Tp_JIG_MAX: 54.4,
              Tp_WJIG: 55.7,
              Tp_WJIG_MIN: 46.5,
              Tp_WJIG_MAX: 58.4,
              Tp_Vibration: 881,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 2,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0143",
              In_InsertTime: "2023-01-05 07:52:07",
              In_Result: "OK",
              In_Outer_Width1: 224.163,
              In_Outer_Width2: 223.948,
              In_Internal_Width: 216.299,
              In_Thickness: 4.486,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 08:15:13",
              Tp_Result: "OK",
              Tp_JIG: 35.5,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 38.4,
              Tp_WJIG: 39.5,
              Tp_WJIG_MIN: 29.5,
              Tp_WJIG_MAX: 39.8,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1186,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0204",
              In_InsertTime: "2023-01-05 15:29:29",
              In_Result: "OK",
              In_Outer_Width1: 224.153,
              In_Outer_Width2: 224.141,
              In_Internal_Width: 216.239,
              In_Thickness: 4.553,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 16:07:24",
              Tp_Result: "OK",
              Tp_JIG: 32.2,
              Tp_JIG_MIN: 32.2,
              Tp_JIG_MAX: 35.6,
              Tp_WJIG: 33.5,
              Tp_WJIG_MIN: 25.2,
              Tp_WJIG_MAX: 36.3,
              Tp_Vibration: 872,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 31,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0206",
              In_InsertTime: "2023-01-05 07:58:05",
              In_Result: "OK",
              In_Outer_Width1: 224.052,
              In_Outer_Width2: 224.168,
              In_Internal_Width: 216.242,
              In_Thickness: 4.525,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 08:17:13",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 33.6,
              Tp_JIG_MAX: 37.3,
              Tp_WJIG: 28.7,
              Tp_WJIG_MIN: 24.6,
              Tp_WJIG_MAX: 29.5,
              Tp_Vibration: 851,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 33,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0212",
              In_InsertTime: "2023-01-05 07:58:42",
              In_Result: "OK",
              In_Outer_Width1: 224.058,
              In_Outer_Width2: 224.073,
              In_Internal_Width: 216.237,
              In_Thickness: 4.52,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 08:49:16",
              Tp_Result: "OK",
              Tp_JIG: 25,
              Tp_JIG_MIN: 23.6,
              Tp_JIG_MAX: 26.9,
              Tp_WJIG: 42,
              Tp_WJIG_MIN: 32.7,
              Tp_WJIG_MAX: 42.7,
              Tp_Vibration: 849,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 550,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0220",
              In_InsertTime: "2023-01-05 10:53:22",
              In_Result: "OK",
              In_Outer_Width1: 223.956,
              In_Outer_Width2: 224.125,
              In_Internal_Width: 216.241,
              In_Thickness: 4.534,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 11:43:45",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 36.1,
              Tp_WJIG: 35.4,
              Tp_WJIG_MIN: 28.1,
              Tp_WJIG_MAX: 37.1,
              Tp_Vibration: 851,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 34,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0221",
              In_InsertTime: "2023-01-05 07:58:59",
              In_Result: "OK",
              In_Outer_Width1: 223.932,
              In_Outer_Width2: 224.038,
              In_Internal_Width: 216.223,
              In_Thickness: 4.537,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 08:45:13",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.8,
              Tp_WJIG: 42.5,
              Tp_WJIG_MIN: 39,
              Tp_WJIG_MAX: 44.7,
              Tp_Vibration: 875,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 510,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0251",
              In_InsertTime: "2023-01-05 10:41:06",
              In_Result: "OK",
              In_Outer_Width1: 223.878,
              In_Outer_Width2: 224.165,
              In_Internal_Width: 216.276,
              In_Thickness: 4.488,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 11:29:02",
              Tp_Result: "OK",
              Tp_JIG: 52.3,
              Tp_JIG_MIN: 51,
              Tp_JIG_MAX: 53.9,
              Tp_WJIG: 27.4,
              Tp_WJIG_MIN: 21.7,
              Tp_WJIG_MAX: 27.6,
              Tp_Vibration: 891,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 142,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0254",
              In_InsertTime: "2023-01-05 08:32:57",
              In_Result: "OK",
              In_Outer_Width1: 223.965,
              In_Outer_Width2: 224.227,
              In_Internal_Width: 216.303,
              In_Thickness: 4.48,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 09:18:41",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.7,
              Tp_WJIG: 37.8,
              Tp_WJIG_MIN: 32.7,
              Tp_WJIG_MAX: 38.6,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 138,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0256",
              In_InsertTime: "2023-01-05 08:32:05",
              In_Result: "OK",
              In_Outer_Width1: 224.284,
              In_Outer_Width2: 224.241,
              In_Internal_Width: 216.367,
              In_Thickness: 4.491,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 09:17:22",
              Tp_Result: "OK",
              Tp_JIG: 31.6,
              Tp_JIG_MIN: 30.4,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 43.6,
              Tp_WJIG_MIN: 34.3,
              Tp_WJIG_MAX: 44.3,
              Tp_Vibration: 803,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 633,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0258",
              In_InsertTime: "2023-01-05 11:21:04",
              In_Result: "OK",
              In_Outer_Width1: 223.889,
              In_Outer_Width2: 223.983,
              In_Internal_Width: 216.312,
              In_Thickness: 4.497,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 13:13:22",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 36.4,
              Tp_WJIG: 50,
              Tp_WJIG_MIN: 38.8,
              Tp_WJIG_MAX: 51.7,
              Tp_Vibration: 842,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 584,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0259",
              In_InsertTime: "2023-01-05 11:04:44",
              In_Result: "OK",
              In_Outer_Width1: 223.88,
              In_Outer_Width2: 223.953,
              In_Internal_Width: 216.315,
              In_Thickness: 4.492,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 12:56:20",
              Tp_Result: "OK",
              Tp_JIG: 34.6,
              Tp_JIG_MIN: 33.1,
              Tp_JIG_MAX: 35.8,
              Tp_WJIG: 48.6,
              Tp_WJIG_MIN: 34.9,
              Tp_WJIG_MAX: 49.3,
              Tp_Vibration: 881,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 137,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0260",
              In_InsertTime: "2023-01-05 08:31:57",
              In_Result: "OK",
              In_Outer_Width1: 224.128,
              In_Outer_Width2: 224.124,
              In_Internal_Width: 216.328,
              In_Thickness: 4.489,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 08:57:38",
              Tp_Result: "OK",
              Tp_JIG: 46.2,
              Tp_JIG_MIN: 45.1,
              Tp_JIG_MAX: 49.2,
              Tp_WJIG: 34.8,
              Tp_WJIG_MIN: 27.3,
              Tp_WJIG_MAX: 35,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 632,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0261",
              In_InsertTime: "2023-01-05 11:20:55",
              In_Result: "OK",
              In_Outer_Width1: 224.058,
              In_Outer_Width2: 224.028,
              In_Internal_Width: 216.347,
              In_Thickness: 4.493,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 13:13:02",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.3,
              Tp_WJIG: 36.2,
              Tp_WJIG_MIN: 32.7,
              Tp_WJIG_MAX: 38.9,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 553,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0262",
              In_InsertTime: "2023-01-05 10:55:37",
              In_Result: "OK",
              In_Outer_Width1: 224.038,
              In_Outer_Width2: 224.188,
              In_Internal_Width: 216.379,
              In_Thickness: 4.489,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 11:47:07",
              Tp_Result: "OK",
              Tp_JIG: 37.1,
              Tp_JIG_MIN: 35.9,
              Tp_JIG_MAX: 39.1,
              Tp_WJIG: 50.6,
              Tp_WJIG_MIN: 39.3,
              Tp_WJIG_MAX: 50.7,
              Tp_Vibration: 896,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 149,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0263",
              In_InsertTime: "2023-01-05 08:36:37",
              In_Result: "OK",
              In_Outer_Width1: 224.186,
              In_Outer_Width2: 224.007,
              In_Internal_Width: 216.268,
              In_Thickness: 4.557,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:52:54",
              Tp_Result: "OK",
              Tp_JIG: 21.2,
              Tp_JIG_MIN: 19.9,
              Tp_JIG_MAX: 23.9,
              Tp_WJIG: 38,
              Tp_WJIG_MIN: 33.6,
              Tp_WJIG_MAX: 40,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 589,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0264",
              In_InsertTime: "2023-01-05 11:07:24",
              In_Result: "OK",
              In_Outer_Width1: 224.029,
              In_Outer_Width2: 224.127,
              In_Internal_Width: 216.269,
              In_Thickness: 4.556,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 11:36:01",
              Tp_Result: "OK",
              Tp_JIG: 32.6,
              Tp_JIG_MIN: 31.4,
              Tp_JIG_MAX: 34.6,
              Tp_WJIG: 34.3,
              Tp_WJIG_MIN: 25.9,
              Tp_WJIG_MAX: 34.4,
              Tp_Vibration: 884,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 151,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0266",
              In_InsertTime: "2023-01-05 08:36:54",
              In_Result: "OK",
              In_Outer_Width1: 223.989,
              In_Outer_Width2: 224.035,
              In_Internal_Width: 216.236,
              In_Thickness: 4.545,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 09:22:07",
              Tp_Result: "OK",
              Tp_JIG: 28.2,
              Tp_JIG_MIN: 26.7,
              Tp_JIG_MAX: 30.1,
              Tp_WJIG: 31,
              Tp_WJIG_MIN: 23.4,
              Tp_WJIG_MAX: 31.2,
              Tp_Vibration: 871,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 407,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0267",
              In_InsertTime: "2023-01-05 10:08:05",
              In_Result: "OK",
              In_Outer_Width1: 224.117,
              In_Outer_Width2: 224.083,
              In_Internal_Width: 216.335,
              In_Thickness: 4.484,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 10:34:44",
              Tp_Result: "OK",
              Tp_JIG: 26.6,
              Tp_JIG_MIN: 25.2,
              Tp_JIG_MAX: 29.4,
              Tp_WJIG: 30.4,
              Tp_WJIG_MIN: 23.6,
              Tp_WJIG_MAX: 30.4,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 136,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0271",
              In_InsertTime: "2023-01-05 08:31:26",
              In_Result: "OK",
              In_Outer_Width1: 224.064,
              In_Outer_Width2: 224.152,
              In_Internal_Width: 216.239,
              In_Thickness: 4.536,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 08:50:35",
              Tp_Result: "OK",
              Tp_JIG: 35.6,
              Tp_JIG_MIN: 34.1,
              Tp_JIG_MAX: 37.5,
              Tp_WJIG: 28.8,
              Tp_WJIG_MIN: 25.3,
              Tp_WJIG_MAX: 31.3,
              Tp_Vibration: 781,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 135,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0273",
              In_InsertTime: "2023-01-05 08:31:18",
              In_Result: "OK",
              In_Outer_Width1: 223.847,
              In_Outer_Width2: 223.905,
              In_Internal_Width: 216.307,
              In_Thickness: 4.478,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 09:22:04",
              Tp_Result: "OK",
              Tp_JIG: 37.4,
              Tp_JIG_MIN: 36.2,
              Tp_JIG_MAX: 39.3,
              Tp_WJIG: 37,
              Tp_WJIG_MIN: 27.5,
              Tp_WJIG_MAX: 37.7,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 245,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0274",
              In_InsertTime: "2023-01-05 09:06:01",
              In_Result: "OK",
              In_Outer_Width1: 223.873,
              In_Outer_Width2: 223.933,
              In_Internal_Width: 216.302,
              In_Thickness: 4.482,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 10:04:22",
              Tp_Result: "OK",
              Tp_JIG: 53,
              Tp_JIG_MIN: 51.5,
              Tp_JIG_MAX: 53.9,
              Tp_WJIG: 55.8,
              Tp_WJIG_MIN: 42.6,
              Tp_WJIG_MAX: 56.9,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 244,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0275",
              In_InsertTime: "2023-01-05 09:05:48",
              In_Result: "OK",
              In_Outer_Width1: 223.832,
              In_Outer_Width2: 223.864,
              In_Internal_Width: 216.292,
              In_Thickness: 4.478,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 09:33:03",
              Tp_Result: "OK",
              Tp_JIG: 12.4,
              Tp_JIG_MIN: 11.1,
              Tp_JIG_MAX: 13.9,
              Tp_WJIG: 36.2,
              Tp_WJIG_MIN: 26.4,
              Tp_WJIG_MAX: 37.8,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 547,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0276",
              In_InsertTime: "2023-01-05 10:52:47",
              In_Result: "OK",
              In_Outer_Width1: 224.011,
              In_Outer_Width2: 224.128,
              In_Internal_Width: 216.294,
              In_Thickness: 4.483,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 11:30:41",
              Tp_Result: "OK",
              Tp_JIG: 31.4,
              Tp_JIG_MIN: 31.4,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 40.5,
              Tp_WJIG_MIN: 31.9,
              Tp_WJIG_MAX: 42.3,
              Tp_Vibration: 852,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 200,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0277",
              In_InsertTime: "2023-01-05 08:51:46",
              In_Result: "OK",
              In_Outer_Width1: 223.858,
              In_Outer_Width2: 223.975,
              In_Internal_Width: 216.307,
              In_Thickness: 4.473,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 09:37:57",
              Tp_Result: "OK",
              Tp_JIG: 53,
              Tp_JIG_MIN: 51.6,
              Tp_JIG_MAX: 54.1,
              Tp_WJIG: 52.4,
              Tp_WJIG_MIN: 41,
              Tp_WJIG_MAX: 53.6,
              Tp_Vibration: 872,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 154,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0282",
              In_InsertTime: "2023-01-05 08:37:27",
              In_Result: "OK",
              In_Outer_Width1: 223.992,
              In_Outer_Width2: 224.047,
              In_Internal_Width: 216.262,
              In_Thickness: 4.535,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 09:27:43",
              Tp_Result: "OK",
              Tp_JIG: 37.3,
              Tp_JIG_MIN: 35.8,
              Tp_JIG_MAX: 39.2,
              Tp_WJIG: 43.2,
              Tp_WJIG_MIN: 33.7,
              Tp_WJIG_MAX: 43.8,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 500,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0283",
              In_InsertTime: "2023-01-05 10:38:04",
              In_Result: "OK",
              In_Outer_Width1: 223.941,
              In_Outer_Width2: 224.104,
              In_Internal_Width: 216.256,
              In_Thickness: 4.527,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 11:28:13",
              Tp_Result: "OK",
              Tp_JIG: 33.1,
              Tp_JIG_MIN: 31.5,
              Tp_JIG_MAX: 33.7,
              Tp_WJIG: 32.8,
              Tp_WJIG_MIN: 25.2,
              Tp_WJIG_MAX: 32.8,
              Tp_Vibration: 894,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 406,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0284",
              In_InsertTime: "2023-01-05 10:07:53",
              In_Result: "OK",
              In_Outer_Width1: 223.97,
              In_Outer_Width2: 224.158,
              In_Internal_Width: 216.262,
              In_Thickness: 4.525,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 10:58:22",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 46.6,
              Tp_WJIG_MIN: 35.6,
              Tp_WJIG_MAX: 47.8,
              Tp_Vibration: 852,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 544,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0285",
              In_InsertTime: "2023-01-05 10:52:05",
              In_Result: "OK",
              In_Outer_Width1: 223.861,
              In_Outer_Width2: 224.044,
              In_Internal_Width: 216.246,
              In_Thickness: 4.528,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 11:42:46",
              Tp_Result: "OK",
              Tp_JIG: 24.9,
              Tp_JIG_MIN: 23.6,
              Tp_JIG_MAX: 26.3,
              Tp_WJIG: 35.5,
              Tp_WJIG_MIN: 28.3,
              Tp_WJIG_MAX: 36.4,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 502,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0286",
              In_InsertTime: "2023-01-05 10:39:05",
              In_Result: "OK",
              In_Outer_Width1: 223.976,
              In_Outer_Width2: 224.022,
              In_Internal_Width: 216.272,
              In_Thickness: 4.529,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 11:30:08",
              Tp_Result: "OK",
              Tp_JIG: 37.3,
              Tp_JIG_MIN: 36,
              Tp_JIG_MAX: 39,
              Tp_WJIG: 39.2,
              Tp_WJIG_MIN: 30.5,
              Tp_WJIG_MAX: 40.7,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 543,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0287",
              In_InsertTime: "2023-01-05 10:51:56",
              In_Result: "OK",
              In_Outer_Width1: 223.93,
              In_Outer_Width2: 223.947,
              In_Internal_Width: 216.262,
              In_Thickness: 4.524,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 11:20:08",
              Tp_Result: "OK",
              Tp_JIG: 26.9,
              Tp_JIG_MIN: 25.2,
              Tp_JIG_MAX: 29.6,
              Tp_WJIG: 24.1,
              Tp_WJIG_MIN: 19,
              Tp_WJIG_MAX: 24.3,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 592,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0289",
              In_InsertTime: "2023-01-05 11:08:33",
              In_Result: "OK",
              In_Outer_Width1: 223.95,
              In_Outer_Width2: 224.044,
              In_Internal_Width: 216.265,
              In_Thickness: 4.525,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 11:25:22",
              Tp_Result: "OK",
              Tp_JIG: 15.4,
              Tp_JIG_MIN: 14.1,
              Tp_JIG_MAX: 17.3,
              Tp_WJIG: 33.4,
              Tp_WJIG_MIN: 29.4,
              Tp_WJIG_MAX: 34.6,
              Tp_Vibration: 790,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 580,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0291",
              In_InsertTime: "2023-01-05 11:03:58",
              In_Result: "OK",
              In_Outer_Width1: 224.094,
              In_Outer_Width2: 224.01,
              In_Internal_Width: 216.307,
              In_Thickness: 4.516,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 12:55:17",
              Tp_Result: "OK",
              Tp_JIG: 31.1,
              Tp_JIG_MIN: 29.4,
              Tp_JIG_MAX: 32.3,
              Tp_WJIG: 40.7,
              Tp_WJIG_MIN: 31.6,
              Tp_WJIG_MAX: 41.4,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 201,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0295",
              In_InsertTime: "2023-01-05 08:52:18",
              In_Result: "OK",
              In_Outer_Width1: 223.792,
              In_Outer_Width2: 223.876,
              In_Internal_Width: 216.259,
              In_Thickness: 4.512,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 09:43:23",
              Tp_Result: "OK",
              Tp_JIG: 33.3,
              Tp_JIG_MIN: 31.1,
              Tp_JIG_MAX: 33.8,
              Tp_WJIG: 36.1,
              Tp_WJIG_MIN: 28.5,
              Tp_WJIG_MAX: 37,
              Tp_Vibration: 896,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 408,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0296",
              In_InsertTime: "2023-01-05 10:08:16",
              In_Result: "OK",
              In_Outer_Width1: 223.934,
              In_Outer_Width2: 224.012,
              In_Internal_Width: 216.317,
              In_Thickness: 4.479,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 11:00:12",
              Tp_Result: "OK",
              Tp_JIG: 33.2,
              Tp_JIG_MIN: 31.4,
              Tp_JIG_MAX: 33.7,
              Tp_WJIG: 33.8,
              Tp_WJIG_MIN: 27,
              Tp_WJIG_MAX: 34.5,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 634,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0297",
              In_InsertTime: "2023-01-05 11:21:31",
              In_Result: "OK",
              In_Outer_Width1: 223.912,
              In_Outer_Width2: 223.866,
              In_Internal_Width: 216.295,
              In_Thickness: 4.479,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 11:51:20",
              Tp_Result: "OK",
              Tp_JIG: 12.4,
              Tp_JIG_MIN: 10.9,
              Tp_JIG_MAX: 13.7,
              Tp_WJIG: 27.8,
              Tp_WJIG_MIN: 21.7,
              Tp_WJIG_MAX: 29.7,
              Tp_Vibration: 880,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 586,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0299",
              In_InsertTime: "2023-01-05 11:06:15",
              In_Result: "OK",
              In_Outer_Width1: 223.782,
              In_Outer_Width2: 223.771,
              In_Internal_Width: 216.281,
              In_Thickness: 4.476,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 12:57:20",
              Tp_Result: "OK",
              Tp_JIG: 32.5,
              Tp_JIG_MIN: 30.4,
              Tp_JIG_MAX: 33,
              Tp_WJIG: 41.7,
              Tp_WJIG_MIN: 32.5,
              Tp_WJIG_MAX: 41.8,
              Tp_Vibration: 882,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 583,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0306",
              In_InsertTime: "2023-01-05 11:04:29",
              In_Result: "OK",
              In_Outer_Width1: 223.876,
              In_Outer_Width2: 223.94,
              In_Internal_Width: 216.27,
              In_Thickness: 4.502,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 11:23:25",
              Tp_Result: "OK",
              Tp_JIG: 34.1,
              Tp_JIG_MIN: 32.4,
              Tp_JIG_MAX: 34.5,
              Tp_WJIG: 22.6,
              Tp_WJIG_MIN: 18.9,
              Tp_WJIG_MAX: 25.6,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 498,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0321",
              In_InsertTime: "2023-01-05 10:36:39",
              In_Result: "OK",
              In_Outer_Width1: 223.933,
              In_Outer_Width2: 224.134,
              In_Internal_Width: 216.309,
              In_Thickness: 4.408,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 11:26:44",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 34,
              Tp_JIG_MAX: 36.3,
              Tp_WJIG: 38.2,
              Tp_WJIG_MIN: 28.8,
              Tp_WJIG_MAX: 39.2,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 155,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0332",
              In_InsertTime: "2023-01-05 08:37:43",
              In_Result: "OK",
              In_Outer_Width1: 224.148,
              In_Outer_Width2: 224.184,
              In_Internal_Width: 216.257,
              In_Thickness: 4.405,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 08:56:08",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 37.5,
              Tp_WJIG: 36.6,
              Tp_WJIG_MIN: 31.9,
              Tp_WJIG_MAX: 38.4,
              Tp_Vibration: 783,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 247,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0336",
              In_InsertTime: "2023-01-05 09:06:20",
              In_Result: "OK",
              In_Outer_Width1: 224.163,
              In_Outer_Width2: 224.036,
              In_Internal_Width: 216.329,
              In_Thickness: 4.413,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 09:33:59",
              Tp_Result: "OK",
              Tp_JIG: 32.3,
              Tp_JIG_MIN: 31.2,
              Tp_JIG_MAX: 35.3,
              Tp_WJIG: 41.8,
              Tp_WJIG_MIN: 32,
              Tp_WJIG_MAX: 41.8,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 164,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0337",
              In_InsertTime: "2023-01-05 08:40:32",
              In_Result: "OK",
              In_Outer_Width1: 223.909,
              In_Outer_Width2: 224.009,
              In_Internal_Width: 216.251,
              In_Thickness: 4.412,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 09:26:39",
              Tp_Result: "OK",
              Tp_JIG: 53.2,
              Tp_JIG_MIN: 51.8,
              Tp_JIG_MAX: 54.1,
              Tp_WJIG: 53.1,
              Tp_WJIG_MIN: 43.8,
              Tp_WJIG_MAX: 55.2,
              Tp_Vibration: 879,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 141,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0340",
              In_InsertTime: "2023-01-05 08:32:33",
              In_Result: "OK",
              In_Outer_Width1: 223.878,
              In_Outer_Width2: 224.154,
              In_Internal_Width: 216.231,
              In_Thickness: 4.424,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 08:51:33",
              Tp_Result: "OK",
              Tp_JIG: 15.2,
              Tp_JIG_MIN: 13.6,
              Tp_JIG_MAX: 17.4,
              Tp_WJIG: 36.7,
              Tp_WJIG_MIN: 30.3,
              Tp_WJIG_MAX: 37.9,
              Tp_Vibration: 874,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 635,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0341",
              In_InsertTime: "2023-01-05 11:21:41",
              In_Result: "OK",
              In_Outer_Width1: 223.944,
              In_Outer_Width2: 223.877,
              In_Internal_Width: 216.283,
              In_Thickness: 4.461,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 13:14:23",
              Tp_Result: "OK",
              Tp_JIG: 33,
              Tp_JIG_MIN: 31,
              Tp_JIG_MAX: 33.4,
              Tp_WJIG: 35.3,
              Tp_WJIG_MIN: 26.3,
              Tp_WJIG_MAX: 35.3,
              Tp_Vibration: 828,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 512,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0343",
              In_InsertTime: "2023-01-05 10:41:26",
              In_Result: "OK",
              In_Outer_Width1: 224.312,
              In_Outer_Width2: 224.335,
              In_Internal_Width: 216.33,
              In_Thickness: 4.539,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 11:30:03",
              Tp_Result: "OK",
              Tp_JIG: 28.3,
              Tp_JIG_MIN: 26.7,
              Tp_JIG_MAX: 30.4,
              Tp_WJIG: 43,
              Tp_WJIG_MIN: 34.7,
              Tp_WJIG_MAX: 44.1,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 590,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0344",
              In_InsertTime: "2023-01-05 11:07:34",
              In_Result: "OK",
              In_Outer_Width1: 223.878,
              In_Outer_Width2: 224.169,
              In_Internal_Width: 216.278,
              In_Thickness: 4.432,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 11:43:16",
              Tp_Result: "OK",
              Tp_JIG: 33.7,
              Tp_JIG_MIN: 30.1,
              Tp_JIG_MAX: 34.9,
              Tp_WJIG: 41.3,
              Tp_WJIG_MIN: 34.5,
              Tp_WJIG_MAX: 43.1,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 143,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0346",
              In_InsertTime: "2023-01-05 08:33:12",
              In_Result: "OK",
              In_Outer_Width1: 224.21,
              In_Outer_Width2: 224.12,
              In_Internal_Width: 216.316,
              In_Thickness: 4.532,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 09:23:21",
              Tp_Result: "OK",
              Tp_JIG: 25.2,
              Tp_JIG_MIN: 23.3,
              Tp_JIG_MAX: 26.9,
              Tp_WJIG: 47,
              Tp_WJIG_MIN: 34.1,
              Tp_WJIG_MAX: 47,
              Tp_Vibration: 853,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 575,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0347",
              In_InsertTime: "2023-01-05 11:03:01",
              In_Result: "OK",
              In_Outer_Width1: 224.017,
              In_Outer_Width2: 224.263,
              In_Internal_Width: 216.292,
              In_Thickness: 4.53,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 11:19:48",
              Tp_Result: "OK",
              Tp_JIG: 14.9,
              Tp_JIG_MIN: 13.7,
              Tp_JIG_MAX: 16.5,
              Tp_WJIG: 33.2,
              Tp_WJIG_MIN: 30.9,
              Tp_WJIG_MAX: 35.8,
              Tp_Vibration: 792,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 403,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0348",
              In_InsertTime: "2023-01-05 10:07:05",
              In_Result: "OK",
              In_Outer_Width1: 224.045,
              In_Outer_Width2: 224.233,
              In_Internal_Width: 216.3,
              In_Thickness: 4.531,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 10:57:22",
              Tp_Result: "OK",
              Tp_JIG: 24.9,
              Tp_JIG_MIN: 23.3,
              Tp_JIG_MAX: 26.4,
              Tp_WJIG: 44,
              Tp_WJIG_MIN: 34.2,
              Tp_WJIG_MAX: 44.7,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 145,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0349",
              In_InsertTime: "2023-01-05 08:34:28",
              In_Result: "OK",
              In_Outer_Width1: 224.005,
              In_Outer_Width2: 224.091,
              In_Internal_Width: 216.264,
              In_Thickness: 4.526,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 09:25:26",
              Tp_Result: "OK",
              Tp_JIG: 34.9,
              Tp_JIG_MIN: 33.6,
              Tp_JIG_MAX: 36.3,
              Tp_WJIG: 34.8,
              Tp_WJIG_MIN: 26.6,
              Tp_WJIG_MAX: 35.5,
              Tp_Vibration: 872,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 156,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0350",
              In_InsertTime: "2023-01-05 08:37:52",
              In_Result: "OK",
              In_Outer_Width1: 224.019,
              In_Outer_Width2: 224.044,
              In_Internal_Width: 216.274,
              In_Thickness: 4.52,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 09:23:01",
              Tp_Result: "OK",
              Tp_JIG: 32,
              Tp_JIG_MIN: 30.6,
              Tp_JIG_MAX: 33.8,
              Tp_WJIG: 29.9,
              Tp_WJIG_MIN: 26,
              Tp_WJIG_MAX: 31.7,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 140,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0351",
              In_InsertTime: "2023-01-05 08:32:24",
              In_Result: "OK",
              In_Outer_Width1: 224.049,
              In_Outer_Width2: 224.194,
              In_Internal_Width: 216.287,
              In_Thickness: 4.52,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 09:10:16",
              Tp_Result: "OK",
              Tp_JIG: 32.8,
              Tp_JIG_MIN: 32.8,
              Tp_JIG_MAX: 36.4,
              Tp_WJIG: 39.6,
              Tp_WJIG_MIN: 28.9,
              Tp_WJIG_MAX: 40.8,
              Tp_Vibration: 895,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 133,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0352",
              In_InsertTime: "2023-01-05 08:31:01",
              In_Result: "OK",
              In_Outer_Width1: 223.95,
              In_Outer_Width2: 224.123,
              In_Internal_Width: 216.264,
              In_Thickness: 4.522,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 09:16:27",
              Tp_Result: "OK",
              Tp_JIG: 28.4,
              Tp_JIG_MIN: 26.9,
              Tp_JIG_MAX: 30.1,
              Tp_WJIG: 34.5,
              Tp_WJIG_MIN: 26.5,
              Tp_WJIG_MAX: 34.9,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 203,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0353",
              In_InsertTime: "2023-01-05 08:52:47",
              In_Result: "OK",
              In_Outer_Width1: 223.971,
              In_Outer_Width2: 223.995,
              In_Internal_Width: 216.263,
              In_Thickness: 4.521,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 09:09:43",
              Tp_Result: "OK",
              Tp_JIG: 21.6,
              Tp_JIG_MIN: 20.3,
              Tp_JIG_MAX: 24,
              Tp_WJIG: 25.2,
              Tp_WJIG_MIN: 21.4,
              Tp_WJIG_MAX: 27.3,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 153,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0354",
              In_InsertTime: "2023-01-05 08:37:15",
              In_Result: "OK",
              In_Outer_Width1: 223.901,
              In_Outer_Width2: 224.008,
              In_Internal_Width: 216.246,
              In_Thickness: 4.518,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 09:03:19",
              Tp_Result: "OK",
              Tp_JIG: 33,
              Tp_JIG_MIN: 31.9,
              Tp_JIG_MAX: 36,
              Tp_WJIG: 34.2,
              Tp_WJIG_MIN: 27.4,
              Tp_WJIG_MAX: 35,
              Tp_Vibration: 902,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 238,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0356",
              In_InsertTime: "2023-01-05 09:03:48",
              In_Result: "OK",
              In_Outer_Width1: 224.046,
              In_Outer_Width2: 224.156,
              In_Internal_Width: 216.299,
              In_Thickness: 4.519,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 09:29:21",
              Tp_Result: "OK",
              Tp_JIG: 35.7,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 38.2,
              Tp_WJIG: 40.4,
              Tp_WJIG_MIN: 33,
              Tp_WJIG_MAX: 41.7,
              Tp_Vibration: 789,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 134,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0357",
              In_InsertTime: "2023-01-05 08:31:10",
              In_Result: "OK",
              In_Outer_Width1: 224.096,
              In_Outer_Width2: 224.148,
              In_Internal_Width: 216.295,
              In_Thickness: 4.525,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 09:05:35",
              Tp_Result: "OK",
              Tp_JIG: 33.6,
              Tp_JIG_MIN: 30.6,
              Tp_JIG_MAX: 34.8,
              Tp_WJIG: 33.5,
              Tp_WJIG_MIN: 24.8,
              Tp_WJIG_MAX: 34,
              Tp_Vibration: 894,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 202,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0358",
              In_InsertTime: "2023-01-05 08:52:37",
              In_Result: "OK",
              In_Outer_Width1: 223.929,
              In_Outer_Width2: 224.145,
              In_Internal_Width: 216.276,
              In_Thickness: 4.51,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 09:17:46",
              Tp_Result: "OK",
              Tp_JIG: 35.3,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 38.2,
              Tp_WJIG: 36,
              Tp_WJIG_MIN: 29.8,
              Tp_WJIG_MAX: 37.9,
              Tp_Vibration: 788,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 237,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0359",
              In_InsertTime: "2023-01-05 09:03:25",
              In_Result: "OK",
              In_Outer_Width1: 223.919,
              In_Outer_Width2: 223.896,
              In_Internal_Width: 216.285,
              In_Thickness: 4.404,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 10:06:18",
              Tp_Result: "OK",
              Tp_JIG: 25.6,
              Tp_JIG_MIN: 23.8,
              Tp_JIG_MAX: 26.8,
              Tp_WJIG: 44.9,
              Tp_WJIG_MIN: 34.9,
              Tp_WJIG_MAX: 45.2,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 241,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0360",
              In_InsertTime: "2023-01-05 09:05:01",
              In_Result: "OK",
              In_Outer_Width1: 223.901,
              In_Outer_Width2: 223.866,
              In_Internal_Width: 216.317,
              In_Thickness: 4.408,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 09:50:16",
              Tp_Result: "OK",
              Tp_JIG: 28.5,
              Tp_JIG_MIN: 27.2,
              Tp_JIG_MAX: 30.5,
              Tp_WJIG: 23.9,
              Tp_WJIG_MIN: 17.6,
              Tp_WJIG_MAX: 25,
              Tp_Vibration: 882,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 246,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0361",
              In_InsertTime: "2023-01-05 09:06:10",
              In_Result: "OK",
              In_Outer_Width1: 223.981,
              In_Outer_Width2: 223.975,
              In_Internal_Width: 216.277,
              In_Thickness: 4.411,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 09:40:12",
              Tp_Result: "OK",
              Tp_JIG: 33.5,
              Tp_JIG_MIN: 30.8,
              Tp_JIG_MAX: 34.9,
              Tp_WJIG: 32.1,
              Tp_WJIG_MIN: 24.4,
              Tp_WJIG_MAX: 32.8,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 402,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0362",
              In_InsertTime: "2023-01-05 10:06:54",
              In_Result: "OK",
              In_Outer_Width1: 223.922,
              In_Outer_Width2: 223.845,
              In_Internal_Width: 216.261,
              In_Thickness: 4.493,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 10:33:36",
              Tp_Result: "OK",
              Tp_JIG: 32.5,
              Tp_JIG_MIN: 31.8,
              Tp_JIG_MAX: 34.8,
              Tp_WJIG: 30.4,
              Tp_WJIG_MIN: 24.5,
              Tp_WJIG_MAX: 31.6,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 199,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0364",
              In_InsertTime: "2023-01-05 08:51:38",
              In_Result: "OK",
              In_Outer_Width1: 223.822,
              In_Outer_Width2: 223.91,
              In_Internal_Width: 216.237,
              In_Thickness: 4.412,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 09:42:23",
              Tp_Result: "OK",
              Tp_JIG: 35.6,
              Tp_JIG_MIN: 34.4,
              Tp_JIG_MAX: 37.2,
              Tp_WJIG: 39.7,
              Tp_WJIG_MIN: 30.8,
              Tp_WJIG_MAX: 41.6,
              Tp_Vibration: 871,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 405,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0375",
              In_InsertTime: "2023-01-05 10:07:25",
              In_Result: "OK",
              In_Outer_Width1: 224.179,
              In_Outer_Width2: 223.959,
              In_Internal_Width: 216.286,
              In_Thickness: 4.497,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 10:52:28",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.7,
              Tp_WJIG: 30.9,
              Tp_WJIG_MIN: 27.8,
              Tp_WJIG_MAX: 33.5,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 499,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0377",
              In_InsertTime: "2023-01-05 10:36:49",
              In_Result: "OK",
              In_Outer_Width1: 223.824,
              In_Outer_Width2: 224.08,
              In_Internal_Width: 216.212,
              In_Thickness: 4.504,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 11:24:27",
              Tp_Result: "OK",
              Tp_JIG: 28.8,
              Tp_JIG_MIN: 27.6,
              Tp_JIG_MAX: 30.7,
              Tp_WJIG: 32.8,
              Tp_WJIG_MIN: 26.7,
              Tp_WJIG_MAX: 34.6,
              Tp_Vibration: 889,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 509,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0382",
              In_InsertTime: "2023-01-05 10:40:49",
              In_Result: "OK",
              In_Outer_Width1: 223.863,
              In_Outer_Width2: 224.004,
              In_Internal_Width: 216.231,
              In_Thickness: 4.535,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 11:31:26",
              Tp_Result: "OK",
              Tp_JIG: 24.8,
              Tp_JIG_MIN: 23,
              Tp_JIG_MAX: 26.2,
              Tp_WJIG: 40.3,
              Tp_WJIG_MIN: 32.4,
              Tp_WJIG_MAX: 41.5,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 503,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0383",
              In_InsertTime: "2023-01-05 10:39:20",
              In_Result: "OK",
              In_Outer_Width1: 224.343,
              In_Outer_Width2: 224.021,
              In_Internal_Width: 216.306,
              In_Thickness: 4.451,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 11:07:47",
              Tp_Result: "OK",
              Tp_JIG: 32.4,
              Tp_JIG_MIN: 31.3,
              Tp_JIG_MAX: 34.7,
              Tp_WJIG: 42.7,
              Tp_WJIG_MIN: 35.2,
              Tp_WJIG_MAX: 44,
              Tp_Vibration: 893,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 552,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0384",
              In_InsertTime: "2023-01-05 10:54:54",
              In_Result: "OK",
              In_Outer_Width1: 223.869,
              In_Outer_Width2: 224.083,
              In_Internal_Width: 216.246,
              In_Thickness: 4.456,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 11:45:00",
              Tp_Result: "OK",
              Tp_JIG: 33,
              Tp_JIG_MIN: 31.3,
              Tp_JIG_MAX: 33.4,
              Tp_WJIG: 32,
              Tp_WJIG_MIN: 26.5,
              Tp_WJIG_MAX: 32.7,
              Tp_Vibration: 901,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1195,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0385",
              In_InsertTime: "2023-01-05 15:32:51",
              In_Result: "OK",
              In_Outer_Width1: 223.924,
              In_Outer_Width2: 224.042,
              In_Internal_Width: 216.247,
              In_Thickness: 4.461,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 16:23:34",
              Tp_Result: "OK",
              Tp_JIG: 25.3,
              Tp_JIG_MIN: 23.9,
              Tp_JIG_MAX: 26.8,
              Tp_WJIG: 40.5,
              Tp_WJIG_MIN: 32.8,
              Tp_WJIG_MAX: 41.6,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 540,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0386",
              In_InsertTime: "2023-01-05 10:51:25",
              In_Result: "OK",
              In_Outer_Width1: 223.799,
              In_Outer_Width2: 224.07,
              In_Internal_Width: 216.222,
              In_Thickness: 4.459,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 11:37:41",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.9,
              Tp_WJIG: 39.3,
              Tp_WJIG_MIN: 36.8,
              Tp_WJIG_MAX: 42.8,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 542,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0387",
              In_InsertTime: "2023-01-05 10:51:46",
              In_Result: "OK",
              In_Outer_Width1: 223.871,
              In_Outer_Width2: 224.075,
              In_Internal_Width: 216.251,
              In_Thickness: 4.46,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 11:11:16",
              Tp_Result: "OK",
              Tp_JIG: 20.8,
              Tp_JIG_MIN: 19.8,
              Tp_JIG_MAX: 23.2,
              Tp_WJIG: 25.8,
              Tp_WJIG_MIN: 22.4,
              Tp_WJIG_MAX: 27.9,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 501,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0389",
              In_InsertTime: "2023-01-05 10:38:55",
              In_Result: "OK",
              In_Outer_Width1: 223.76,
              In_Outer_Width2: 224.118,
              In_Internal_Width: 216.255,
              In_Thickness: 4.466,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 11:25:25",
              Tp_Result: "OK",
              Tp_JIG: 31.3,
              Tp_JIG_MIN: 30.1,
              Tp_JIG_MAX: 33.3,
              Tp_WJIG: 39,
              Tp_WJIG_MIN: 33.4,
              Tp_WJIG_MAX: 41.1,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 505,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0399",
              In_InsertTime: "2023-01-05 10:40:06",
              In_Result: "OK",
              In_Outer_Width1: 224.055,
              In_Outer_Width2: 224.2,
              In_Internal_Width: 216.256,
              In_Thickness: 4.519,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 11:26:23",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 10.2,
              Tp_WJIG: 32.5,
              Tp_WJIG_MIN: 29.1,
              Tp_WJIG_MAX: 34.8,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 507,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0404",
              In_InsertTime: "2023-01-05 10:40:27",
              In_Result: "OK",
              In_Outer_Width1: 223.984,
              In_Outer_Width2: 224.149,
              In_Internal_Width: 216.228,
              In_Thickness: 4.533,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 10:57:28",
              Tp_Result: "OK",
              Tp_JIG: 15.4,
              Tp_JIG_MIN: 13.9,
              Tp_JIG_MAX: 17.3,
              Tp_WJIG: 30.8,
              Tp_WJIG_MIN: 28.1,
              Tp_WJIG_MAX: 32.6,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 576,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0406",
              In_InsertTime: "2023-01-05 11:03:11",
              In_Result: "OK",
              In_Outer_Width1: 223.86,
              In_Outer_Width2: 224.185,
              In_Internal_Width: 216.224,
              In_Thickness: 4.489,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 11:31:29",
              Tp_Result: "OK",
              Tp_JIG: 27.1,
              Tp_JIG_MIN: 25.5,
              Tp_JIG_MAX: 29.7,
              Tp_WJIG: 22.2,
              Tp_WJIG_MIN: 18.4,
              Tp_WJIG_MAX: 22.6,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 593,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0409",
              In_InsertTime: "2023-01-05 11:08:43",
              In_Result: "OK",
              In_Outer_Width1: 224.161,
              In_Outer_Width2: 223.94,
              In_Internal_Width: 216.26,
              In_Thickness: 4.469,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 11:37:09",
              Tp_Result: "OK",
              Tp_JIG: 27,
              Tp_JIG_MIN: 25.5,
              Tp_JIG_MAX: 30,
              Tp_WJIG: 24.9,
              Tp_WJIG_MIN: 20.7,
              Tp_WJIG_MAX: 25.1,
              Tp_Vibration: 882,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 581,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0410",
              In_InsertTime: "2023-01-05 11:04:07",
              In_Result: "OK",
              In_Outer_Width1: 224.089,
              In_Outer_Width2: 224.103,
              In_Internal_Width: 216.28,
              In_Thickness: 4.473,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 11:42:11",
              Tp_Result: "OK",
              Tp_JIG: 31.8,
              Tp_JIG_MIN: 31.8,
              Tp_JIG_MAX: 36.4,
              Tp_WJIG: 40.1,
              Tp_WJIG_MIN: 30.3,
              Tp_WJIG_MAX: 41.5,
              Tp_Vibration: 871,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 546,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0411",
              In_InsertTime: "2023-01-05 10:52:37",
              In_Result: "OK",
              In_Outer_Width1: 223.778,
              In_Outer_Width2: 224.018,
              In_Internal_Width: 216.212,
              In_Thickness: 4.473,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 11:41:21",
              Tp_Result: "OK",
              Tp_JIG: 28.4,
              Tp_JIG_MIN: 27.2,
              Tp_JIG_MAX: 30.5,
              Tp_WJIG: 41,
              Tp_WJIG_MIN: 34.9,
              Tp_WJIG_MAX: 43.4,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 545,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0412",
              In_InsertTime: "2023-01-05 10:52:23",
              In_Result: "OK",
              In_Outer_Width1: 224.11,
              In_Outer_Width2: 224.293,
              In_Internal_Width: 216.312,
              In_Thickness: 4.48,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 11:40:19",
              Tp_Result: "OK",
              Tp_JIG: 52,
              Tp_JIG_MIN: 50.7,
              Tp_JIG_MAX: 53.7,
              Tp_WJIG: 56.9,
              Tp_WJIG_MIN: 46.8,
              Tp_WJIG_MAX: 58.9,
              Tp_Vibration: 892,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 587,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0413",
              In_InsertTime: "2023-01-05 11:06:48",
              In_Result: "OK",
              In_Outer_Width1: 224.139,
              In_Outer_Width2: 224.214,
              In_Internal_Width: 216.283,
              In_Thickness: 4.473,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 13:00:00",
              Tp_Result: "OK",
              Tp_JIG: 68.9,
              Tp_JIG_MIN: 68.8,
              Tp_JIG_MAX: 71.6,
              Tp_WJIG: 47.8,
              Tp_WJIG_MIN: 39.3,
              Tp_WJIG_MAX: 49.3,
              Tp_Vibration: 897,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 579,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0414",
              In_InsertTime: "2023-01-05 11:03:46",
              In_Result: "OK",
              In_Outer_Width1: 223.943,
              In_Outer_Width2: 224.064,
              In_Internal_Width: 216.266,
              In_Thickness: 4.475,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 12:54:21",
              Tp_Result: "OK",
              Tp_JIG: 30.3,
              Tp_JIG_MIN: 28.9,
              Tp_JIG_MAX: 32.1,
              Tp_WJIG: 39.3,
              Tp_WJIG_MIN: 30.5,
              Tp_WJIG_MAX: 40.2,
              Tp_Vibration: 884,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 511,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0416",
              In_InsertTime: "2023-01-05 10:41:15",
              In_Result: "OK",
              In_Outer_Width1: 224.043,
              In_Outer_Width2: 224.213,
              In_Internal_Width: 216.256,
              In_Thickness: 4.48,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 11:00:06",
              Tp_Result: "OK",
              Tp_JIG: 20.7,
              Tp_JIG_MIN: 19.5,
              Tp_JIG_MAX: 23.1,
              Tp_WJIG: 30.8,
              Tp_WJIG_MIN: 27.2,
              Tp_WJIG_MAX: 33.8,
              Tp_Vibration: 874,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 549,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0419",
              In_InsertTime: "2023-01-05 10:53:06",
              In_Result: "OK",
              In_Outer_Width1: 223.913,
              In_Outer_Width2: 224.067,
              In_Internal_Width: 216.244,
              In_Thickness: 4.481,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 11:23:09",
              Tp_Result: "OK",
              Tp_JIG: 12.2,
              Tp_JIG_MIN: 11,
              Tp_JIG_MAX: 13.7,
              Tp_WJIG: 35.4,
              Tp_WJIG_MIN: 27.3,
              Tp_WJIG_MAX: 37.5,
              Tp_Vibration: 876,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 591,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0421",
              In_InsertTime: "2023-01-05 11:08:23",
              In_Result: "OK",
              In_Outer_Width1: 223.86,
              In_Outer_Width2: 224.06,
              In_Internal_Width: 216.201,
              In_Thickness: 4.475,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 12:57:14",
              Tp_Result: "OK",
              Tp_JIG: 51.9,
              Tp_JIG_MIN: 50.8,
              Tp_JIG_MAX: 53.3,
              Tp_WJIG: 49.6,
              Tp_WJIG_MIN: 38.9,
              Tp_WJIG_MAX: 51.4,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1204,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0459",
              In_InsertTime: "2023-01-05 15:35:06",
              In_Result: "OK",
              In_Outer_Width1: 223.995,
              In_Outer_Width2: 224.138,
              In_Internal_Width: 216.294,
              In_Thickness: 4.44,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 16:13:08",
              Tp_Result: "OK",
              Tp_JIG: 31.4,
              Tp_JIG_MIN: 31.3,
              Tp_JIG_MAX: 35.5,
              Tp_WJIG: 44,
              Tp_WJIG_MIN: 30.6,
              Tp_WJIG_MAX: 44.3,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 588,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0474",
              In_InsertTime: "2023-01-05 11:07:00",
              In_Result: "OK",
              In_Outer_Width1: 223.94,
              In_Outer_Width2: 224.001,
              In_Internal_Width: 216.244,
              In_Thickness: 4.426,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 11:24:24",
              Tp_Result: "OK",
              Tp_JIG: 34.9,
              Tp_JIG_MIN: 33.6,
              Tp_JIG_MAX: 36.9,
              Tp_WJIG: 33,
              Tp_WJIG_MIN: 29.2,
              Tp_WJIG_MAX: 34.9,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 158,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0478",
              In_InsertTime: "2023-01-05 08:38:08",
              In_Result: "OK",
              In_Outer_Width1: 223.803,
              In_Outer_Width2: 224.018,
              In_Internal_Width: 216.232,
              In_Thickness: 4.425,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 08:57:06",
              Tp_Result: "OK",
              Tp_JIG: 15.1,
              Tp_JIG_MIN: 14,
              Tp_JIG_MAX: 17.2,
              Tp_WJIG: 33.6,
              Tp_WJIG_MIN: 30.7,
              Tp_WJIG_MAX: 36.6,
              Tp_Vibration: 881,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 506,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0480",
              In_InsertTime: "2023-01-05 10:40:16",
              In_Result: "OK",
              In_Outer_Width1: 223.865,
              In_Outer_Width2: 223.941,
              In_Internal_Width: 216.246,
              In_Thickness: 4.425,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 11:14:30",
              Tp_Result: "OK",
              Tp_JIG: 32.4,
              Tp_JIG_MIN: 28.8,
              Tp_JIG_MAX: 34.6,
              Tp_WJIG: 39.2,
              Tp_WJIG_MIN: 29.6,
              Tp_WJIG_MAX: 39.4,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 242,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0482",
              In_InsertTime: "2023-01-05 09:05:09",
              In_Result: "OK",
              In_Outer_Width1: 223.906,
              In_Outer_Width2: 224.007,
              In_Internal_Width: 216.278,
              In_Thickness: 4.438,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 10:07:18",
              Tp_Result: "OK",
              Tp_JIG: 34.8,
              Tp_JIG_MIN: 33.3,
              Tp_JIG_MAX: 36,
              Tp_WJIG: 51.1,
              Tp_WJIG_MIN: 37.4,
              Tp_WJIG_MAX: 51.2,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 159,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0483",
              In_InsertTime: "2023-01-05 08:38:16",
              In_Result: "OK",
              In_Outer_Width1: 223.947,
              In_Outer_Width2: 223.912,
              In_Internal_Width: 216.263,
              In_Thickness: 4.436,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 09:04:21",
              Tp_Result: "OK",
              Tp_JIG: 12.4,
              Tp_JIG_MIN: 11,
              Tp_JIG_MAX: 13.8,
              Tp_WJIG: 30.1,
              Tp_WJIG_MIN: 21.3,
              Tp_WJIG_MAX: 31.6,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 577,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0484",
              In_InsertTime: "2023-01-05 11:03:19",
              In_Result: "OK",
              In_Outer_Width1: 224.012,
              In_Outer_Width2: 223.928,
              In_Internal_Width: 216.267,
              In_Thickness: 4.442,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 11:22:27",
              Tp_Result: "OK",
              Tp_JIG: 21.4,
              Tp_JIG_MIN: 20.2,
              Tp_JIG_MAX: 23.4,
              Tp_WJIG: 24,
              Tp_WJIG_MIN: 21,
              Tp_WJIG_MAX: 27.4,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 147,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0485",
              In_InsertTime: "2023-01-05 08:35:33",
              In_Result: "OK",
              In_Outer_Width1: 224.035,
              In_Outer_Width2: 224.016,
              In_Internal_Width: 216.272,
              In_Thickness: 4.443,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 09:26:33",
              Tp_Result: "OK",
              Tp_JIG: 33.2,
              Tp_JIG_MIN: 31.2,
              Tp_JIG_MAX: 33.8,
              Tp_WJIG: 38.3,
              Tp_WJIG_MIN: 31.3,
              Tp_WJIG_MAX: 39.5,
              Tp_Vibration: 884,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 548,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0486",
              In_InsertTime: "2023-01-05 10:52:56",
              In_Result: "OK",
              In_Outer_Width1: 223.943,
              In_Outer_Width2: 224.038,
              In_Internal_Width: 216.322,
              In_Thickness: 4.43,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 11:12:13",
              Tp_Result: "OK",
              Tp_JIG: 34.5,
              Tp_JIG_MIN: 32.7,
              Tp_JIG_MAX: 34.6,
              Tp_WJIG: 13.3,
              Tp_WJIG_MIN: 11.1,
              Tp_WJIG_MAX: 16,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 585,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0487",
              In_InsertTime: "2023-01-05 11:05:02",
              In_Result: "OK",
              In_Outer_Width1: 223.995,
              In_Outer_Width2: 224.039,
              In_Internal_Width: 216.282,
              In_Thickness: 4.444,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 12:56:14",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 10,
              Tp_WJIG: 34.4,
              Tp_WJIG_MIN: 29.4,
              Tp_WJIG_MAX: 37.1,
              Tp_Vibration: 887,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1232,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0490",
              In_InsertTime: "2023-01-05 15:50:04",
              In_Result: "OK",
              In_Outer_Width1: 223.822,
              In_Outer_Width2: 223.897,
              In_Internal_Width: 216.269,
              In_Thickness: 4.439,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 16:20:03",
              Tp_Result: "OK",
              Tp_JIG: 31,
              Tp_JIG_MIN: 28.4,
              Tp_JIG_MAX: 34.7,
              Tp_WJIG: 27.6,
              Tp_WJIG_MIN: 21.3,
              Tp_WJIG_MAX: 28.1,
              Tp_Vibration: 877,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 504,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0495",
              In_InsertTime: "2023-01-05 10:39:28",
              In_Result: "OK",
              In_Outer_Width1: 224.009,
              In_Outer_Width2: 224.007,
              In_Internal_Width: 216.236,
              In_Thickness: 4.501,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 10:56:27",
              Tp_Result: "OK",
              Tp_JIG: 35.3,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 37.1,
              Tp_WJIG: 32.3,
              Tp_WJIG_MIN: 28,
              Tp_WJIG_MAX: 34.5,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 582,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0496",
              In_InsertTime: "2023-01-05 11:04:19",
              In_Result: "OK",
              In_Outer_Width1: 223.968,
              In_Outer_Width2: 224.141,
              In_Internal_Width: 216.303,
              In_Thickness: 4.443,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 11:34:26",
              Tp_Result: "OK",
              Tp_JIG: 12.3,
              Tp_JIG_MIN: 11.2,
              Tp_JIG_MAX: 13.8,
              Tp_WJIG: 26.2,
              Tp_WJIG_MIN: 17.7,
              Tp_WJIG_MAX: 26.6,
              Tp_Vibration: 878,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 578,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0497",
              In_InsertTime: "2023-01-05 11:03:29",
              In_Result: "OK",
              In_Outer_Width1: 223.994,
              In_Outer_Width2: 224.018,
              In_Internal_Width: 216.223,
              In_Thickness: 4.512,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 12:55:21",
              Tp_Result: "OK",
              Tp_JIG: 25.1,
              Tp_JIG_MIN: 23.5,
              Tp_JIG_MAX: 26.6,
              Tp_WJIG: 50.6,
              Tp_WJIG_MIN: 38.3,
              Tp_WJIG_MAX: 50.6,
              Tp_Vibration: 878,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 508,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0501",
              In_InsertTime: "2023-01-05 10:40:40",
              In_Result: "OK",
              In_Outer_Width1: 224.104,
              In_Outer_Width2: 224.127,
              In_Internal_Width: 216.236,
              In_Thickness: 4.529,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 11:08:47",
              Tp_Result: "OK",
              Tp_JIG: 26.8,
              Tp_JIG_MIN: 24.9,
              Tp_JIG_MAX: 29.6,
              Tp_WJIG: 28.4,
              Tp_WJIG_MIN: 21.8,
              Tp_WJIG_MAX: 28.7,
              Tp_Vibration: 800,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1316,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0504",
              In_InsertTime: "2023-01-05 16:09:52",
              In_Result: "OK",
              In_Outer_Width1: 224.173,
              In_Outer_Width2: 223.976,
              In_Internal_Width: 216.2,
              In_Thickness: 4.507,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 16:37:02",
              Tp_Result: "OK",
              Tp_JIG: 35.6,
              Tp_JIG_MIN: 34.4,
              Tp_JIG_MAX: 38.4,
              Tp_WJIG: 38,
              Tp_WJIG_MIN: 31,
              Tp_WJIG_MAX: 38.5,
              Tp_Vibration: 858,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1197,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0506",
              In_InsertTime: "2023-01-05 15:33:34",
              In_Result: "OK",
              In_Outer_Width1: 224.027,
              In_Outer_Width2: 224.105,
              In_Internal_Width: 216.188,
              In_Thickness: 4.517,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 16:18:11",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 10.8,
              Tp_WJIG: 31.5,
              Tp_WJIG_MIN: 27.5,
              Tp_WJIG_MAX: 34,
              Tp_Vibration: 892,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1313,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0508",
              In_InsertTime: "2023-01-05 16:09:21",
              In_Result: "OK",
              In_Outer_Width1: 223.966,
              In_Outer_Width2: 224.178,
              In_Internal_Width: 216.189,
              In_Thickness: 4.533,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 17:32:02",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 43.8,
              Tp_WJIG_MIN: 32.7,
              Tp_WJIG_MAX: 45.2,
              Tp_Vibration: 895,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1307,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0519",
              In_InsertTime: "2023-01-05 16:07:50",
              In_Result: "OK",
              In_Outer_Width1: 223.838,
              In_Outer_Width2: 223.94,
              In_Internal_Width: 216.264,
              In_Thickness: 4.418,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 16:24:13",
              Tp_Result: "OK",
              Tp_JIG: 20.4,
              Tp_JIG_MIN: 19.3,
              Tp_JIG_MAX: 22.2,
              Tp_WJIG: 20.5,
              Tp_WJIG_MIN: 19.2,
              Tp_WJIG_MAX: 22.6,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1172,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0524",
              In_InsertTime: "2023-01-05 15:24:32",
              In_Result: "OK",
              In_Outer_Width1: 224.056,
              In_Outer_Width2: 224.299,
              In_Internal_Width: 216.297,
              In_Thickness: 4.434,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 16:15:19",
              Tp_Result: "OK",
              Tp_JIG: 38.3,
              Tp_JIG_MIN: 36.8,
              Tp_JIG_MAX: 39.9,
              Tp_WJIG: 32.9,
              Tp_WJIG_MIN: 25.1,
              Tp_WJIG_MAX: 34,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1304,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0527",
              In_InsertTime: "2023-01-05 16:06:52",
              In_Result: "OK",
              In_Outer_Width1: 224.113,
              In_Outer_Width2: 223.908,
              In_Internal_Width: 216.331,
              In_Thickness: 4.415,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 17:31:02",
              Tp_Result: "OK",
              Tp_JIG: 32.6,
              Tp_JIG_MIN: 30.7,
              Tp_JIG_MAX: 33,
              Tp_WJIG: 34.8,
              Tp_WJIG_MIN: 27.7,
              Tp_WJIG_MAX: 34.8,
              Tp_Vibration: 907,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1306,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0528",
              In_InsertTime: "2023-01-05 16:07:33",
              In_Result: "OK",
              In_Outer_Width1: 223.92,
              In_Outer_Width2: 223.913,
              In_Internal_Width: 216.327,
              In_Thickness: 4.412,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 17:26:19",
              Tp_Result: "OK",
              Tp_JIG: 29,
              Tp_JIG_MIN: 28.2,
              Tp_JIG_MAX: 31,
              Tp_WJIG: 27.1,
              Tp_WJIG_MIN: 20.8,
              Tp_WJIG_MAX: 28.4,
              Tp_Vibration: 887,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1196,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0529",
              In_InsertTime: "2023-01-05 15:33:11",
              In_Result: "OK",
              In_Outer_Width1: 223.99,
              In_Outer_Width2: 223.839,
              In_Internal_Width: 216.279,
              In_Thickness: 4.424,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 16:08:28",
              Tp_Result: "OK",
              Tp_JIG: 31.2,
              Tp_JIG_MIN: 27.5,
              Tp_JIG_MAX: 34.6,
              Tp_WJIG: 27,
              Tp_WJIG_MIN: 19.4,
              Tp_WJIG_MAX: 27.2,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1298,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0533",
              In_InsertTime: "2023-01-05 16:04:17",
              In_Result: "OK",
              In_Outer_Width1: 223.885,
              In_Outer_Width2: 223.862,
              In_Internal_Width: 216.299,
              In_Thickness: 4.431,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 16:22:45",
              Tp_Result: "OK",
              Tp_JIG: 14.6,
              Tp_JIG_MIN: 13.5,
              Tp_JIG_MAX: 16.5,
              Tp_WJIG: 27.9,
              Tp_WJIG_MIN: 25.7,
              Tp_WJIG_MAX: 31.4,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1171,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0534",
              In_InsertTime: "2023-01-05 15:24:21",
              In_Result: "OK",
              In_Outer_Width1: 223.862,
              In_Outer_Width2: 223.916,
              In_Internal_Width: 216.293,
              In_Thickness: 4.429,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 15:42:56",
              Tp_Result: "OK",
              Tp_JIG: 34,
              Tp_JIG_MIN: 32.8,
              Tp_JIG_MAX: 36.4,
              Tp_WJIG: 23.5,
              Tp_WJIG_MIN: 21.1,
              Tp_WJIG_MAX: 25.6,
              Tp_Vibration: 892,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 234,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0536",
              In_InsertTime: "2023-01-05 09:01:46",
              In_Result: "OK",
              In_Outer_Width1: 223.824,
              In_Outer_Width2: 223.911,
              In_Internal_Width: 216.262,
              In_Thickness: 4.513,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 09:28:19",
              Tp_Result: "OK",
              Tp_JIG: 27.6,
              Tp_JIG_MIN: 25.8,
              Tp_JIG_MAX: 30,
              Tp_WJIG: 19.7,
              Tp_WJIG_MIN: 15.6,
              Tp_WJIG_MAX: 20,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1303,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0537",
              In_InsertTime: "2023-01-05 16:06:21",
              In_Result: "OK",
              In_Outer_Width1: 223.909,
              In_Outer_Width2: 223.984,
              In_Internal_Width: 216.269,
              In_Thickness: 4.53,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 17:30:01",
              Tp_Result: "OK",
              Tp_JIG: 38.1,
              Tp_JIG_MIN: 36.9,
              Tp_JIG_MAX: 39.6,
              Tp_WJIG: 31.3,
              Tp_WJIG_MIN: 24.9,
              Tp_WJIG_MAX: 33.4,
              Tp_Vibration: 900,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1225,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0538",
              In_InsertTime: "2023-01-05 15:48:57",
              In_Result: "OK",
              In_Outer_Width1: 223.901,
              In_Outer_Width2: 223.914,
              In_Internal_Width: 216.282,
              In_Thickness: 4.533,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 16:31:18",
              Tp_Result: "OK",
              Tp_JIG: 33,
              Tp_JIG_MIN: 31.1,
              Tp_JIG_MAX: 33.2,
              Tp_WJIG: 24.2,
              Tp_WJIG_MIN: 19.7,
              Tp_WJIG_MAX: 25.3,
              Tp_Vibration: 851,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1233,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0539",
              In_InsertTime: "2023-01-05 15:50:12",
              In_Result: "OK",
              In_Outer_Width1: 223.82,
              In_Outer_Width2: 223.849,
              In_Internal_Width: 216.244,
              In_Thickness: 4.543,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 16:29:26",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 10.3,
              Tp_WJIG: 24.1,
              Tp_WJIG_MIN: 22.7,
              Tp_WJIG_MAX: 25.9,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1226,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0540",
              In_InsertTime: "2023-01-05 15:49:06",
              In_Result: "OK",
              In_Outer_Width1: 223.888,
              In_Outer_Width2: 223.916,
              In_Internal_Width: 216.27,
              In_Thickness: 4.557,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 16:08:39",
              Tp_Result: "OK",
              Tp_JIG: 36.6,
              Tp_JIG_MIN: 35.1,
              Tp_JIG_MAX: 39,
              Tp_WJIG: 28.7,
              Tp_WJIG_MIN: 22.9,
              Tp_WJIG_MAX: 29.5,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1170,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0549",
              In_InsertTime: "2023-01-05 15:24:11",
              In_Result: "OK",
              In_Outer_Width1: 223.94,
              In_Outer_Width2: 224.026,
              In_Internal_Width: 216.258,
              In_Thickness: 4.527,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 15:50:08",
              Tp_Result: "OK",
              Tp_JIG: 12.2,
              Tp_JIG_MIN: 11.1,
              Tp_JIG_MAX: 13.5,
              Tp_WJIG: 21.6,
              Tp_WJIG_MIN: 16.8,
              Tp_WJIG_MAX: 22.7,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1216,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0550",
              In_InsertTime: "2023-01-05 15:47:35",
              In_Result: "OK",
              In_Outer_Width1: 223.949,
              In_Outer_Width2: 223.921,
              In_Internal_Width: 216.267,
              In_Thickness: 4.523,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 15:57:21",
              Tp_Result: "OK",
              Tp_JIG: 33.9,
              Tp_JIG_MIN: 32.3,
              Tp_JIG_MAX: 34.3,
              Tp_WJIG: 18.1,
              Tp_WJIG_MIN: 14.4,
              Tp_WJIG_MAX: 18.7,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1271,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0551",
              In_InsertTime: "2023-01-05 15:56:31",
              In_Result: "OK",
              In_Outer_Width1: 223.87,
              In_Outer_Width2: 223.877,
              In_Internal_Width: 216.252,
              In_Thickness: 4.508,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 16:41:39",
              Tp_Result: "OK",
              Tp_JIG: 30.6,
              Tp_JIG_MIN: 29.4,
              Tp_JIG_MAX: 32.4,
              Tp_WJIG: 30,
              Tp_WJIG_MIN: 26.7,
              Tp_WJIG_MAX: 31.7,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1173,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0552",
              In_InsertTime: "2023-01-05 15:24:57",
              In_Result: "OK",
              In_Outer_Width1: 224.089,
              In_Outer_Width2: 223.893,
              In_Internal_Width: 216.28,
              In_Thickness: 4.531,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 15:51:30",
              Tp_Result: "OK",
              Tp_JIG: 37.2,
              Tp_JIG_MIN: 35.9,
              Tp_JIG_MAX: 39.4,
              Tp_WJIG: 29.2,
              Tp_WJIG_MIN: 23.4,
              Tp_WJIG_MAX: 30,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1223,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0553",
              In_InsertTime: "2023-01-05 15:48:41",
              In_Result: "OK",
              In_Outer_Width1: 223.913,
              In_Outer_Width2: 223.876,
              In_Internal_Width: 216.259,
              In_Thickness: 4.505,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 15:59:36",
              Tp_Result: "OK",
              Tp_JIG: 34.3,
              Tp_JIG_MIN: 33.1,
              Tp_JIG_MAX: 36.7,
              Tp_WJIG: 20.6,
              Tp_WJIG_MIN: 17.9,
              Tp_WJIG_MAX: 21.1,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1190,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0555",
              In_InsertTime: "2023-01-05 15:30:10",
              In_Result: "OK",
              In_Outer_Width1: 223.977,
              In_Outer_Width2: 224.05,
              In_Internal_Width: 216.227,
              In_Thickness: 4.501,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 15:48:29",
              Tp_Result: "OK",
              Tp_JIG: 33.9,
              Tp_JIG_MIN: 32.8,
              Tp_JIG_MAX: 36.3,
              Tp_WJIG: 21.8,
              Tp_WJIG_MIN: 19.5,
              Tp_WJIG_MAX: 23.5,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1166,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0556",
              In_InsertTime: "2023-01-05 15:23:22",
              In_Result: "OK",
              In_Outer_Width1: 224.052,
              In_Outer_Width2: 224.057,
              In_Internal_Width: 216.288,
              In_Thickness: 4.551,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 16:13:14",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 34,
              Tp_JIG_MAX: 36.4,
              Tp_WJIG: 34.1,
              Tp_WJIG_MIN: 25.6,
              Tp_WJIG_MAX: 35.6,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1189,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0557",
              In_InsertTime: "2023-01-05 15:30:01",
              In_Result: "OK",
              In_Outer_Width1: 224.02,
              In_Outer_Width2: 224.278,
              In_Internal_Width: 216.293,
              In_Thickness: 4.453,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 16:14:46",
              Tp_Result: "OK",
              Tp_JIG: 51.2,
              Tp_JIG_MIN: 50.1,
              Tp_JIG_MAX: 53.2,
              Tp_WJIG: 35.5,
              Tp_WJIG_MIN: 30.4,
              Tp_WJIG_MAX: 37.8,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1188,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0558",
              In_InsertTime: "2023-01-05 15:29:52",
              In_Result: "OK",
              In_Outer_Width1: 224.112,
              In_Outer_Width2: 224.044,
              In_Internal_Width: 216.287,
              In_Thickness: 4.463,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 16:13:33",
              Tp_Result: "OK",
              Tp_JIG: 30.6,
              Tp_JIG_MIN: 29.3,
              Tp_JIG_MAX: 32.6,
              Tp_WJIG: 29.1,
              Tp_WJIG_MIN: 24.7,
              Tp_WJIG_MAX: 29.4,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1300,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0559",
              In_InsertTime: "2023-01-05 16:04:45",
              In_Result: "OK",
              In_Outer_Width1: 224.021,
              In_Outer_Width2: 223.92,
              In_Internal_Width: 216.28,
              In_Thickness: 4.458,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 17:27:25",
              Tp_Result: "OK",
              Tp_JIG: 25.4,
              Tp_JIG_MIN: 23.8,
              Tp_JIG_MAX: 26.7,
              Tp_WJIG: 34.9,
              Tp_WJIG_MIN: 26.7,
              Tp_WJIG_MAX: 35.3,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1176,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0560",
              In_InsertTime: "2023-01-05 15:26:36",
              In_Result: "OK",
              In_Outer_Width1: 223.86,
              In_Outer_Width2: 224.1,
              In_Internal_Width: 216.267,
              In_Thickness: 4.453,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 15:43:55",
              Tp_Result: "OK",
              Tp_JIG: 14.8,
              Tp_JIG_MIN: 13.8,
              Tp_JIG_MAX: 16.8,
              Tp_WJIG: 27.9,
              Tp_WJIG_MIN: 25.5,
              Tp_WJIG_MAX: 30.4,
              Tp_Vibration: 878,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1185,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0561",
              In_InsertTime: "2023-01-05 15:29:11",
              In_Result: "OK",
              In_Outer_Width1: 223.825,
              In_Outer_Width2: 223.905,
              In_Internal_Width: 216.277,
              In_Thickness: 4.46,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 16:12:34",
              Tp_Result: "OK",
              Tp_JIG: 9.3,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 11.6,
              Tp_WJIG: 26.6,
              Tp_WJIG_MIN: 24.2,
              Tp_WJIG_MAX: 28.9,
              Tp_Vibration: 888,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1179,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0562",
              In_InsertTime: "2023-01-05 15:27:51",
              In_Result: "OK",
              In_Outer_Width1: 223.919,
              In_Outer_Width2: 224.115,
              In_Internal_Width: 216.268,
              In_Thickness: 4.466,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 15:54:22",
              Tp_Result: "OK",
              Tp_JIG: 28.1,
              Tp_JIG_MIN: 26.6,
              Tp_JIG_MAX: 30.9,
              Tp_WJIG: 24.7,
              Tp_WJIG_MIN: 19.9,
              Tp_WJIG_MAX: 25.7,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1299,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0563",
              In_InsertTime: "2023-01-05 16:04:27",
              In_Result: "OK",
              In_Outer_Width1: 223.87,
              In_Outer_Width2: 223.892,
              In_Internal_Width: 216.272,
              In_Thickness: 4.462,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 16:31:21",
              Tp_Result: "OK",
              Tp_JIG: 36.4,
              Tp_JIG_MIN: 35.1,
              Tp_JIG_MAX: 38.7,
              Tp_WJIG: 33.6,
              Tp_WJIG_MIN: 27.5,
              Tp_WJIG_MAX: 34.4,
              Tp_Vibration: 858,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1134,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0564",
              In_InsertTime: "2023-01-05 15:12:51",
              In_Result: "OK",
              In_Outer_Width1: 223.989,
              In_Outer_Width2: 223.974,
              In_Internal_Width: 216.293,
              In_Thickness: 4.465,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 15:38:50",
              Tp_Result: "OK",
              Tp_JIG: 12,
              Tp_JIG_MIN: 10.6,
              Tp_JIG_MAX: 13.5,
              Tp_WJIG: 29.2,
              Tp_WJIG_MIN: 23.8,
              Tp_WJIG_MAX: 32.4,
              Tp_Vibration: 880,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1081,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0565",
              In_InsertTime: "2023-01-05 14:45:39",
              In_Result: "OK",
              In_Outer_Width1: 223.991,
              In_Outer_Width2: 223.893,
              In_Internal_Width: 216.314,
              In_Thickness: 4.489,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 15:46:56",
              Tp_Result: "OK",
              Tp_JIG: 38.4,
              Tp_JIG_MIN: 37.1,
              Tp_JIG_MAX: 40.1,
              Tp_WJIG: 33.9,
              Tp_WJIG_MIN: 27.6,
              Tp_WJIG_MAX: 35.3,
              Tp_Vibration: 792,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1163,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0566",
              In_InsertTime: "2023-01-05 15:22:41",
              In_Result: "OK",
              In_Outer_Width1: 223.962,
              In_Outer_Width2: 224.045,
              In_Internal_Width: 216.295,
              In_Thickness: 4.485,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 15:39:34",
              Tp_Result: "OK",
              Tp_JIG: 20.1,
              Tp_JIG_MIN: 19.1,
              Tp_JIG_MAX: 22.1,
              Tp_WJIG: 23.9,
              Tp_WJIG_MIN: 21.1,
              Tp_WJIG_MAX: 25.8,
              Tp_Vibration: 854,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1191,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0567",
              In_InsertTime: "2023-01-05 15:30:33",
              In_Result: "OK",
              In_Outer_Width1: 224.092,
              In_Outer_Width2: 224.05,
              In_Internal_Width: 216.308,
              In_Thickness: 4.498,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 16:20:56",
              Tp_Result: "OK",
              Tp_JIG: 37.9,
              Tp_JIG_MIN: 36.6,
              Tp_JIG_MAX: 39.7,
              Tp_WJIG: 29.4,
              Tp_WJIG_MIN: 24.1,
              Tp_WJIG_MAX: 29.5,
              Tp_Vibration: 852,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1082,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0568",
              In_InsertTime: "2023-01-05 14:45:47",
              In_Result: "OK",
              In_Outer_Width1: 223.902,
              In_Outer_Width2: 223.961,
              In_Internal_Width: 216.302,
              In_Thickness: 4.501,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 15:23:08",
              Tp_Result: "OK",
              Tp_JIG: 36.4,
              Tp_JIG_MIN: 34.9,
              Tp_JIG_MAX: 38.6,
              Tp_WJIG: 27.9,
              Tp_WJIG_MIN: 23.7,
              Tp_WJIG_MAX: 28.8,
              Tp_Vibration: 791,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1214,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0569",
              In_InsertTime: "2023-01-05 15:38:47",
              In_Result: "OK",
              In_Outer_Width1: 224.061,
              In_Outer_Width2: 223.94,
              In_Internal_Width: 216.304,
              In_Thickness: 4.495,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 16:29:14",
              Tp_Result: "OK",
              Tp_JIG: 25.4,
              Tp_JIG_MIN: 23.7,
              Tp_JIG_MAX: 26.9,
              Tp_WJIG: 36.3,
              Tp_WJIG_MIN: 29.9,
              Tp_WJIG_MAX: 37.6,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1210,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0570",
              In_InsertTime: "2023-01-05 15:36:26",
              In_Result: "OK",
              In_Outer_Width1: 224.036,
              In_Outer_Width2: 224.017,
              In_Internal_Width: 216.295,
              In_Thickness: 4.494,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 16:26:37",
              Tp_Result: "OK",
              Tp_JIG: 38,
              Tp_JIG_MIN: 36.9,
              Tp_JIG_MAX: 39.8,
              Tp_WJIG: 29.1,
              Tp_WJIG_MIN: 24.1,
              Tp_WJIG_MAX: 30.3,
              Tp_Vibration: 844,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1169,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0571",
              In_InsertTime: "2023-01-05 15:23:57",
              In_Result: "OK",
              In_Outer_Width1: 223.876,
              In_Outer_Width2: 223.883,
              In_Internal_Width: 216.288,
              In_Thickness: 4.506,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 16:14:22",
              Tp_Result: "OK",
              Tp_JIG: 32.8,
              Tp_JIG_MIN: 31,
              Tp_JIG_MAX: 33.1,
              Tp_WJIG: 24.9,
              Tp_WJIG_MIN: 20.2,
              Tp_WJIG_MAX: 24.9,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1174,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0572",
              In_InsertTime: "2023-01-05 15:25:12",
              In_Result: "OK",
              In_Outer_Width1: 223.993,
              In_Outer_Width2: 224.179,
              In_Internal_Width: 216.307,
              In_Thickness: 4.513,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 16:17:53",
              Tp_Result: "OK",
              Tp_JIG: 25.4,
              Tp_JIG_MIN: 24,
              Tp_JIG_MAX: 26.7,
              Tp_WJIG: 33.2,
              Tp_WJIG_MIN: 27,
              Tp_WJIG_MAX: 34.5,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1175,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0575",
              In_InsertTime: "2023-01-05 15:25:22",
              In_Result: "OK",
              In_Outer_Width1: 223.926,
              In_Outer_Width2: 223.951,
              In_Internal_Width: 216.315,
              In_Thickness: 4.484,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 16:11:34",
              Tp_Result: "OK",
              Tp_JIG: 29.2,
              Tp_JIG_MIN: 28.1,
              Tp_JIG_MAX: 31.2,
              Tp_WJIG: 27,
              Tp_WJIG_MIN: 22,
              Tp_WJIG_MAX: 28,
              Tp_Vibration: 890,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1168,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0578",
              In_InsertTime: "2023-01-05 15:23:47",
              In_Result: "OK",
              In_Outer_Width1: 224.073,
              In_Outer_Width2: 224,
              In_Internal_Width: 216.333,
              In_Thickness: 4.494,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 16:01:39",
              Tp_Result: "OK",
              Tp_JIG: 32.4,
              Tp_JIG_MIN: 32.4,
              Tp_JIG_MAX: 35.6,
              Tp_WJIG: 27.8,
              Tp_WJIG_MIN: 22,
              Tp_WJIG_MAX: 29.8,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 968,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0582",
              In_InsertTime: "2023-01-05 14:10:20",
              In_Result: "OK",
              In_Outer_Width1: 223.968,
              In_Outer_Width2: 223.924,
              In_Internal_Width: 216.286,
              In_Thickness: 4.473,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 15:07:06",
              Tp_Result: "OK",
              Tp_JIG: 51.8,
              Tp_JIG_MIN: 50.4,
              Tp_JIG_MAX: 53.5,
              Tp_WJIG: 41.6,
              Tp_WJIG_MIN: 32.6,
              Tp_WJIG_MAX: 43.7,
              Tp_Vibration: 888,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1182,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0583",
              In_InsertTime: "2023-01-05 15:28:31",
              In_Result: "OK",
              In_Outer_Width1: 223.948,
              In_Outer_Width2: 223.901,
              In_Internal_Width: 216.284,
              In_Thickness: 4.477,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 15:46:05",
              Tp_Result: "OK",
              Tp_JIG: 33.9,
              Tp_JIG_MIN: 32.1,
              Tp_JIG_MAX: 34.1,
              Tp_WJIG: 20.4,
              Tp_WJIG_MIN: 16.1,
              Tp_WJIG_MAX: 23.1,
              Tp_Vibration: 789,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1167,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0585",
              In_InsertTime: "2023-01-05 15:23:35",
              In_Result: "OK",
              In_Outer_Width1: 223.984,
              In_Outer_Width2: 223.983,
              In_Internal_Width: 216.299,
              In_Thickness: 4.479,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 16:09:08",
              Tp_Result: "OK",
              Tp_JIG: 51.4,
              Tp_JIG_MIN: 50.2,
              Tp_JIG_MAX: 53.4,
              Tp_WJIG: 36,
              Tp_WJIG_MIN: 29.7,
              Tp_WJIG_MAX: 38.2,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1133,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0586",
              In_InsertTime: "2023-01-05 15:12:37",
              In_Result: "OK",
              In_Outer_Width1: 223.852,
              In_Outer_Width2: 223.849,
              In_Internal_Width: 216.272,
              In_Thickness: 4.484,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 16:02:56",
              Tp_Result: "OK",
              Tp_JIG: 32.8,
              Tp_JIG_MIN: 31,
              Tp_JIG_MAX: 33.1,
              Tp_WJIG: 31.4,
              Tp_WJIG_MIN: 25.7,
              Tp_WJIG_MAX: 32.6,
              Tp_Vibration: 853,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1209,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0587",
              In_InsertTime: "2023-01-05 15:36:11",
              In_Result: "OK",
              In_Outer_Width1: 223.864,
              In_Outer_Width2: 223.848,
              In_Internal_Width: 216.282,
              In_Thickness: 4.488,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 15:55:00",
              Tp_Result: "OK",
              Tp_JIG: 14.7,
              Tp_JIG_MIN: 13.4,
              Tp_JIG_MAX: 16.4,
              Tp_WJIG: 26.9,
              Tp_WJIG_MIN: 24.4,
              Tp_WJIG_MAX: 28.8,
              Tp_Vibration: 877,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1083,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0588",
              In_InsertTime: "2023-01-05 14:46:07",
              In_Result: "OK",
              In_Outer_Width1: 223.832,
              In_Outer_Width2: 223.918,
              In_Internal_Width: 216.273,
              In_Thickness: 4.494,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 15:15:11",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 36.8,
              Tp_WJIG: 25.5,
              Tp_WJIG_MIN: 22.1,
              Tp_WJIG_MAX: 27.6,
              Tp_Vibration: 874,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 132,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0590",
              In_InsertTime: "2023-01-05 08:30:53",
              In_Result: "OK",
              In_Outer_Width1: 223.839,
              In_Outer_Width2: 223.886,
              In_Internal_Width: 216.235,
              In_Thickness: 4.426,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 08:48:15",
              Tp_Result: "OK",
              Tp_JIG: 34.9,
              Tp_JIG_MIN: 33,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 23,
              Tp_WJIG_MIN: 20.1,
              Tp_WJIG_MAX: 25.3,
              Tp_Vibration: 853,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 315,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0591",
              In_InsertTime: "2023-01-05 09:28:13",
              In_Result: "OK",
              In_Outer_Width1: 223.886,
              In_Outer_Width2: 223.93,
              In_Internal_Width: 216.252,
              In_Thickness: 4.425,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 09:46:09",
              Tp_Result: "OK",
              Tp_JIG: 35,
              Tp_JIG_MIN: 33.5,
              Tp_JIG_MAX: 37.1,
              Tp_WJIG: 28.8,
              Tp_WJIG_MIN: 24.4,
              Tp_WJIG_MAX: 30.4,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1181,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0593",
              In_InsertTime: "2023-01-05 15:28:23",
              In_Result: "OK",
              In_Outer_Width1: 223.888,
              In_Outer_Width2: 223.937,
              In_Internal_Width: 216.239,
              In_Thickness: 4.443,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 15:45:08",
              Tp_Result: "OK",
              Tp_JIG: 20.3,
              Tp_JIG_MIN: 19.1,
              Tp_JIG_MAX: 22.2,
              Tp_WJIG: 29.7,
              Tp_WJIG_MIN: 25,
              Tp_WJIG_MAX: 31.1,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 326,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0594",
              In_InsertTime: "2023-01-05 09:30:47",
              In_Result: "OK",
              In_Outer_Width1: 223.858,
              In_Outer_Width2: 223.909,
              In_Internal_Width: 216.256,
              In_Thickness: 4.438,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 10:28:52",
              Tp_Result: "OK",
              Tp_JIG: 31.4,
              Tp_JIG_MIN: 30,
              Tp_JIG_MAX: 33.3,
              Tp_WJIG: 38.3,
              Tp_WJIG_MIN: 31.6,
              Tp_WJIG_MAX: 39.9,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1200,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0599",
              In_InsertTime: "2023-01-05 15:34:09",
              In_Result: "OK",
              In_Outer_Width1: 224.075,
              In_Outer_Width2: 223.948,
              In_Internal_Width: 216.369,
              In_Thickness: 4.43,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 15:51:40",
              Tp_Result: "OK",
              Tp_JIG: 33.9,
              Tp_JIG_MIN: 31.9,
              Tp_JIG_MAX: 34.2,
              Tp_WJIG: 24.8,
              Tp_WJIG_MIN: 21.9,
              Tp_WJIG_MAX: 27.2,
              Tp_Vibration: 786,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1199,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0603",
              In_InsertTime: "2023-01-05 15:34:00",
              In_Result: "OK",
              In_Outer_Width1: 223.868,
              In_Outer_Width2: 224.033,
              In_Internal_Width: 216.303,
              In_Thickness: 4.445,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 15:50:43",
              Tp_Result: "OK",
              Tp_JIG: 20.7,
              Tp_JIG_MIN: 19.4,
              Tp_JIG_MAX: 22.6,
              Tp_WJIG: 27.1,
              Tp_WJIG_MIN: 24.1,
              Tp_WJIG_MAX: 29.7,
              Tp_Vibration: 852,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 314,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0605",
              In_InsertTime: "2023-01-05 09:28:04",
              In_Result: "OK",
              In_Outer_Width1: 223.847,
              In_Outer_Width2: 223.867,
              In_Internal_Width: 216.296,
              In_Thickness: 4.44,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 10:06:24",
              Tp_Result: "OK",
              Tp_JIG: 27.3,
              Tp_JIG_MIN: 25.9,
              Tp_JIG_MAX: 30,
              Tp_WJIG: 24,
              Tp_WJIG_MIN: 18.6,
              Tp_WJIG_MAX: 24.8,
              Tp_Vibration: 907,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1194,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0614",
              In_InsertTime: "2023-01-05 15:32:17",
              In_Result: "OK",
              In_Outer_Width1: 224.255,
              In_Outer_Width2: 224.269,
              In_Internal_Width: 216.364,
              In_Thickness: 4.458,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 16:17:17",
              Tp_Result: "OK",
              Tp_JIG: 29.3,
              Tp_JIG_MIN: 28.2,
              Tp_JIG_MAX: 31.4,
              Tp_WJIG: 25.8,
              Tp_WJIG_MIN: 22,
              Tp_WJIG_MAX: 27.8,
              Tp_Vibration: 891,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 320,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0616",
              In_InsertTime: "2023-01-05 09:29:24",
              In_Result: "OK",
              In_Outer_Width1: 224.075,
              In_Outer_Width2: 223.891,
              In_Internal_Width: 216.269,
              In_Thickness: 4.493,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 10:26:50",
              Tp_Result: "OK",
              Tp_JIG: 52.4,
              Tp_JIG_MIN: 51.1,
              Tp_JIG_MAX: 53.9,
              Tp_WJIG: 42.4,
              Tp_WJIG_MIN: 33.8,
              Tp_WJIG_MAX: 45,
              Tp_Vibration: 894,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 161,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0617",
              In_InsertTime: "2023-01-05 08:38:53",
              In_Result: "OK",
              In_Outer_Width1: 223.869,
              In_Outer_Width2: 223.929,
              In_Internal_Width: 216.253,
              In_Thickness: 4.456,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 09:24:16",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 10,
              Tp_WJIG: 27.9,
              Tp_WJIG_MIN: 26.2,
              Tp_WJIG_MAX: 28.7,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 240,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0618",
              In_InsertTime: "2023-01-05 09:04:51",
              In_Result: "OK",
              In_Outer_Width1: 223.805,
              In_Outer_Width2: 223.887,
              In_Internal_Width: 216.254,
              In_Thickness: 4.464,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 09:21:49",
              Tp_Result: "OK",
              Tp_JIG: 34.7,
              Tp_JIG_MIN: 33.1,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 15.7,
              Tp_WJIG_MIN: 12.5,
              Tp_WJIG_MAX: 17.5,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1184,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0621",
              In_InsertTime: "2023-01-05 15:28:52",
              In_Result: "OK",
              In_Outer_Width1: 223.945,
              In_Outer_Width2: 223.998,
              In_Internal_Width: 216.287,
              In_Thickness: 4.47,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 16:18:56",
              Tp_Result: "OK",
              Tp_JIG: 35.4,
              Tp_JIG_MIN: 34.3,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 29.8,
              Tp_WJIG_MIN: 24.9,
              Tp_WJIG_MAX: 30.4,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 205,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0623",
              In_InsertTime: "2023-01-05 08:53:44",
              In_Result: "OK",
              In_Outer_Width1: 223.896,
              In_Outer_Width2: 224.066,
              In_Internal_Width: 216.209,
              In_Thickness: 4.514,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 09:38:59",
              Tp_Result: "OK",
              Tp_JIG: 28.4,
              Tp_JIG_MIN: 26.8,
              Tp_JIG_MAX: 30.1,
              Tp_WJIG: 25.9,
              Tp_WJIG_MIN: 20.4,
              Tp_WJIG_MAX: 26.7,
              Tp_Vibration: 871,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1192,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0625",
              In_InsertTime: "2023-01-05 15:30:53",
              In_Result: "OK",
              In_Outer_Width1: 223.842,
              In_Outer_Width2: 223.91,
              In_Internal_Width: 216.269,
              In_Thickness: 4.474,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 15:49:27",
              Tp_Result: "OK",
              Tp_JIG: 14.9,
              Tp_JIG_MIN: 13.5,
              Tp_JIG_MAX: 16.8,
              Tp_WJIG: 27,
              Tp_WJIG_MIN: 25.3,
              Tp_WJIG_MAX: 30.1,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1198,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0626",
              In_InsertTime: "2023-01-05 15:33:46",
              In_Result: "OK",
              In_Outer_Width1: 223.899,
              In_Outer_Width2: 223.906,
              In_Internal_Width: 216.275,
              In_Thickness: 4.482,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 16:19:10",
              Tp_Result: "OK",
              Tp_JIG: 30.6,
              Tp_JIG_MIN: 29.4,
              Tp_JIG_MAX: 32.5,
              Tp_WJIG: 30.8,
              Tp_WJIG_MIN: 27.4,
              Tp_WJIG_MAX: 31.1,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 265,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0627",
              In_InsertTime: "2023-01-05 09:11:54",
              In_Result: "OK",
              In_Outer_Width1: 224.178,
              In_Outer_Width2: 224.06,
              In_Internal_Width: 216.253,
              In_Thickness: 4.522,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 09:30:26",
              Tp_Result: "OK",
              Tp_JIG: 15.2,
              Tp_JIG_MIN: 14.1,
              Tp_JIG_MAX: 16.9,
              Tp_WJIG: 26,
              Tp_WJIG_MIN: 23.4,
              Tp_WJIG_MAX: 26.2,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 327,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0628",
              In_InsertTime: "2023-01-05 09:31:12",
              In_Result: "OK",
              In_Outer_Width1: 223.778,
              In_Outer_Width2: 223.833,
              In_Internal_Width: 216.249,
              In_Thickness: 4.469,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 10:33:30",
              Tp_Result: "OK",
              Tp_JIG: 37.4,
              Tp_JIG_MIN: 36.1,
              Tp_JIG_MAX: 39.1,
              Tp_WJIG: 33.8,
              Tp_WJIG_MIN: 27.5,
              Tp_WJIG_MAX: 35.6,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1308,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0629",
              In_InsertTime: "2023-01-05 16:08:14",
              In_Result: "OK",
              In_Outer_Width1: 223.902,
              In_Outer_Width2: 223.807,
              In_Internal_Width: 216.266,
              In_Thickness: 4.465,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 16:25:10",
              Tp_Result: "OK",
              Tp_JIG: 33.9,
              Tp_JIG_MIN: 32,
              Tp_JIG_MAX: 34.1,
              Tp_WJIG: 16.6,
              Tp_WJIG_MIN: 13.9,
              Tp_WJIG_MAX: 19.1,
              Tp_Vibration: 854,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1201,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0632",
              In_InsertTime: "2023-01-05 15:34:20",
              In_Result: "OK",
              In_Outer_Width1: 223.92,
              In_Outer_Width2: 223.909,
              In_Internal_Width: 216.322,
              In_Thickness: 4.458,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 15:59:58",
              Tp_Result: "OK",
              Tp_JIG: 28,
              Tp_JIG_MIN: 26.6,
              Tp_JIG_MAX: 31.3,
              Tp_WJIG: 20.6,
              Tp_WJIG_MIN: 16.8,
              Tp_WJIG_MAX: 20.8,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 266,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0633",
              In_InsertTime: "2023-01-05 09:12:04",
              In_Result: "OK",
              In_Outer_Width1: 223.952,
              In_Outer_Width2: 223.976,
              In_Internal_Width: 216.325,
              In_Thickness: 4.456,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 09:39:40",
              Tp_Result: "OK",
              Tp_JIG: 32.8,
              Tp_JIG_MIN: 31.8,
              Tp_JIG_MAX: 35.2,
              Tp_WJIG: 27.3,
              Tp_WJIG_MIN: 22.7,
              Tp_WJIG_MAX: 27.7,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 313,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0634",
              In_InsertTime: "2023-01-05 09:27:57",
              In_Result: "OK",
              In_Outer_Width1: 223.919,
              In_Outer_Width2: 223.942,
              In_Internal_Width: 216.322,
              In_Thickness: 4.475,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 10:27:50",
              Tp_Result: "OK",
              Tp_JIG: 37.3,
              Tp_JIG_MIN: 36.2,
              Tp_JIG_MAX: 39.2,
              Tp_WJIG: 29.7,
              Tp_WJIG_MIN: 22.2,
              Tp_WJIG_MAX: 30.3,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 331,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0636",
              In_InsertTime: "2023-01-05 09:33:18",
              In_Result: "OK",
              In_Outer_Width1: 223.862,
              In_Outer_Width2: 224.014,
              In_Internal_Width: 216.314,
              In_Thickness: 4.462,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 10:21:36",
              Tp_Result: "OK",
              Tp_JIG: 32,
              Tp_JIG_MIN: 31.9,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 24.4,
              Tp_WJIG_MIN: 21.1,
              Tp_WJIG_MAX: 25,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1193,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0647",
              In_InsertTime: "2023-01-05 15:31:03",
              In_Result: "OK",
              In_Outer_Width1: 223.858,
              In_Outer_Width2: 223.946,
              In_Internal_Width: 216.295,
              In_Thickness: 4.43,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 15:58:20",
              Tp_Result: "OK",
              Tp_JIG: 31.8,
              Tp_JIG_MIN: 30.2,
              Tp_JIG_MAX: 34.4,
              Tp_WJIG: 33.9,
              Tp_WJIG_MIN: 26.9,
              Tp_WJIG_MAX: 34.2,
              Tp_Vibration: 794,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1187,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0648",
              In_InsertTime: "2023-01-05 15:29:37",
              In_Result: "OK",
              In_Outer_Width1: 223.848,
              In_Outer_Width2: 223.893,
              In_Internal_Width: 216.279,
              In_Thickness: 4.426,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 16:19:57",
              Tp_Result: "OK",
              Tp_JIG: 32.9,
              Tp_JIG_MIN: 31,
              Tp_JIG_MAX: 33.2,
              Tp_WJIG: 23.7,
              Tp_WJIG_MIN: 18.9,
              Tp_WJIG_MAX: 23.9,
              Tp_Vibration: 842,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1315,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0650",
              In_InsertTime: "2023-01-05 16:09:44",
              In_Result: "OK",
              In_Outer_Width1: 223.992,
              In_Outer_Width2: 223.988,
              In_Internal_Width: 216.223,
              In_Thickness: 4.424,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 16:47:36",
              Tp_Result: "OK",
              Tp_JIG: 31.8,
              Tp_JIG_MIN: 31.8,
              Tp_JIG_MAX: 35.7,
              Tp_WJIG: 34,
              Tp_WJIG_MIN: 25.9,
              Tp_WJIG_MAX: 35,
              Tp_Vibration: 899,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1314,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0658",
              In_InsertTime: "2023-01-05 16:09:34",
              In_Result: "OK",
              In_Outer_Width1: 223.879,
              In_Outer_Width2: 224.09,
              In_Internal_Width: 216.238,
              In_Thickness: 4.426,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 16:35:10",
              Tp_Result: "OK",
              Tp_JIG: 12.1,
              Tp_JIG_MIN: 10.9,
              Tp_JIG_MAX: 13.5,
              Tp_WJIG: 33.2,
              Tp_WJIG_MIN: 25.5,
              Tp_WJIG_MAX: 35.1,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 362,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0666",
              In_InsertTime: "2023-01-05 09:42:19",
              In_Result: "OK",
              In_Outer_Width1: 223.833,
              In_Outer_Width2: 223.854,
              In_Internal_Width: 216.188,
              In_Thickness: 4.412,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 10:40:11",
              Tp_Result: "OK",
              Tp_JIG: 31.9,
              Tp_JIG_MIN: 30.8,
              Tp_JIG_MAX: 33.6,
              Tp_WJIG: 29.1,
              Tp_WJIG_MIN: 25.2,
              Tp_WJIG_MAX: 30.9,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 383,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0669",
              In_InsertTime: "2023-01-05 10:00:48",
              In_Result: "OK",
              In_Outer_Width1: 223.914,
              In_Outer_Width2: 223.88,
              In_Internal_Width: 216.2,
              In_Thickness: 4.422,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 10:18:18",
              Tp_Result: "OK",
              Tp_JIG: 15.5,
              Tp_JIG_MIN: 14.5,
              Tp_JIG_MAX: 17.6,
              Tp_WJIG: 29.1,
              Tp_WJIG_MIN: 26.6,
              Tp_WJIG_MAX: 32.9,
              Tp_Vibration: 858,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 382,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0670",
              In_InsertTime: "2023-01-05 10:00:40",
              In_Result: "OK",
              In_Outer_Width1: 223.882,
              In_Outer_Width2: 223.977,
              In_Internal_Width: 216.197,
              In_Thickness: 4.431,
              Tp_No: 74,
              Tp_InsertTime: "2023-01-05 10:45:49",
              Tp_Result: "OK",
              Tp_JIG: 31.6,
              Tp_JIG_MIN: 30.4,
              Tp_JIG_MAX: 33.4,
              Tp_WJIG: 33.6,
              Tp_WJIG_MIN: 29.2,
              Tp_WJIG_MAX: 35.8,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 356,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0671",
              In_InsertTime: "2023-01-05 09:40:57",
              In_Result: "OK",
              In_Outer_Width1: 223.839,
              In_Outer_Width2: 224.089,
              In_Internal_Width: 216.209,
              In_Thickness: 4.431,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 10:43:23",
              Tp_Result: "OK",
              Tp_JIG: 33.4,
              Tp_JIG_MIN: 31.4,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 30.9,
              Tp_WJIG_MIN: 24.7,
              Tp_WJIG_MAX: 31.3,
              Tp_Vibration: 896,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 386,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0672",
              In_InsertTime: "2023-01-05 10:01:15",
              In_Result: "OK",
              In_Outer_Width1: 223.943,
              In_Outer_Width2: 224.013,
              In_Internal_Width: 216.2,
              In_Thickness: 4.414,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 10:52:41",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 33.7,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 41.6,
              Tp_WJIG_MIN: 34.6,
              Tp_WJIG_MAX: 43.5,
              Tp_Vibration: 853,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 367,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0674",
              In_InsertTime: "2023-01-05 09:44:32",
              In_Result: "OK",
              In_Outer_Width1: 223.836,
              In_Outer_Width2: 223.98,
              In_Internal_Width: 216.206,
              In_Thickness: 4.429,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 10:41:10",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.5,
              Tp_WJIG: 26.5,
              Tp_WJIG_MIN: 23.9,
              Tp_WJIG_MAX: 30,
              Tp_Vibration: 801,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 381,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0675",
              In_InsertTime: "2023-01-05 10:00:31",
              In_Result: "OK",
              In_Outer_Width1: 223.87,
              In_Outer_Width2: 224.034,
              In_Internal_Width: 216.197,
              In_Thickness: 4.428,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 10:38:53",
              Tp_Result: "OK",
              Tp_JIG: 32.3,
              Tp_JIG_MIN: 32.2,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 30.7,
              Tp_WJIG_MIN: 23.6,
              Tp_WJIG_MAX: 33.1,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1311,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0676",
              In_InsertTime: "2023-01-05 16:08:56",
              In_Result: "OK",
              In_Outer_Width1: 224.31,
              In_Outer_Width2: 223.97,
              In_Internal_Width: 216.198,
              In_Thickness: 4.502,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 17:27:22",
              Tp_Result: "OK",
              Tp_JIG: 51.4,
              Tp_JIG_MIN: 50,
              Tp_JIG_MAX: 52.9,
              Tp_WJIG: 41.3,
              Tp_WJIG_MIN: 33.9,
              Tp_WJIG_MAX: 43.4,
              Tp_Vibration: 885,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 385,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0678",
              In_InsertTime: "2023-01-05 10:01:06",
              In_Result: "OK",
              In_Outer_Width1: 223.947,
              In_Outer_Width2: 224.043,
              In_Internal_Width: 216.111,
              In_Thickness: 4.502,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 10:51:42",
              Tp_Result: "OK",
              Tp_JIG: 25,
              Tp_JIG_MIN: 23.6,
              Tp_JIG_MAX: 26.3,
              Tp_WJIG: 35.1,
              Tp_WJIG_MIN: 30.3,
              Tp_WJIG_MAX: 37.3,
              Tp_Vibration: 866,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 347,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0683",
              In_InsertTime: "2023-01-05 09:38:24",
              In_Result: "OK",
              In_Outer_Width1: 223.841,
              In_Outer_Width2: 224.1,
              In_Internal_Width: 216.139,
              In_Thickness: 4.478,
              Tp_No: 71,
              Tp_InsertTime: "2023-01-05 10:35:31",
              Tp_Result: "OK",
              Tp_JIG: 0,
              Tp_JIG_MIN: 0,
              Tp_JIG_MAX: 9.8,
              Tp_WJIG: 25.6,
              Tp_WJIG_MIN: 23.1,
              Tp_WJIG_MAX: 28.9,
              Tp_Vibration: 798,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 236,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0688",
              In_InsertTime: "2023-01-05 09:02:48",
              In_Result: "OK",
              In_Outer_Width1: 223.863,
              In_Outer_Width2: 224.029,
              In_Internal_Width: 216.142,
              In_Thickness: 4.493,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 09:49:16",
              Tp_Result: "OK",
              Tp_JIG: 53.3,
              Tp_JIG_MIN: 52.1,
              Tp_JIG_MAX: 54.2,
              Tp_WJIG: 32.7,
              Tp_WJIG_MIN: 26.9,
              Tp_WJIG_MAX: 35.5,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 233,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0691",
              In_InsertTime: "2023-01-05 09:01:37",
              In_Result: "OK",
              In_Outer_Width1: 224.008,
              In_Outer_Width2: 224.109,
              In_Internal_Width: 216.173,
              In_Thickness: 4.543,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 10:04:09",
              Tp_Result: "OK",
              Tp_JIG: 33.5,
              Tp_JIG_MIN: 31.9,
              Tp_JIG_MAX: 34.2,
              Tp_WJIG: 24.3,
              Tp_WJIG_MIN: 17.8,
              Tp_WJIG_MAX: 24.9,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 198,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0692",
              In_InsertTime: "2023-01-05 08:50:26",
              In_Result: "OK",
              In_Outer_Width1: 224.307,
              In_Outer_Width2: 223.902,
              In_Internal_Width: 216.238,
              In_Thickness: 4.492,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 09:16:45",
              Tp_Result: "OK",
              Tp_JIG: 27.5,
              Tp_JIG_MIN: 25.7,
              Tp_JIG_MAX: 30.2,
              Tp_WJIG: 20.2,
              Tp_WJIG_MIN: 16,
              Tp_WJIG_MAX: 21.2,
              Tp_Vibration: 864,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 380,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0694",
              In_InsertTime: "2023-01-05 10:00:23",
              In_Result: "OK",
              In_Outer_Width1: 224.2,
              In_Outer_Width2: 224.042,
              In_Internal_Width: 216.221,
              In_Thickness: 4.542,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 10:26:45",
              Tp_Result: "OK",
              Tp_JIG: 11.9,
              Tp_JIG_MIN: 10.9,
              Tp_JIG_MAX: 13.8,
              Tp_WJIG: 24.2,
              Tp_WJIG_MIN: 17,
              Tp_WJIG_MAX: 26.6,
              Tp_Vibration: 874,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 129,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0695",
              In_InsertTime: "2023-01-05 08:29:57",
              In_Result: "OK",
              In_Outer_Width1: 223.807,
              In_Outer_Width2: 223.973,
              In_Internal_Width: 216.156,
              In_Thickness: 4.508,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 09:20:48",
              Tp_Result: "OK",
              Tp_JIG: 33.5,
              Tp_JIG_MIN: 31.5,
              Tp_JIG_MAX: 34.2,
              Tp_WJIG: 25.3,
              Tp_WJIG_MIN: 19.8,
              Tp_WJIG_MAX: 26.5,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 162,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0696",
              In_InsertTime: "2023-01-05 08:39:03",
              In_Result: "OK",
              In_Outer_Width1: 224.154,
              In_Outer_Width2: 224.036,
              In_Internal_Width: 216.212,
              In_Thickness: 4.521,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 09:05:22",
              Tp_Result: "OK",
              Tp_JIG: 27.4,
              Tp_JIG_MIN: 26,
              Tp_JIG_MAX: 29.8,
              Tp_WJIG: 17,
              Tp_WJIG_MIN: 13.4,
              Tp_WJIG_MAX: 17.9,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 340,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0697",
              In_InsertTime: "2023-01-05 09:36:10",
              In_Result: "OK",
              In_Outer_Width1: 223.848,
              In_Outer_Width2: 224.115,
              In_Internal_Width: 216.161,
              In_Thickness: 4.518,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 10:15:32",
              Tp_Result: "OK",
              Tp_JIG: 12.6,
              Tp_JIG_MIN: 11.4,
              Tp_JIG_MAX: 13.9,
              Tp_WJIG: 23,
              Tp_WJIG_MIN: 16.4,
              Tp_WJIG_MAX: 25.5,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 131,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0698",
              In_InsertTime: "2023-01-05 08:30:13",
              In_Result: "OK",
              In_Outer_Width1: 224.04,
              In_Outer_Width2: 224.07,
              In_Internal_Width: 216.179,
              In_Thickness: 4.51,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 08:47:20",
              Tp_Result: "OK",
              Tp_JIG: 21.9,
              Tp_JIG_MIN: 20.8,
              Tp_JIG_MAX: 24.1,
              Tp_WJIG: 20.4,
              Tp_WJIG_MIN: 17.5,
              Tp_WJIG_MAX: 22.9,
              Tp_Vibration: 863,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 319,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0699",
              In_InsertTime: "2023-01-05 09:29:02",
              In_Result: "OK",
              In_Outer_Width1: 223.993,
              In_Outer_Width2: 224.045,
              In_Internal_Width: 216.191,
              In_Thickness: 4.511,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 10:29:59",
              Tp_Result: "OK",
              Tp_JIG: 35.4,
              Tp_JIG_MIN: 34.1,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 32.3,
              Tp_WJIG_MIN: 26.2,
              Tp_WJIG_MAX: 34.5,
              Tp_Vibration: 853,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 334,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0702",
              In_InsertTime: "2023-01-05 09:34:43",
              In_Result: "OK",
              In_Outer_Width1: 223.89,
              In_Outer_Width2: 224.138,
              In_Internal_Width: 216.181,
              In_Thickness: 4.53,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 10:34:41",
              Tp_Result: "OK",
              Tp_JIG: 25.1,
              Tp_JIG_MIN: 23.5,
              Tp_JIG_MAX: 26.3,
              Tp_WJIG: 28.3,
              Tp_WJIG_MIN: 22.9,
              Tp_WJIG_MAX: 29.5,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 322,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0705",
              In_InsertTime: "2023-01-05 09:29:56",
              In_Result: "OK",
              In_Outer_Width1: 224.194,
              In_Outer_Width2: 223.983,
              In_Internal_Width: 216.221,
              In_Thickness: 4.53,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 10:32:12",
              Tp_Result: "OK",
              Tp_JIG: 33.5,
              Tp_JIG_MIN: 31.7,
              Tp_JIG_MAX: 34,
              Tp_WJIG: 22.6,
              Tp_WJIG_MIN: 17.4,
              Tp_WJIG_MAX: 23.2,
              Tp_Vibration: 884,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 317,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0706",
              In_InsertTime: "2023-01-05 09:28:44",
              In_Result: "OK",
              In_Outer_Width1: 224.023,
              In_Outer_Width2: 224,
              In_Internal_Width: 216.185,
              In_Thickness: 4.54,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 10:28:57",
              Tp_Result: "OK",
              Tp_JIG: 25,
              Tp_JIG_MIN: 23.5,
              Tp_JIG_MAX: 26.4,
              Tp_WJIG: 32.3,
              Tp_WJIG_MIN: 26.8,
              Tp_WJIG_MAX: 33.6,
              Tp_Vibration: 856,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 323,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0707",
              In_InsertTime: "2023-01-05 09:30:15",
              In_Result: "OK",
              In_Outer_Width1: 224.047,
              In_Outer_Width2: 223.964,
              In_Internal_Width: 216.22,
              In_Thickness: 4.495,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 09:48:49",
              Tp_Result: "OK",
              Tp_JIG: 21.3,
              Tp_JIG_MIN: 20.5,
              Tp_JIG_MAX: 23.7,
              Tp_WJIG: 19.3,
              Tp_WJIG_MIN: 16.9,
              Tp_WJIG_MAX: 21.8,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 312,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0708",
              In_InsertTime: "2023-01-05 09:27:48",
              In_Result: "OK",
              In_Outer_Width1: 224.14,
              In_Outer_Width2: 223.829,
              In_Internal_Width: 216.232,
              In_Thickness: 4.467,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 09:44:10",
              Tp_Result: "OK",
              Tp_JIG: 34.5,
              Tp_JIG_MIN: 32.9,
              Tp_JIG_MAX: 34.9,
              Tp_WJIG: 17.7,
              Tp_WJIG_MIN: 14.7,
              Tp_WJIG_MAX: 20.4,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 324,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0709",
              In_InsertTime: "2023-01-05 09:30:24",
              In_Result: "OK",
              In_Outer_Width1: 223.75,
              In_Outer_Width2: 223.785,
              In_Internal_Width: 216.17,
              In_Thickness: 4.474,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 10:16:55",
              Tp_Result: "OK",
              Tp_JIG: 33.8,
              Tp_JIG_MIN: 31.5,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 27.3,
              Tp_WJIG_MIN: 21.7,
              Tp_WJIG_MAX: 29.3,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 332,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0710",
              In_InsertTime: "2023-01-05 09:33:45",
              In_Result: "OK",
              In_Outer_Width1: 223.885,
              In_Outer_Width2: 223.798,
              In_Internal_Width: 216.175,
              In_Thickness: 4.45,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 10:04:16",
              Tp_Result: "OK",
              Tp_JIG: 21.2,
              Tp_JIG_MIN: 19.8,
              Tp_JIG_MAX: 23.1,
              Tp_WJIG: 25.6,
              Tp_WJIG_MIN: 21.5,
              Tp_WJIG_MAX: 27.9,
              Tp_Vibration: 880,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 337,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0711",
              In_InsertTime: "2023-01-05 09:35:15",
              In_Result: "OK",
              In_Outer_Width1: 223.739,
              In_Outer_Width2: 223.865,
              In_Internal_Width: 216.164,
              In_Thickness: 4.474,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 10:13:00",
              Tp_Result: "OK",
              Tp_JIG: 36.5,
              Tp_JIG_MIN: 34.9,
              Tp_JIG_MAX: 38.7,
              Tp_WJIG: 27.3,
              Tp_WJIG_MIN: 22.2,
              Tp_WJIG_MAX: 29.6,
              Tp_Vibration: 868,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 318,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0712",
              In_InsertTime: "2023-01-05 09:28:53",
              In_Result: "OK",
              In_Outer_Width1: 224.062,
              In_Outer_Width2: 223.861,
              In_Internal_Width: 216.168,
              In_Thickness: 4.451,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 09:47:07",
              Tp_Result: "OK",
              Tp_JIG: 15,
              Tp_JIG_MIN: 13.5,
              Tp_JIG_MAX: 16.8,
              Tp_WJIG: 31.4,
              Tp_WJIG_MIN: 28.9,
              Tp_WJIG_MAX: 34.8,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 328,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0713",
              In_InsertTime: "2023-01-05 09:32:04",
              In_Result: "OK",
              In_Outer_Width1: 223.882,
              In_Outer_Width2: 224.242,
              In_Internal_Width: 216.189,
              In_Thickness: 4.48,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 10:10:58",
              Tp_Result: "OK",
              Tp_JIG: 32.7,
              Tp_JIG_MIN: 31.6,
              Tp_JIG_MAX: 35,
              Tp_WJIG: 32.7,
              Tp_WJIG_MIN: 27.2,
              Tp_WJIG_MAX: 34,
              Tp_Vibration: 905,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 339,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0714",
              In_InsertTime: "2023-01-05 09:35:52",
              In_Result: "OK",
              In_Outer_Width1: 223.988,
              In_Outer_Width2: 223.974,
              In_Internal_Width: 216.195,
              In_Thickness: 4.484,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 10:33:33",
              Tp_Result: "OK",
              Tp_JIG: 28.6,
              Tp_JIG_MIN: 27.5,
              Tp_JIG_MAX: 30.4,
              Tp_WJIG: 28.5,
              Tp_WJIG_MIN: 24.3,
              Tp_WJIG_MAX: 31.3,
              Tp_Vibration: 892,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1206,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0715",
              In_InsertTime: "2023-01-05 15:35:27",
              In_Result: "OK",
              In_Outer_Width1: 223.893,
              In_Outer_Width2: 223.883,
              In_Internal_Width: 216.173,
              In_Thickness: 4.465,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 16:01:23",
              Tp_Result: "OK",
              Tp_JIG: 12,
              Tp_JIG_MIN: 10.4,
              Tp_JIG_MAX: 13.6,
              Tp_WJIG: 26.2,
              Tp_WJIG_MIN: 19.3,
              Tp_WJIG_MAX: 28.4,
              Tp_Vibration: 867,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 357,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0716",
              In_InsertTime: "2023-01-05 09:41:21",
              In_Result: "OK",
              In_Outer_Width1: 223.97,
              In_Outer_Width2: 223.949,
              In_Internal_Width: 216.163,
              In_Thickness: 4.485,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 10:39:13",
              Tp_Result: "OK",
              Tp_JIG: 28.3,
              Tp_JIG_MIN: 27,
              Tp_JIG_MAX: 30.3,
              Tp_WJIG: 31.8,
              Tp_WJIG_MIN: 27,
              Tp_WJIG_MAX: 33.8,
              Tp_Vibration: 893,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 350,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0717",
              In_InsertTime: "2023-01-05 09:39:21",
              In_Result: "OK",
              In_Outer_Width1: 223.938,
              In_Outer_Width2: 223.86,
              In_Internal_Width: 216.186,
              In_Thickness: 4.484,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 10:09:49",
              Tp_Result: "OK",
              Tp_JIG: 21.5,
              Tp_JIG_MIN: 20.5,
              Tp_JIG_MAX: 23.4,
              Tp_WJIG: 20.4,
              Tp_WJIG_MIN: 17.3,
              Tp_WJIG_MAX: 22.8,
              Tp_Vibration: 894,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 354,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0718",
              In_InsertTime: "2023-01-05 09:40:21",
              In_Result: "OK",
              In_Outer_Width1: 223.849,
              In_Outer_Width2: 223.845,
              In_Internal_Width: 216.179,
              In_Thickness: 4.462,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 10:41:20",
              Tp_Result: "OK",
              Tp_JIG: 35.4,
              Tp_JIG_MIN: 34.1,
              Tp_JIG_MAX: 36.7,
              Tp_WJIG: 28.2,
              Tp_WJIG_MIN: 21.6,
              Tp_WJIG_MAX: 29.8,
              Tp_Vibration: 850,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 345,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0719",
              In_InsertTime: "2023-01-05 09:37:14",
              In_Result: "OK",
              In_Outer_Width1: 223.874,
              In_Outer_Width2: 223.882,
              In_Internal_Width: 216.163,
              In_Thickness: 4.468,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 10:16:38",
              Tp_Result: "OK",
              Tp_JIG: 32.7,
              Tp_JIG_MIN: 31.6,
              Tp_JIG_MAX: 34.9,
              Tp_WJIG: 25,
              Tp_WJIG_MIN: 20.8,
              Tp_WJIG_MAX: 26.9,
              Tp_Vibration: 795,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 336,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0720",
              In_InsertTime: "2023-01-05 09:35:00",
              In_Result: "OK",
              In_Outer_Width1: 223.884,
              In_Outer_Width2: 223.819,
              In_Internal_Width: 216.178,
              In_Thickness: 4.458,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 10:05:09",
              Tp_Result: "OK",
              Tp_JIG: 34.8,
              Tp_JIG_MIN: 33,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 20.2,
              Tp_WJIG_MIN: 16.5,
              Tp_WJIG_MAX: 22.5,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 349,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0721",
              In_InsertTime: "2023-01-05 09:38:54",
              In_Result: "OK",
              In_Outer_Width1: 223.854,
              In_Outer_Width2: 223.907,
              In_Internal_Width: 216.156,
              In_Thickness: 4.45,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 10:27:22",
              Tp_Result: "OK",
              Tp_JIG: 31.8,
              Tp_JIG_MIN: 31.6,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 30.2,
              Tp_WJIG_MIN: 23.2,
              Tp_WJIG_MAX: 33.1,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 355,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0722",
              In_InsertTime: "2023-01-05 09:40:33",
              In_Result: "OK",
              In_Outer_Width1: 223.886,
              In_Outer_Width2: 223.893,
              In_Internal_Width: 216.163,
              In_Thickness: 4.495,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 10:18:41",
              Tp_Result: "OK",
              Tp_JIG: 36.6,
              Tp_JIG_MIN: 35.1,
              Tp_JIG_MAX: 38.9,
              Tp_WJIG: 30,
              Tp_WJIG_MIN: 25.3,
              Tp_WJIG_MAX: 32.4,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 353,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0723",
              In_InsertTime: "2023-01-05 09:40:11",
              In_Result: "OK",
              In_Outer_Width1: 223.743,
              In_Outer_Width2: 223.754,
              In_Internal_Width: 216.168,
              In_Thickness: 4.465,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 10:38:08",
              Tp_Result: "OK",
              Tp_JIG: 52.2,
              Tp_JIG_MIN: 51.4,
              Tp_JIG_MAX: 53.9,
              Tp_WJIG: 35.3,
              Tp_WJIG_MIN: 29.7,
              Tp_WJIG_MAX: 38.3,
              Tp_Vibration: 893,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 344,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0724",
              In_InsertTime: "2023-01-05 09:36:56",
              In_Result: "OK",
              In_Outer_Width1: 224.227,
              In_Outer_Width2: 223.941,
              In_Internal_Width: 216.285,
              In_Thickness: 4.476,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 10:07:08",
              Tp_Result: "OK",
              Tp_JIG: 16,
              Tp_JIG_MIN: 14.5,
              Tp_JIG_MAX: 17.8,
              Tp_WJIG: 27.6,
              Tp_WJIG_MIN: 24.4,
              Tp_WJIG_MAX: 31,
              Tp_Vibration: 897,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 368,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0725",
              In_InsertTime: "2023-01-05 09:44:56",
              In_Result: "OK",
              In_Outer_Width1: 223.834,
              In_Outer_Width2: 223.957,
              In_Internal_Width: 216.216,
              In_Thickness: 4.47,
              Tp_No: 33,
              Tp_InsertTime: "2023-01-05 10:46:01",
              Tp_Result: "OK",
              Tp_JIG: 25,
              Tp_JIG_MIN: 23.3,
              Tp_JIG_MAX: 26.4,
              Tp_WJIG: 38.3,
              Tp_WJIG_MIN: 32.9,
              Tp_WJIG_MAX: 40,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 373,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0726",
              In_InsertTime: "2023-01-05 09:48:18",
              In_Result: "OK",
              In_Outer_Width1: 223.92,
              In_Outer_Width2: 223.839,
              In_Internal_Width: 216.175,
              In_Thickness: 4.453,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 10:49:00",
              Tp_Result: "OK",
              Tp_JIG: 33.3,
              Tp_JIG_MIN: 31.5,
              Tp_JIG_MAX: 34.4,
              Tp_WJIG: 25.3,
              Tp_WJIG_MIN: 19.6,
              Tp_WJIG_MAX: 26.7,
              Tp_Vibration: 895,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 366,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0727",
              In_InsertTime: "2023-01-05 09:44:23",
              In_Result: "OK",
              In_Outer_Width1: 223.854,
              In_Outer_Width2: 223.802,
              In_Internal_Width: 216.137,
              In_Thickness: 4.453,
              Tp_No: 12,
              Tp_InsertTime: "2023-01-05 10:12:43",
              Tp_Result: "OK",
              Tp_JIG: 14.9,
              Tp_JIG_MIN: 13.7,
              Tp_JIG_MAX: 16.7,
              Tp_WJIG: 27.7,
              Tp_WJIG_MIN: 25.4,
              Tp_WJIG_MAX: 31.4,
              Tp_Vibration: 896,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 363,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0728",
              In_InsertTime: "2023-01-05 09:42:35",
              In_Result: "OK",
              In_Outer_Width1: 223.789,
              In_Outer_Width2: 223.894,
              In_Internal_Width: 216.126,
              In_Thickness: 4.453,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 10:44:52",
              Tp_Result: "OK",
              Tp_JIG: 37.8,
              Tp_JIG_MIN: 36.1,
              Tp_JIG_MAX: 39.2,
              Tp_WJIG: 35.2,
              Tp_WJIG_MIN: 30.1,
              Tp_WJIG_MAX: 37.3,
              Tp_Vibration: 894,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 360,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0729",
              In_InsertTime: "2023-01-05 09:41:52",
              In_Result: "OK",
              In_Outer_Width1: 224.005,
              In_Outer_Width2: 224.154,
              In_Internal_Width: 216.243,
              In_Thickness: 4.479,
              Tp_No: 64,
              Tp_InsertTime: "2023-01-05 10:21:08",
              Tp_Result: "OK",
              Tp_JIG: 12.3,
              Tp_JIG_MIN: 11,
              Tp_JIG_MAX: 13.9,
              Tp_WJIG: 21.9,
              Tp_WJIG_MIN: 14.7,
              Tp_WJIG_MAX: 24,
              Tp_Vibration: 877,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 372,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0730",
              In_InsertTime: "2023-01-05 09:47:04",
              In_Result: "OK",
              In_Outer_Width1: 224.188,
              In_Outer_Width2: 223.93,
              In_Internal_Width: 216.243,
              In_Thickness: 4.481,
              Tp_No: 72,
              Tp_InsertTime: "2023-01-05 10:43:48",
              Tp_Result: "OK",
              Tp_JIG: 52.7,
              Tp_JIG_MIN: 51.7,
              Tp_JIG_MAX: 53.9,
              Tp_WJIG: 41.3,
              Tp_WJIG_MIN: 35.5,
              Tp_WJIG_MAX: 44.4,
              Tp_Vibration: 894,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 361,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0731",
              In_InsertTime: "2023-01-05 09:42:01",
              In_Result: "OK",
              In_Outer_Width1: 223.858,
              In_Outer_Width2: 223.96,
              In_Internal_Width: 216.211,
              In_Thickness: 4.478,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 10:11:44",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 33.9,
              Tp_JIG_MAX: 37,
              Tp_WJIG: 20.5,
              Tp_WJIG_MIN: 18.4,
              Tp_WJIG_MAX: 23.1,
              Tp_Vibration: 879,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 374,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0732",
              In_InsertTime: "2023-01-05 09:59:31",
              In_Result: "OK",
              In_Outer_Width1: 224.118,
              In_Outer_Width2: 224.212,
              In_Internal_Width: 216.234,
              In_Thickness: 4.486,
              Tp_No: 14,
              Tp_InsertTime: "2023-01-05 10:16:20",
              Tp_Result: "OK",
              Tp_JIG: 34.5,
              Tp_JIG_MIN: 32.8,
              Tp_JIG_MAX: 34.8,
              Tp_WJIG: 20.9,
              Tp_WJIG_MIN: 17.8,
              Tp_WJIG_MAX: 23.3,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 389,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0733",
              In_InsertTime: "2023-01-05 10:01:49",
              In_Result: "OK",
              In_Outer_Width1: 224.125,
              In_Outer_Width2: 224.139,
              In_Internal_Width: 216.238,
              In_Thickness: 4.485,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 10:29:01",
              Tp_Result: "OK",
              Tp_JIG: 27.7,
              Tp_JIG_MIN: 26,
              Tp_JIG_MAX: 30,
              Tp_WJIG: 19.3,
              Tp_WJIG_MIN: 15.5,
              Tp_WJIG_MAX: 20.3,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 388,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0734",
              In_InsertTime: "2023-01-05 10:01:39",
              In_Result: "OK",
              In_Outer_Width1: 223.761,
              In_Outer_Width2: 223.829,
              In_Internal_Width: 216.11,
              In_Thickness: 4.46,
              Tp_No: 13,
              Tp_InsertTime: "2023-01-05 10:21:02",
              Tp_Result: "OK",
              Tp_JIG: 21.6,
              Tp_JIG_MIN: 20.4,
              Tp_JIG_MAX: 23.6,
              Tp_WJIG: 24.7,
              Tp_WJIG_MIN: 22,
              Tp_WJIG_MAX: 27.9,
              Tp_Vibration: 903,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 376,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0735",
              In_InsertTime: "2023-01-05 09:59:49",
              In_Result: "OK",
              In_Outer_Width1: 223.832,
              In_Outer_Width2: 223.898,
              In_Internal_Width: 216.119,
              In_Thickness: 4.466,
              Tp_No: 24,
              Tp_InsertTime: "2023-01-05 10:34:12",
              Tp_Result: "OK",
              Tp_JIG: 33.6,
              Tp_JIG_MIN: 27.6,
              Tp_JIG_MAX: 35.1,
              Tp_WJIG: 30.1,
              Tp_WJIG_MIN: 24.6,
              Tp_WJIG_MAX: 32.3,
              Tp_Vibration: 862,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 377,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0736",
              In_InsertTime: "2023-01-05 09:59:58",
              In_Result: "OK",
              In_Outer_Width1: 223.778,
              In_Outer_Width2: 223.871,
              In_Internal_Width: 216.102,
              In_Thickness: 4.461,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 10:44:55",
              Tp_Result: "OK",
              Tp_JIG: 28.3,
              Tp_JIG_MIN: 26.9,
              Tp_JIG_MAX: 30.3,
              Tp_WJIG: 33.6,
              Tp_WJIG_MIN: 30,
              Tp_WJIG_MAX: 36.3,
              Tp_Vibration: 893,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 130,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0737",
              In_InsertTime: "2023-01-05 08:30:05",
              In_Result: "OK",
              In_Outer_Width1: 223.848,
              In_Outer_Width2: 223.85,
              In_Internal_Width: 216.125,
              In_Thickness: 4.455,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 08:55:27",
              Tp_Result: "OK",
              Tp_JIG: 36.2,
              Tp_JIG_MIN: 34.7,
              Tp_JIG_MAX: 39,
              Tp_WJIG: 29.8,
              Tp_WJIG_MIN: 25.3,
              Tp_WJIG_MAX: 32.7,
              Tp_Vibration: 859,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 378,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0738",
              In_InsertTime: "2023-01-05 10:00:06",
              In_Result: "OK",
              In_Outer_Width1: 223.92,
              In_Outer_Width2: 223.946,
              In_Internal_Width: 216.22,
              In_Thickness: 4.494,
              Tp_No: 11,
              Tp_InsertTime: "2023-01-05 10:17:20",
              Tp_Result: "OK",
              Tp_JIG: 35.1,
              Tp_JIG_MIN: 33.8,
              Tp_JIG_MAX: 37,
              Tp_WJIG: 21.6,
              Tp_WJIG_MIN: 19.4,
              Tp_WJIG_MAX: 24.7,
              Tp_Vibration: 857,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 1302,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0739",
              In_InsertTime: "2023-01-05 16:05:54",
              In_Result: "OK",
              In_Outer_Width1: 223.993,
              In_Outer_Width2: 223.955,
              In_Internal_Width: 216.164,
              In_Thickness: 4.474,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 16:32:20",
              Tp_Result: "OK",
              Tp_JIG: 32.2,
              Tp_JIG_MIN: 30.9,
              Tp_JIG_MAX: 34.2,
              Tp_WJIG: 29.3,
              Tp_WJIG_MIN: 24.2,
              Tp_WJIG_MAX: 31,
              Tp_Vibration: 791,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 338,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0740",
              In_InsertTime: "2023-01-05 09:35:41",
              In_Result: "OK",
              In_Outer_Width1: 223.838,
              In_Outer_Width2: 223.799,
              In_Internal_Width: 216.242,
              In_Thickness: 4.446,
              Tp_No: 31,
              Tp_InsertTime: "2023-01-05 10:35:39",
              Tp_Result: "OK",
              Tp_JIG: 35.2,
              Tp_JIG_MIN: 34,
              Tp_JIG_MAX: 36.5,
              Tp_WJIG: 34.5,
              Tp_WJIG_MIN: 27.5,
              Tp_WJIG_MAX: 36.6,
              Tp_Vibration: 794,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 379,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0741",
              In_InsertTime: "2023-01-05 10:00:15",
              In_Result: "OK",
              In_Outer_Width1: 224.189,
              In_Outer_Width2: 223.881,
              In_Internal_Width: 216.241,
              In_Thickness: 4.428,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 10:50:31",
              Tp_Result: "OK",
              Tp_JIG: 37.8,
              Tp_JIG_MIN: 36.4,
              Tp_JIG_MAX: 39.2,
              Tp_WJIG: 36.8,
              Tp_WJIG_MIN: 30.4,
              Tp_WJIG_MAX: 37.8,
              Tp_Vibration: 894,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 235,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0742",
              In_InsertTime: "2023-01-05 09:02:28",
              In_Result: "OK",
              In_Outer_Width1: 223.863,
              In_Outer_Width2: 223.88,
              In_Internal_Width: 216.192,
              In_Thickness: 4.429,
              Tp_No: 32,
              Tp_InsertTime: "2023-01-05 10:05:12",
              Tp_Result: "OK",
              Tp_JIG: 40,
              Tp_JIG_MIN: 38.4,
              Tp_JIG_MAX: 41.3,
              Tp_WJIG: 39.6,
              Tp_WJIG_MIN: 31.5,
              Tp_WJIG_MAX: 39.6,
              Tp_Vibration: 865,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 267,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0743",
              In_InsertTime: "2023-01-05 09:12:37",
              In_Result: "OK",
              In_Outer_Width1: 223.887,
              In_Outer_Width2: 223.959,
              In_Internal_Width: 216.237,
              In_Thickness: 4.454,
              Tp_No: 23,
              Tp_InsertTime: "2023-01-05 09:50:39",
              Tp_Result: "OK",
              Tp_JIG: 33.2,
              Tp_JIG_MIN: 33.1,
              Tp_JIG_MAX: 36.6,
              Tp_WJIG: 32.6,
              Tp_WJIG_MIN: 25.5,
              Tp_WJIG_MAX: 35.2,
              Tp_Vibration: 860,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 384,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0744",
              In_InsertTime: "2023-01-05 10:00:57",
              In_Result: "OK",
              In_Outer_Width1: 223.965,
              In_Outer_Width2: 223.959,
              In_Internal_Width: 216.205,
              In_Thickness: 4.436,
              Tp_No: 62,
              Tp_InsertTime: "2023-01-05 10:27:57",
              Tp_Result: "OK",
              Tp_JIG: 32.4,
              Tp_JIG_MIN: 31.3,
              Tp_JIG_MAX: 34.7,
              Tp_WJIG: 34.7,
              Tp_WJIG_MIN: 30.3,
              Tp_WJIG_MAX: 36.4,
              Tp_Vibration: 869,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 321,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0745",
              In_InsertTime: "2023-01-05 09:29:47",
              In_Result: "OK",
              In_Outer_Width1: 224.156,
              In_Outer_Width2: 223.859,
              In_Internal_Width: 216.203,
              In_Thickness: 4.431,
              Tp_No: 73,
              Tp_InsertTime: "2023-01-05 10:27:54",
              Tp_Result: "OK",
              Tp_JIG: 28.5,
              Tp_JIG_MIN: 27.2,
              Tp_JIG_MAX: 30.5,
              Tp_WJIG: 35.1,
              Tp_WJIG_MIN: 30.4,
              Tp_WJIG_MAX: 37.1,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 390,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0746",
              In_InsertTime: "2023-01-05 10:02:02",
              In_Result: "OK",
              In_Outer_Width1: 223.892,
              In_Outer_Width2: 223.887,
              In_Internal_Width: 216.233,
              In_Thickness: 4.466,
              Tp_No: 34,
              Tp_InsertTime: "2023-01-05 10:54:36",
              Tp_Result: "OK",
              Tp_JIG: 33.3,
              Tp_JIG_MIN: 31.4,
              Tp_JIG_MAX: 33.9,
              Tp_WJIG: 31.1,
              Tp_WJIG_MIN: 25.8,
              Tp_WJIG_MAX: 32.7,
              Tp_Vibration: 892,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 316,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0747",
              In_InsertTime: "2023-01-05 09:28:25",
              In_Result: "OK",
              In_Outer_Width1: 223.811,
              In_Outer_Width2: 223.74,
              In_Internal_Width: 216.188,
              In_Thickness: 4.44,
              Tp_No: 63,
              Tp_InsertTime: "2023-01-05 10:07:24",
              Tp_Result: "OK",
              Tp_JIG: 36.2,
              Tp_JIG_MIN: 34.6,
              Tp_JIG_MAX: 38.9,
              Tp_WJIG: 37.8,
              Tp_WJIG_MIN: 31.3,
              Tp_WJIG_MAX: 38.8,
              Tp_Vibration: 870,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
            {
              No: 333,
              INDATE: "20230105",
              ID_No: "21100863A 2CR0748",
              In_InsertTime: "2023-01-05 09:33:53",
              In_Result: "OK",
              In_Outer_Width1: 224.193,
              In_Outer_Width2: 224.061,
              In_Internal_Width: 216.29,
              In_Thickness: 4.431,
              Tp_No: 61,
              Tp_InsertTime: "2023-01-05 10:12:04",
              Tp_Result: "OK",
              Tp_JIG: 26.2,
              Tp_JIG_MIN: 24.9,
              Tp_JIG_MAX: 29.6,
              Tp_WJIG: 29.2,
              Tp_WJIG_MIN: 23.6,
              Tp_WJIG_MAX: 29.7,
              Tp_Vibration: 855,
              Vi_InsertTime: null,
              Vi_ReadTime: null,
              Vi_Result: null,
              Vi_SloatHole_1X: null,
              Vi_SloatHole_1Y: null,
              Vi_SloatHole_2X: null,
              Vi_SloatHole_2Y: null,
              Vi_SloatHole_3X: null,
              Vi_SloatHole_3Y: null,
              Vi_SloatHole_4X: null,
              Vi_SloatHole_4Y: null,
              Vi_SloatHole_5X: null,
              Vi_SloatHole_5Y: null,
              Vi_SloatHole_6X: null,
              Vi_SloatHole_6Y: null,
              Vi_CutLen_1: null,
              Vi_CutLen_2: null,
              Vi_CutLen_3: null,
              Vi_LenQR_1: null,
              Vi_LenQR_2: null,
              Vi_LenQR_3: null,
              Vi_Cut_1: null,
              Vi_Cut_2: null,
              Vi_Cut_3: null,
              Vi_Len_1: null,
              Vi_Len_2: null,
              Vi_Len_3: null,
              Vi_HoleT_1: null,
              Vi_HoleT_2: null,
              Vi_HoleT_3: null,
              Vi_HoleT_4: null,
              Vi_HoleL_1: null,
              Vi_HoleL_2: null,
              Vi_HoleL_3: null,
              Vi_Cut_Outer_Width_1: null,
              Vi_Cut_Outer_Width_2: null,
              Vi_Item_Internal_WidthQR_1: null,
              Vi_Item_Internal_WidthQR_2: null,
              Vi_Item_Outer_WidthQR_1: null,
              Vi_Item_Outer_WidthQR_2: null,
              Vi_Item_Internal_Width_1: null,
              Vi_Item_Internal_Width_2: null,
              Vi_Item_Outer_Width_1: null,
              Vi_Item_Outer_Width_2: null,
              Vi_42: null,
              Vi_43: null,
              Vi_44: null,
              Vi_45: null,
              Vi_46: null,
              Vi_47: null,
              Vi_48: null,
              Vi_49: null,
              Vi_50: null,
              Vi_Front_Width_1: null,
              Vi_Front_Width_2: null,
              Vi_Front_Width_3: null,
              Vi_Front_Width_4: null,
              Vi_Front_Width_5: null,
              Vi_Front_Width_6: null,
              Vi_Behind_Width_1: null,
              Vi_Behind_Width_2: null,
              Vi_Behind_Width_3: null,
              Vi_Behind_Width_4: null,
              Vi_Behind_Width_5: null,
              Vi_Behind_Width_6: null,
              Vi_ExtraField_1: null,
              Vi_ExtraField_2: null,
              Vi_ExtraField_3: null,
              Vi_ExtraField_4: null,
              Vi_ExtraField_5: null,
              Vi_ExtraField_6: null,
              Vi_ExtraField_7: null,
              Vi_ExtraField_8: null,
              Vi_ExtraField_9: null,
              Vi_ExtraField_10: null,
              Vi_ExtraField_11: null,
              Vi_ExtraField_12: null,
              Vi_ExtraField_13: null,
              Vi_ExtraField_14: null,
              Vi_ExtraField_15: null,
              Vi_ExtraField_16: null,
              Vi_ExtraField_17: null,
              Vi_ExtraField_18: null,
              Vi_ExtraField_19: null,
              Vi_ExtraField_20: null,
              Vi_RL1: null,
              Vi_RL2: null,
              Vi_RL3: null,
              Vi_RL4: null,
              Vi_RL5: null,
              Vi_RR1: null,
              Vi_RR2: null,
              Vi_RR3: null,
              Vi_RR4: null,
              Vi_RR5: null,
              Vi_FL1: null,
              Vi_FL2: null,
              Vi_FL3: null,
              Vi_FL4: null,
              Vi_FL5: null,
              Vi_FR1: null,
              Vi_FR2: null,
              Vi_FR3: null,
              Vi_FR4: null,
              Vi_FR5: null,
              Pass_FINYN: null,
            },
          ],
        },
        {
          TableName: "Table2",
          ColumnCount: 6,
          RowCount: 1,
          TotalRowCount: null,
          Columns: [
            {
              ColumnName: "in_ok",
              DataType: "Int32",
            },
            {
              ColumnName: "in_ng1",
              DataType: "Int32",
            },
            {
              ColumnName: "in_ng2",
              DataType: "Int32",
            },
            {
              ColumnName: "out_ok1",
              DataType: "Int32",
            },
            {
              ColumnName: "out_ok2",
              DataType: "Int32",
            },
            {
              ColumnName: "out_ng",
              DataType: "Int32",
            },
          ],
          Rows: [
            {
              in_ok: 404,
              in_ng1: 0,
              in_ng2: 0,
              out_ok1: 413,
              out_ok2: 393,
              out_ng: 0,
            },
          ],
        },
        {
          TableName: "Table3",
          ColumnCount: 1,
          RowCount: 0,
          TotalRowCount: null,
          Columns: [
            {
              ColumnName: "msg",
              DataType: "String",
            },
          ],
          Rows: [],
        },
        {
          TableName: "Table4",
          ColumnCount: 4,
          RowCount: 28,
          TotalRowCount: null,
          Columns: [
            {
              ColumnName: "Tp_No",
              DataType: "Int32",
            },
            {
              ColumnName: "ID_No",
              DataType: "String",
            },
            {
              ColumnName: "itemnm",
              DataType: "String",
            },
            {
              ColumnName: "qty",
              DataType: "Decimal",
            },
          ],
          Rows: [
            {
              Tp_No: 11,
              ID_No: "21100863A 35P2837",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 75,
            },
            {
              Tp_No: 12,
              ID_No: "21100863A 35P2817",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 75,
            },
            {
              Tp_No: 13,
              ID_No: "21100863A 35P2848",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 74,
            },
            {
              Tp_No: 14,
              ID_No: "21100863A 35P2776",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 75,
            },
            {
              Tp_No: 21,
              ID_No: "21100863A 35P2777",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 75,
            },
            {
              Tp_No: 22,
              ID_No: "21100863A 35P2856",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 72,
            },
            {
              Tp_No: 23,
              ID_No: "21100863A 35P2858",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 76,
            },
            {
              Tp_No: 24,
              ID_No: "21100863A 35P2857",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 75,
            },
            {
              Tp_No: 31,
              ID_No: "21100863A 35P2814",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 76,
            },
            {
              Tp_No: 32,
              ID_No: "21100863A 35P2842",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 78,
            },
            {
              Tp_No: 33,
              ID_No: "21100863A 35P2788",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 75,
            },
            {
              Tp_No: 34,
              ID_No: "21100863A 35P2832",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 77,
            },
            {
              Tp_No: 41,
              ID_No: "21100863A 35P2784",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 85,
            },
            {
              Tp_No: 42,
              ID_No: "21100863A 35P2829",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 87,
            },
            {
              Tp_No: 43,
              ID_No: "21100863A 35P2840",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 78,
            },
            {
              Tp_No: 44,
              ID_No: "21100863A 35P2805",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 85,
            },
            {
              Tp_No: 51,
              ID_No: "21100863A 35O2210",
              itemnm: "",
              qty: 0,
            },
            {
              Tp_No: 52,
              ID_No: "21100863A 35O2230",
              itemnm: "",
              qty: 0,
            },
            {
              Tp_No: 53,
              ID_No: "21100863A 35O2212",
              itemnm: "",
              qty: 0,
            },
            {
              Tp_No: 54,
              ID_No: "21100863A 35P0061",
              itemnm: "",
              qty: 0,
            },
            {
              Tp_No: 61,
              ID_No: "21100863A 35O1853",
              itemnm: "",
              qty: 0,
            },
            {
              Tp_No: 62,
              ID_No: "21100863A 35P2862",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 81,
            },
            {
              Tp_No: 63,
              ID_No: "21100863A 35P0516",
              itemnm: "",
              qty: 0,
            },
            {
              Tp_No: 64,
              ID_No: "21100863A 35P2859",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 91,
            },
            {
              Tp_No: 71,
              ID_No: "21100863A 35O2244",
              itemnm: "COOLING PLATE-LWR_3P8S_TP",
              qty: 1,
            },
            {
              Tp_No: 72,
              ID_No: "21100863A 35O2150",
              itemnm: "",
              qty: 0,
            },
            {
              Tp_No: 73,
              ID_No: "21100863A 35O0736",
              itemnm: "",
              qty: 0,
            },
            {
              Tp_No: 74,
              ID_No: "21100863A 35O2175",
              itemnm: "",
              qty: 0,
            },
          ],
        },
      ],
    };
    if (data.isSuccess === true) {
      const totalRowCnt0 = data.tables[0].RowCount;
      const totalRowCnt1 = data.tables[1].RowCount;
      const totalRowCnt2 = data.tables[2].RowCount;
      const totalRowCnt3 = data.tables[3].RowCount;
      const totalRowCnt4 = data.tables[4].RowCount;
      const row0 = data.tables[0].Rows[0];
      const rows1 = data.tables[1].Rows;
      const row2 = data.tables[2].Rows[0];
      const row3 = data.tables[3].Rows[0];
      const rows4 = data.tables[4].Rows;

      if (totalRowCnt0 > 0 && totalRowCnt2 > 0) {
        const row = { ...row0, ...row2 };
        setMainDataResult(row);
      }

      if (totalRowCnt1 > 0) setDetailDataResult(rows1);
      if (totalRowCnt4 > 0) setPanelDataResult(rows4);
    } else {
      console.log("[오류 발생]");
      console.log(data);
    }
  };

  useEffect(() => {
    // 최초 조회
    fetchMainGrid();
  }, []);

  useEffect(() => {
    if (actions["Animation"]) {
      actions["Animation"].play();
    }
  }, [actions]);

  // useEffect(() => {
  //   if (actions["Animation"]) {
  //     if (isAnimated) {
  //       actions["Animation"].play();
  //     } else {
  //       actions["Animation"].stop();
  //     }
  //   }
  // }, [actions, isAnimated]);

  const onClickTcpPanelDetail = (tcpNumber: any) => {
    if (tcpNumber == "") {
      setIsVisonClicking("");
    } else if (tcpNumber == "1") {
      setIsInfoClicking("");
    } else if (tcpNumber == "2") {
      setIsQtyClicking("");
    } else {
      setIsClicking("");
      setDetail(tcpNumber);
    }
  };

  const onClickTcpPanelDetail2 = (tcpNumber: any) => {
    if (tcpNumber == "비젼") {
      setIsVisonClicking(tcpNumber);
    } else if (tcpNumber == "세척기" || tcpNumber == "건조기") {
      setIsInfoClicking(tcpNumber);
    } else if (tcpNumber == "재고부하율" || tcpNumber == "안전재고미달") {
      setIsQtyClicking(tcpNumber);
    } else {
      setIsClicking(tcpNumber);
    }
  };

  return (
    <group dispose={null}>
      <primitive object={scene} ref={ref} {...props} />
      {mainDataResult && props.active == true ? (
        <>
          {/* TCP (1~7구역) */}
          {[1, 2, 3, 4, 5, 6, 7].map((num: number) => {
            return (
              <>
                {/* 메인 판넬 */}
                <TcpPanel
                  tcpNumber={num}
                  position={tcpPosition}
                  data={mainDataResult}
                  onClickDetail={onClickTcpPanelDetail}
                  onClickState={onClickTcpPanelDetail2}
                  isClicking={isClicking}
                ></TcpPanel>
                {/* 상세 판넬 */}
                {detailDataResult && detail && (
                  <TcpDetailPanel
                    tcpNumber={num}
                    selectedTcpNumber={detail}
                    position={tcpDetailPosition}
                    data={detailDataResult}
                    PanelData={PanelDataResult}
                    onClickDetail={onClickTcpPanelDetail}
                  ></TcpDetailPanel>
                )}
              </>
            );
          })}

          {/* 비전 */}
          <Marker rotation={[0, 0, 0]} position={visionPosition}>
            <DataContainer style={{ width: "100px", height: "45px" }}>
              <PanelTable
                label={`비젼`}
                value={mainDataResult[`OP6_배출검사_State`]}
                onClickDetail={() => setIsVisionDetailShowed(true)}
                onClickState={onClickTcpPanelDetail2}
                isClicking={isVisionClicking}
              ></PanelTable>
            </DataContainer>
          </Marker>
          {detailDataResult && isVisionDetailShowed && (
            <VisionDetailPanel
              position={visionDetailPosition}
              data={detailDataResult}
              isSelected={isVisionDetailShowed}
              onClickDetail={() => setIsVisionDetailShowed(false)}
              onClickState={onClickTcpPanelDetail}
            ></VisionDetailPanel>
          )}

          {/* 세척 및 건조기 */}
          <Marker rotation={[0, 0, 0]} position={dryerPosition}>
            <DataContainer
              style={{
                width: "120px",
                height: "80px",
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)" /* 1열 */,
                gridTemplateRows: "repeat(2, 1fr)" /* 2행 */,
              }}
            >
              <PanelTable
                style={{ width: "110px" }}
                label={`세척기`}
                value={mainDataResult[`Washing_State`]}
                onClickDetail={() => {
                  setIsInfonDetailShowed(true);
                  setIsInfonDetailShowed2(false);
                }}
                onClickState={onClickTcpPanelDetail2}
                isClicking={isInfoClicking}
              ></PanelTable>
              <PanelTable
                style={{ width: "110px" }}
                label={`건조기`}
                value={mainDataResult[`AirBlower_State`]}
                onClickDetail={() => {
                  setIsInfonDetailShowed2(true);
                  setIsInfonDetailShowed(false);
                }}
                onClickState={onClickTcpPanelDetail2}
                isClicking={isInfoClicking}
              ></PanelTable>
            </DataContainer>
          </Marker>
          {detailDataResult && isInfoDetailShowed && (
            <InfoDetailPanel
              position={InfoDetailPosition}
              data={detailDataResult}
              isSelected={isInfoDetailShowed}
              onClickDetail={() => {
                setIsInfonDetailShowed(false);
                setIsInfonDetailShowed2(false);
              }}
              onClickState={onClickTcpPanelDetail}
            ></InfoDetailPanel>
          )}
          {detailDataResult && isInfoDetailShowed2 && (
            <InfoDetailPanel2
              position={InfoDetailPosition}
              data={detailDataResult}
              isSelected={isInfoDetailShowed2}
              onClickDetail={() => {
                setIsInfonDetailShowed(false);
                setIsInfonDetailShowed2(false);
              }}
              onClickState={onClickTcpPanelDetail}
            ></InfoDetailPanel2>
          )}
          {/* 생산 */}
          <Marker rotation={[0, 0, 0]} position={outputPosition}>
            <DataContainer
              style={{
                width: "280px",
                height: "47px",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)" /* 1열 */,
                gridTemplateRows: "repeat(1, 1fr)" /* 3행 */,
              }}
            >
              <PanelTable
                label={`OK`}
                value={mainDataResult[`out_ok1`]}
                valueType="Number"
              ></PanelTable>
              <PanelTable
                label={`OK2`}
                value={mainDataResult[`out_ok2`]}
                valueType="Number"
              ></PanelTable>
              <PanelTable
                label={`회송`}
                value={mainDataResult[`out_ng`]}
                valueType="Number"
              ></PanelTable>
            </DataContainer>
          </Marker>
          {/* 생산 2*/}
          <Marker rotation={[0, 0, 0]} position={outputPosition2}>
            <DataContainer
              style={{
                width: "280px",
                height: "47px",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)" /* 1열 */,
                gridTemplateRows: "repeat(1, 1fr)" /* 3행 */,
              }}
            >
              <PanelTable
                label={`재고부하율`}
                value={70}
                valueType="Number"
                onClickDetail={() => {
                  setIsQtyDetailShowed(true);
                }}
                onClickState={onClickTcpPanelDetail2}
                isClicking={isQtyClicking}
                style={{ width: "130px" }}
              ></PanelTable>
              <PanelTable
                label={`안전재고미달`}
                value={20}
                valueType="Number"
                onClickDetail={() => {
                  setIsQtyDetailShowed(true);
                }}
                onClickState={onClickTcpPanelDetail2}
                isClicking={isQtyClicking}
                style={{ width: "130px" }}
              ></PanelTable>
            </DataContainer>
          </Marker>
          {detailDataResult && isQtyDetailShowed && (
            <QtyDetailPanel
              position={QtyDetailPosition}
              data={detailDataResult}
              isSelected={isQtyDetailShowed}
              onClickDetail={() => {
                setIsQtyDetailShowed(false);
              }}
              onClickState={onClickTcpPanelDetail}
            ></QtyDetailPanel>
          )}
        </>
      ) : (
        ""
      )}
    </group>
  );
};

function Marker({ children, ...props }: any) {
  const ref = useRef<any>();
  // This holds the local occluded state
  const [isOccluded, setOccluded] = useState();
  const [isInRange, setInRange] = useState<any>();
  const isVisible = isInRange && !isOccluded;
  // Test distance
  const vec = new THREE.Vector3();
  useFrame((state) => {
    if (ref.current != undefined) {
      const range =
        state.camera.position.distanceTo(ref.current.getWorldPosition(vec)) <=
        10;
      if (range !== isInRange) setInRange(range);
    }
  });
  return (
    <group>
      <Html
        transform
        occlude
        onOcclude={setOccluded}
        style={{
          transition: "all 0.2s",
        }}
        {...props}
      >
        {children}
      </Html>
    </group>
  );
}

export function Effects() {
  return (
    <EffectComposer disableNormalPass>
      <Bloom
        luminanceThreshold={0.2}
        mipmapBlur
        luminanceSmoothing={0}
        intensity={0.2}
      />
    </EffectComposer>
  );
}

type TTcpPanel = {
  tcpNumber: number;
  position: { [key: number]: number[] };
  data: any;
  onClickDetail: (n: number) => void;
  onClickState: (n: string) => void;
  isClicking: string;
};

const TcpPanel = ({
  tcpNumber,
  position,
  data,
  onClickDetail,
  onClickState,
  isClicking,
}: TTcpPanel) => {
  return (
    <Marker rotation={[0, 0, 0]} position={position[tcpNumber]}>
      <DataContainer
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)" /* 2열 */,
          gridTemplateRows: "repeat(2, 1fr)" /* 2행 */,
          width: "100px",
        }}
      >
        {[1, 2].map((num: number) => {
          const tcpNum = Number(String(tcpNumber) + String(num));
          return (
            <PanelTable
              label={`TPC${tcpNum}`}
              value={data[`TPC${tcpNum}_State`]}
              onClickDetail={() => onClickDetail(tcpNum)}
              onClickState={() => onClickState(`TPC${tcpNum}`)}
              isClicking={isClicking}
            ></PanelTable>
          );
        })}
      </DataContainer>
    </Marker>
  );
};

type TPanelTable = {
  label: string;
  value: number;
  valueType?: "State" | "Number";
  onClickDetail?: (n: any) => void;
  onClickState?: (n: string) => void;
  isClicking?: string;
  style?: any;
};
const PanelTable = ({
  label,
  value,
  valueType = "State",
  onClickDetail,
  onClickState,
  isClicking,
  style,
}: TPanelTable) => {
  const [isHovering, setIsHovering] = useState(0);

  return (
    <table
      onClick={() => {
        if (onClickDetail != null && onClickState != null) {
          onClickDetail("");
          onClickState(label);
        }
      }}
      onMouseOver={() => setIsHovering(1)}
      onMouseOut={() => setIsHovering(0)}
      style={{
        width: style != undefined ? style.width : "90px",
        height: "35px",
        backgroundColor:
          isHovering == 1 || isClicking == label ? "#3e80ed5e" : "",
        cursor: "Pointer",
      }}
    >
      <tbody>
        <tr>
          <th>{label}</th>
          <td>
            <span
              className={valueType === "Number" ? "number" : "sts sts" + value}
            >
              <span className="light"></span>

              {valueType === "Number"
                ? label == "재고부하율"
                  ? value + "%"
                  : label == "안전재고미달"
                  ? value + "개"
                  : value
                : Number(value) === 1
                ? "정상"
                : Number(value) === 2
                ? "통신 에러"
                : Number(value) === 3
                ? "작은 고장"
                : Number(value) === 4
                ? "큰 고장"
                : ""}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
type TTcpDetailPanel = {
  tcpNumber: number;
  position: any;
  selectedTcpNumber: number;
  data: any[];
  PanelData: any[];
  onClickDetail: (n: any) => void;
};
const TcpDetailPanel = ({
  position,
  tcpNumber,
  selectedTcpNumber,
  data,
  PanelData,
  onClickDetail,
}: TTcpDetailPanel) => {
  const tpData = data.filter((row: any) => row.Tp_No === selectedTcpNumber);
  const PnData = PanelData.filter(
    (row: any) => row.Tp_No === selectedTcpNumber
  );
  const categories = tpData.map((row: any) => row.Tp_InsertTime);
  const tpJig = tpData.map((row: any) => row.Tp_JIG);
  const tpJigMax = tpData.map((row: any) => row.Tp_JIG_MAX);
  const tpJigMin = tpData.map((row: any) => row.Tp_JIG_MIN);

  const tpWjig = tpData.map((row: any) => row.Tp_WJIG);
  const tpWjigMax = tpData.map((row: any) => row.Tp_WJIG_MAX);
  const tpWjigMin = tpData.map((row: any) => row.Tp_WJIG_MIN);

  const tpVibration = tpData.map((row: any) => row.Tp_Vibration);

  return selectedTcpNumber &&
    String(selectedTcpNumber).charAt(0) === String(tcpNumber) ? (
    <group position={position[tcpNumber]} rotation={[0, 0, 0]}>
      <Marker
        rotation={[0, 0, 0]}
        style={{
          opacity: 1,
        }}
      >
        <DataContainer
          onClick={() => onClickDetail(null)}
          style={{
            width: "900px",
            height: "370px",
            padding: "20px",
            backgroundColor: "rgb(16 16 16)",
          }}
        >
          {/* <p
            style={{
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            TPC {selectedTcpNumber}호기{" "}
            {tpData.length > 0 && (
              <>
                ({tpData[0].Tp_InsertTime} ~
                {tpData[tpData.length - 1].Tp_InsertTime})
              </>
            )}
          </p> */}
          <table className="tp-detail-tb">
            {/* <colgroup>
                <col width={"25%"}></col>
                <col width={"25%"}></col>
                <col width={"25%"}></col>
                <col width={"25%"}></col>
              </colgroup> */}
            <tbody>
              <tr>
                <th>탭핑센터 번호</th>
                <td>{PnData != null ? PnData[0].Tp_No : "-"}</td>
                <th>품목코드</th>
                <td>{PnData != null ? PnData[0].ID_No : "-"}</td>
              </tr>
              <tr>
                <th>일일 생산량 집계</th>
                <td>{PnData != null ? PnData[0].qty : "-"}</td>
                <th>품목명</th>
                <td>{PnData != null ? PnData[0].itemnm : "-"}</td>
                {/* <th>차트 기준일시</th>
                  <td>
                    {tpData[0].Tp_InsertTime} ~
                    {tpData[tpData.length - 1].Tp_InsertTime}
                  </td> */}
              </tr>
            </tbody>
          </table>
          <div style={{ width: "100%", height: "auto", display: "flex" }}>
            <Chart style={{ height: "230px" }}>
              <ChartTitle text="JIG 추이" />
              <ChartLegend position="bottom" orientation="horizontal" />

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={categories}
                  visible={false}
                />
              </ChartCategoryAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpJigMax}
                  name={"MAX"}
                  markers={{
                    size: 4,
                  }}
                />
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpJig}
                  name={"JIG"}
                  markers={{
                    size: 4,
                  }}
                />
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpJigMin}
                  name={"MIN"}
                  markers={{
                    size: 4,
                  }}
                />
              </ChartSeries>
            </Chart>
            <Chart style={{ height: "230px" }}>
              <ChartTitle text="WJIG 추이" />
              <ChartLegend position="bottom" orientation="horizontal" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={categories}
                  visible={false}
                />
              </ChartCategoryAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpWjigMax}
                  name={"MAX"}
                  markers={{
                    size: 4,
                  }}
                />
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpWjig}
                  name={"WJIG"}
                  markers={{
                    size: 4,
                  }}
                />
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpWjigMin}
                  name={"MIN"}
                  markers={{
                    size: 4,
                  }}
                />
              </ChartSeries>
            </Chart>
            <Chart style={{ height: "230px" }}>
              <ChartTitle text="진동 추이" />
              <ChartLegend position="bottom" orientation="horizontal" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={categories}
                  visible={false}
                />
              </ChartCategoryAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpVibration}
                  name={"진동"}
                  markers={{
                    size: 4,
                  }}
                />
              </ChartSeries>
            </Chart>
          </div>
          <p
            style={{
              textAlign: "right",
              fontSize: "11px",
            }}
          >
            {tpData.length > 0 && (
              <>(기준일시 : {convertDateToStrWithTime2(new Date())})</>
            )}
          </p>
        </DataContainer>
      </Marker>
    </group>
  ) : (
    <></>
  );
};

type TVisionDetailPanel = {
  position: any;
  isSelected: boolean;
  data: any[];
  onClickDetail: (n: any) => void;
  onClickState: (n: any) => void;
};
const VisionDetailPanel = ({
  position,
  isSelected,
  data,
  onClickDetail,
  onClickState,
}: TVisionDetailPanel) => {
  const tpData = data.filter((row: any) => row.Tp_No !== "");

  const categories = tpData.map((row: any) => row.Tp_InsertTime);
  const tpJig = tpData.map((row: any) => row.Tp_JIG);

  return isSelected ? (
    <group position={position} rotation={[0, 0, 0]}>
      <Marker rotation={[0, 0, 0]}>
        <DataContainer
          onClick={() => {
            onClickDetail(null);
            onClickState("");
          }}
          style={{
            width: "320px",
            height: "300px",
            padding: "20px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            비젼 불량 추이
            <br />
            {data.length > 0 && <>({convertDateToStrWithTime2(new Date())})</>}
          </p>
          <div style={{ width: "100%", height: "auto", display: "flex" }}>
            <Chart style={{ height: "200px" }}>
              {/* <ChartTitle text="Vision 불량 추이" /> */}
              <ChartLegend position="bottom" orientation="horizontal" />

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={categories}
                  visible={false}
                />
              </ChartCategoryAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpJig}
                  name={"불량"}
                  markers={{
                    size: 4,
                  }}
                />
              </ChartSeries>
            </Chart>
          </div>
        </DataContainer>
      </Marker>
    </group>
  ) : (
    <></>
  );
};

type TInfoDetailPanel = {
  position: any;
  isSelected: boolean;
  data: any[];
  onClickDetail: (n: any) => void;
  onClickState: (n: any) => void;
};
const InfoDetailPanel = ({
  position,
  isSelected,
  data,
  onClickDetail,
  onClickState,
}: TInfoDetailPanel) => {
  const tpData = data.filter((row: any) => row.Tp_No !== "");

  const categories = tpData.map((row: any) => row.Tp_InsertTime);
  const tpJig = tpData.map((row: any) => row.Tp_WJIG);

  return isSelected ? (
    <group position={position} rotation={[0, 0, 0]}>
      <Marker
        rotation={[0, 0, 0]}
        style={{
          opacity: 1,
        }}
      >
        <DataContainer
          onClick={() => {
            onClickDetail(null);
            onClickState("1");
          }}
          style={{
            width: "320px",
            height: "300px",
            padding: "20px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            세척 압력 추이
            <br />
            {data.length > 0 && <>({convertDateToStrWithTime2(new Date())})</>}
          </p>
          <div style={{ width: "100%", height: "auto", display: "flex" }}>
            <Chart style={{ height: "200px" }}>
              {/* <ChartTitle text="Vision 불량 추이" /> */}
              <ChartLegend position="bottom" orientation="horizontal" />

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={categories}
                  visible={false}
                />
              </ChartCategoryAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpJig}
                  name={"세척"}
                  markers={{
                    size: 4,
                  }}
                />
              </ChartSeries>
            </Chart>
          </div>
        </DataContainer>
      </Marker>
    </group>
  ) : (
    <></>
  );
};

type TInfoDetailPanel2 = {
  position: any;
  isSelected: boolean;
  data: any[];
  onClickDetail: (n: any) => void;
  onClickState: (n: any) => void;
};
const InfoDetailPanel2 = ({
  position,
  isSelected,
  data,
  onClickDetail,
  onClickState,
}: TInfoDetailPanel2) => {
  const tpData = data.filter((row: any) => row.Tp_No !== "");

  const categories = tpData.map((row: any) => row.Tp_InsertTime);
  const tpJig = tpData.map((row: any) => row.In_Thickness);

  return isSelected ? (
    <group position={position} rotation={[0, 0, 0]}>
      <Marker
        rotation={[0, 0, 0]}
        style={{
          opacity: 1,
        }}
      >
        <DataContainer
          onClick={() => {
            onClickDetail(null);
            onClickState("1");
          }}
          style={{
            width: "320px",
            height: "300px",
            padding: "20px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            건조 온도 추이
            <br />
            {data.length > 0 && <>({convertDateToStrWithTime2(new Date())})</>}
          </p>
          <div style={{ width: "100%", height: "auto", display: "flex" }}>
            <Chart style={{ height: "200px" }}>
              {/* <ChartTitle text="Vision 불량 추이" /> */}
              <ChartLegend position="bottom" orientation="horizontal" />

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={categories}
                  visible={false}
                />
              </ChartCategoryAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type="line"
                  tooltip={{ visible: true }}
                  data={tpJig}
                  name={"건조"}
                  markers={{
                    size: 4,
                  }}
                />
              </ChartSeries>
            </Chart>
          </div>
        </DataContainer>
      </Marker>
    </group>
  ) : (
    <></>
  );
};

type TQtyDetailPanel = {
  position: any;
  isSelected: boolean;
  data: any[];
  onClickDetail: (n: any) => void;
  onClickState: (n: any) => void;
};
const QtyDetailPanel = ({
  position,
  isSelected,
  data,
  onClickDetail,
  onClickState,
}: TQtyDetailPanel) => {
  const tpData = data.filter((row: any) => row.Tp_No !== "");

  const categories = tpData.map((row: any) => row.Tp_InsertTime);
  const tpJig = tpData.map((row: any) => row.In_Thickness);

  return isSelected ? (
    <group position={position} rotation={[0, 0, 0]}>
      <Marker
        rotation={[0, 0, 0]}
        style={{
          opacity: 1,
        }}
      >
        <DataContainer
          onClick={() => {
            onClickDetail(null);
            onClickState("2");
          }}
          style={{
            width: "410px",
            height: "200px",
            padding: "20px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            재고 현황
            <br />
          </p>
          <p
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "10px"
            }}
          >
            {data.length > 0 && <>({convertDateToStrWithTime2(new Date())})</>}
          </p>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "auto" }}>
              <div style={{ display: "flex" }}>
                <table
                  style={{
                    width: "90px",
                    height: "35px",
                    backgroundColor: "#ffc000",
                    border: "2px solid rgb(19 19 25)",
                    borderRadius: "10px",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontWeight: "400",
                          fontSize: "10px",
                        }}
                      >
                        1공장 1창고
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          color: "white",
                          border: "bold",
                          verticalAlign: "top",
                          fontWeight: "900",
                        }}
                      >
                        70%
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    width: "90px",
                    height: "35px",
                    backgroundColor: "#f94836",
                    border: "2px solid rgb(19 19 25)",
                    borderRadius: "10px",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontWeight: "400",
                          fontSize: "10px",
                        }}
                      >
                        1공장 2창고
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          color: "white",
                          border: "bold",
                          verticalAlign: "top",
                          fontWeight: "900",
                        }}
                      >
                        90%
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ display: "flex" }}>
                <table
                  style={{
                    width: "90px",
                    height: "35px",
                    backgroundColor: "#02c502",
                    border: "2px solid rgb(19 19 25)",
                    borderRadius: "10px",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontWeight: "400",
                          fontSize: "10px",
                        }}
                      >
                        1공장 3창고
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          color: "white",
                          border: "bold",
                          verticalAlign: "top",
                          fontWeight: "900",
                        }}
                      >
                        30%
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    width: "90px",
                    height: "35px",
                    backgroundColor: "#02c502",
                    border: "2px solid rgb(19 19 25)",
                    borderRadius: "10px",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontWeight: "400",
                          fontSize: "10px",
                        }}
                      >
                        1공장 4창고
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          color: "white",
                          border: "bold",
                          verticalAlign: "top",
                          fontWeight: "900",
                        }}
                      >
                        40%
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>{" "}
              <div style={{ display: "flex" }}>
                <table
                  style={{
                    width: "90px",
                    height: "35px",
                    backgroundColor: "#02c502",
                    border: "2px solid rgb(19 19 25)",
                    borderRadius: "10px",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontWeight: "400",
                          fontSize: "10px",
                        }}
                      >
                        1공장 5창고
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          color: "white",
                          border: "bold",
                          verticalAlign: "top",
                          fontWeight: "900",
                        }}
                      >
                        30%
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    width: "90px",
                    height: "35px",
                    backgroundColor: "#f94836",
                    border: "2px solid rgb(19 19 25)",
                    borderRadius: "10px",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontWeight: "400",
                          fontSize: "10px",
                        }}
                      >
                        1공장 6창고
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          color: "white",
                          border: "bold",
                          verticalAlign: "top",
                          fontWeight: "900",
                        }}
                      >
                        80%
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style={{ width: "100%", height: "auto", marginLeft: "10px" }}>
              <table
                style={{
                  width: "180px",
                  height: "105px",
                  border: "1px solid #5a5757",
                  borderRadius: "10px",
                }}
              >
                <tbody>
                  <tr style={{ backgroundColor: "#0c1218" }}>
                    <th
                      style={{
                        textAlign: "center",
                        color: "#aed1f7",
                        fontWeight: "bold",
                      }}
                    >
                      품목명
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        color: "#aed1f7",
                        fontWeight: "bold",
                      }}
                    >
                      재고수
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        color: "#aed1f7",
                        fontWeight: "bold",
                      }}
                    >
                      부족재고수
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      장미꽃
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      9
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      BPE BA
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      10
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      0
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      PLATE
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      0
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      5
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DataContainer>
      </Marker>
    </group>
  ) : (
    <></>
  );
};

export default Model;
